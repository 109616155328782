import "./App.css";
import Frame from "./layout/Frame";
import { createBrowserRouter } from "react-router-dom";
import {
  Analytics,
  PostView,
  MarketPrice,
  ScrapMarket,
  Recyclers,
  AddNewRecycler,
  SingleRecycler,
  EditRecycler,
  // AdminsAccounts,
  // EditAccessPermission
} from "./pages";
import Customers from "./pages/recyclers/Customers";
import { Dashboard } from "./pages/main";
import Appmanagement from "./pages/appManagement/Appmanagement";
import ClientDetails from "./pages/clients/pages/ClientDetails";
import EditClientProfile from "./pages/clients/pages/EditClientProfile";
import { Login, ConfirmOTP } from "./pages/auth";
import UsersList from "./pages/clients/pages/UsersList";
import { NotAllow } from "./pages/error";
import { Payments } from "./pages/payment";
import { EditPost, AddPost, Offers } from "./pages/offers";

import {
  UpdateMarketPriceSubscraptions,
  EditAdditionalCredits,
  SubscriptionsPage,
  EditSubscriptions
} from "./pages/subscriptions";



export const unAuthenticated = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  { path: "/confirm-OTP/:session", element: <ConfirmOTP /> },
  {
    path: "/edit-recycler/:id",
    element: (
      <Frame>
        <EditRecycler />
      </Frame>
    )
  },
  {
    path: "/single-recycler/:id",
    element: (
      <Frame>
        <SingleRecycler />
      </Frame>
    )
  },
  {
    path: "/recyclers",
    element: (
      <Frame>
        <Recyclers />
      </Frame>
    )
  },

  {
    path: "/add-recycler",
    element: (
      <Frame>
        <AddNewRecycler />
      </Frame>
    )
  }
]);

export const notAllow = createBrowserRouter([
  {
    path: "/",
    element: <NotAllow />
  }
]);

export const Authenticated = createBrowserRouter([
  {
    path: "/",
    element: (
      <Frame>
        <Dashboard />
      </Frame>
    )
  },

  {
    path: "/not-allow",
    element: <NotAllow />
  },

  {
    path: "/app-management",
    element: (
      <Frame>
        <Appmanagement />
      </Frame>
    )
  },

  {
    path: "/edit-recycler/:id",
    element: (
      <Frame>
        <EditRecycler />
      </Frame>
    )
  },
  {
    path: "/single-recycler/:id",
    element: (
      <Frame>
        <SingleRecycler />
      </Frame>
    )
  },
  {
    path: "/recyclers",
    element: (
      <Frame>
        <Recyclers />
      </Frame>
    )
  },

  {
    path: "/add-recycler",
    element: (
      <Frame>
        <AddNewRecycler />
      </Frame>
    )
  },

  {
    path: "/client-profile/:accountID",
    element: (
      <Frame>
        <ClientDetails />
      </Frame>
    )
  },

  // {
  //   path: "/edit-permission/:accountID",
  //   element: (
  //     <Frame>
  //       <EditAccessPermission />
  //     </Frame>
  //   )
  // },

  {
    path: "/customers",
    element: (
      <Frame>
        <Customers />
      </Frame>
    )
  },
  {
    path: "/analytics",
    element: (
      <Frame>
        <Analytics />{" "}
      </Frame>
    )
  },

  {
    path: "/payments",
    element: (
      <Frame>
        <Payments />{" "}
      </Frame>
    )
  },

  // {
  //   path: "/admins-accounts",
  //   element: (
  //     <Frame>
  //       <AdminsAccounts />{" "}
  //     </Frame>
  //   )
  // },

  {
    path: "/add-post",
    element: (
      <Frame>
        <AddPost />{" "}
      </Frame>
    )
  },

  {
    path: "/post-view/:postID",
    element: (
      <Frame>
        <PostView />
      </Frame>
    )
  },

  {
    path: "/userList",
    element: (
      <Frame>
        <UsersList />{" "}
      </Frame>
    )
  },
  {
    path: "/marketPrice",
    element: (
      <Frame>
        <MarketPrice />
      </Frame>
    )
  },

  {
    path: "/update-price-subscription",
    element: (
      <Frame>
        <UpdateMarketPriceSubscraptions />
      </Frame>
    )
  },

  {
    path: "/scrapMarket",
    element: (
      <Frame>
        <ScrapMarket />
      </Frame>
    )
  },
  {
    path: "/subscriptionPage",
    element: (
      <Frame>
        <SubscriptionsPage />
      </Frame>
    )
  },
  {
    path: "/edit-subscriptions",
    element: (
      <Frame>
        <EditSubscriptions />
      </Frame>
    )
  },

  {
    path: "/edit-post/:postID",
    element: (
      <Frame>
        <EditPost />
      </Frame>
    )
  },

  {
    path: "/edit-additional-credits",
    element: (
      <Frame>
        <EditAdditionalCredits />
      </Frame>
    )
  },
  {
    path: "/edit-client-profile/:accountID",
    element: (
      <Frame>
        <EditClientProfile />
      </Frame>
    )
  },

  {
    path: "/offers",
    element: (
      <Frame>
        <Offers />
      </Frame>
    )
  }
]);
