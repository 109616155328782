import React from "react";

function ClientRecentNotifications() {
  return (
    <div
      className="box p-1 flex column space-between "
      style={{ height: "100%", minHeight: "41vh" }}
    >
      <div>
        <b className="normal-size">Recent Notifications</b>

        
      </div>

      <div className="flex align-center justify-center ">
        <p className="grey-text small-text ">
          To manage all notification like assign credits and subscription,
          please click edit profile button
        </p>
      </div>
    </div>
  );
}

export default ClientRecentNotifications;
