import React from "react";
import { scrapMarketAdminURL } from "../../../redux/config/configURL";
import { deleteCarouselAction } from "../../../redux/action/appManagementAction";
import { useDispatch } from "react-redux";

function Carousel({ imageCarousel }) {
  const dispatch = useDispatch();
  return (
    <div>
      <span className="flex align-center space-between ">
        <b>Image Carousel</b>
        <button className="btn">Add New</button>
      </span>
      <div className="flex wrap-flex gap-1   ">
        {imageCarousel?.map((image) => {
          return (
            <div
              className="white box p-1"
              style={{ width: "400px", position: "relative" }}
            >
              <span
                className=" red-text material-symbols-outlined white hover"
                onClick={() => dispatch(deleteCarouselAction(image._id))}
                style={{
                  position: "absolute",
                  right: "10px",
                  borderRadius: "3px"
                }}
              >
                delete
              </span>
              <img
                src={`${scrapMarketAdminURL}/app-management/read/carousel-img/63fdf2b6bcd7154321455573/${image._id}`}
                height="120px"
                width={"100%"}
              />

              <span className="flex mt-1 gap-1">
                <b>View Type:</b>
                <p>{image.type}</p>
              </span>

              <span className="flex gap-1">
                <b>Screen Name:</b>
                <p>{image.screenName}</p>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Carousel;
