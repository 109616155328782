import React, { useState } from "react";
import ContactDetails from "./ContactDetails";
import RecyclerDetails from "../../components/recycler/RecyclerDetails";
import { addNewRecyclerAction } from "../../redux/action/recycler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AddNewRecycler() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [newRecycler, setNewRecycler] = useState({
    organizationName: "",
    organizationEmail: "",
    cibilScore: "",
    quantityOfRecycled: "",
    processingCapacity: "",
    platformAccess: [],
    contactDetails: [
      {
        name: "",
        email: "",
        phoneNumber: "",
      },
      {
        name: "",
        email: "",
        phoneNumber: "",
      },
    ],
    gst: "",
    scraps: [],
    locations: [],
    CTOCertificate: "",
    EwastAuthorization: "",
    plasticAuthorization: "",
    PWPRegisteration: "",
    panCard: "",
    fireNoc: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if(newRecycler.organizationName == ""){
      setLoading(false);
      return toast.error("Please Enter Organization Name");
    }

    if (newRecycler.scraps.length == 0) {
      setLoading(false);
      return toast.error("Please Select Scrap Type");
    }



    dispatch(addNewRecyclerAction(newRecycler, navigate, setLoading));
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {step == 0 ? (
          <RecyclerDetails
            setStep={setStep}
            setNewRecycler={setNewRecycler}
            newRecycler={newRecycler}
            loading={loading}
          />
        ) : step == 1 ? (
          <ContactDetails
            setStep={setStep}
            setNewRecycler={setNewRecycler}
            newRecycler={newRecycler}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        ) : (
          <div></div>
        )}
      </form>
    </div>
  );
}

export default AddNewRecycler;
