import React from "react";
import { useState } from "react";
import Input from "../../modules/InputFeild";
import { addContactDetailAction } from "../../redux/action/recycler";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function AddNewConactDetails() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phoneNumber: ""
  });
  const dispacth = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispacth(addContactDetailAction(id, contactDetails, setLoading));
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex align-center space-between gap-2 mb-1 ">
          <div className="flex column full-width ">
            <label>Name</label>
            <Input
              state={{
                value: contactDetails.name,
                placeHolder: "Enter Recycler Name",
                icon: "person",
                iconColor: "#6f2da8",
                name: "name"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width ">
            <label>Email</label>
            <Input
              state={{
                value: contactDetails.email,
                placeHolder: "Enter Recycler Email",
                icon: "email",
                iconColor: "#6f2da8",
                name: "email"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
        <div className="flex align-center space-between gap-2  ">
          <div className="flex column full-width ">
            <label>Phone Number</label>
            <Input
              state={{
                value: contactDetails.phoneNumber,
                placeHolder: "enter new phone number",
                icon: "phone",
                iconColor: "#6f2da8",
                name: "phoneNumber",
                type: "tel",
                maxLength:"13"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width "></div>
        </div>
        <div className="flex full-width justify-end flex ">
          <button
            className="btn"
            type="submit"
            disabled={
              !contactDetails.name ||
              !contactDetails.email ||
              !contactDetails.phoneNumber ||
              loading
            }
          >
            {loading ? "loading..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewConactDetails;
