import React, { useState } from "react";
import { Button } from "react-materialize";
import { Input } from "../../modules";
import { createNewScrapAction } from "../../redux/action/marketPriceAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function AddNewScrap() {
  const dispatch = useDispatch();

  const [newScrap, setNewScrap] = useState({
    name: "",
    variant: "",
    type: "",
    image: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "type") {
      const newValue = value.toUpperCase();
      setNewScrap({ ...newScrap, [name]: newValue });
    } else {
      setNewScrap({ ...newScrap, [name]: value });
    }
  };

  const handleNewScrapSubmit = (e) => {
    e.preventDefault();

    if (
      !newScrap.name ||
      !newScrap.image ||
      !newScrap.type ||
      !newScrap.variant
    ) {
      toast.error("Please fill out all required fields.");
    } else {
      const formData = new FormData();

      for (let key in newScrap) {
        formData.append(key, newScrap[key]);
      }
      dispatch(createNewScrapAction(formData));
    }
  };
  return (
    <div>
      <form className=" flex column  " onSubmit={handleNewScrapSubmit}>
        <b className="normal-size">Add New Scrap</b>

        <div className="flex align-center gap-1 mt-1 ">
          <div className="flex column full-width ">
            <label>Enter Scrap Name: *</label>
            <Input
              state={{
                value: newScrap.name,
                placeHolder: "Enter Scrap Name",
                icon: "",
                iconColor: "#999",
                name: "name"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width">
            <label>Enter Scrap Type: *</label>
            <select
              onChange={(e) => {
                handleChange(e);
              }}
              className="browser-default full-width "
              name="type"
            >
              <option>--selecte--</option>
              <option value={"metal"}>Metal</option>
              <option value={"paper"}>Paper</option>
              <option value={"plastic"}>Plastic</option>
              <option value={"eWaste"}>E-Waste</option>
              <option value={"tyres"}>Tyres</option>
              <option value={"battery"}>Battery</option>
              <option value={"wood"}>Wood</option>
              <option value="solid-waste">solid-waste</option>
            </select>
            {/* <Input
              state={{
                value: newScrap.type,
                placeHolder: "Enter Scrap Type",
                icon: "",
                iconColor: "#999",
                name: "type",
              }}
            /> */}
          </div>
        </div>

        <div className="flex align-start gap-1 ">
          <div className="flex column full-width ">
            <label>Enter Scrap Variant: *</label>
            <Input
              state={{
                value: newScrap.variant,
                placeHolder: "Enter Scrap Variant",
                icon: "",
                iconColor: "#999",
                name: "variant"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width hover ">
            <label>Select Scrap Image: *</label>
            <div className="input-file-style">
              <input
                type={"file"}
                className="fileInput"
                onChange={(e) =>
                  setNewScrap({ ...newScrap, image: e.target.files[0] })
                }
              />
              {newScrap.image ? (
                <p className="cercle-purple-text">{newScrap.image?.name}</p>
              ) : (
                <p>Select Image</p>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-1 align-center mt-1 ">
          <Button className="btn-outline" flat modal="close" node="button">
            Close
          </Button>
          <button className="btn-submit" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewScrap;
