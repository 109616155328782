import React, { useEffect, useState } from "react";
import Input from "../../modules/InputFeild";
import LocationsList from "./LocationsList";
import ScrapsList from "./ScrapsList";
import AppsList from "./AppsList";
import { RequireStare } from "../../modules";
import { Button, Modal } from "react-materialize";
import { toast } from "react-toastify";
import Map from "./Map";
import { Link, useNavigation } from "react-router-dom";

function RecyclerDetails({ newRecycler, setStep, setNewRecycler }) {
  const navigate = useNavigation();
  const [currentScrapType, setCureentScrapType] = useState("");
  const [ewasteRecycler, seteWasteRecycler] = useState(false);
  const [currentScrapName, setCureentScrapName] = useState("");
  const [currentProcessOutput, setCurrentProcessOutput] = useState("");
  const [currentAvailableCredits, setCureentAvailableCredits] = useState("");
  const [currentProcessingCapacity, setCureentProcessingCapacity] =
    useState("");
  const [currentQuantityOfRecycled, setCureentQuantityOfRecycled] =
    useState("");
  const [currentCategory, setCureentCategory] = useState("");
  const [currentPrice, setCureentPrice] = useState("");
  const [currentScrapVariant, setCureentScrapVariant] = useState("");
  const [plasticRecycler, setPlasticRecycler] = useState(false);
  const [values, setValues] = useState({
    location: {
      longitude: "",
      latitude: "",
      address: "",
      nickname: "",
      state: "",
      quantity: "",
      country: "",
      wasteType: "",
    },
  });

  useEffect(() => {
    newRecycler.scraps?.map((item) => {
      if (item.type === "plastic") {
        setPlasticRecycler(true);
      } else if (item.type === "eWaste") {
        seteWasteRecycler(true);
      }
    });
  }, [newRecycler.scraps]);

  function deleteLocation(address) {
    setNewRecycler((item) => {
      const filterLocation = item.locations.filter(
        (location) => location.address !== address
      );
      return { ...item, locations: filterLocation };
    });
  }
  function addNewLocation() {
    let newLocations = {
      state: values.location.state,
      latitude: values.location.latitude,
      longitude: values.location.longitude,
      address: values.location.address,
      nickname: values.location?.nickname,
      country: values.location.country,
      wasteType: values.location.wasteType,
      quantity: values.location.quantity,
    };
    setNewRecycler({
      ...newRecycler,
      locations: [...newRecycler.locations, newLocations],
    });

    setValues({
      location: {
        longitude: "",
        latitude: "",
        address: "",
        nickname: "",
        state: "",
        quantity: "",
        country: "",
        wasteType: "",
      },
    });
  }

  const handlePlatformAccess = (appName) => {
    const index = newRecycler.platformAccess.find((e) => e == appName);

    if (index < 0) {
      setNewRecycler((prevUserDetails) => {
        const updatedScraps = prevUserDetails.platformAccess.filter(
          (app) => app !== appName
        );

        return { ...prevUserDetails, platformAccess: updatedScraps };
      });
    } else {
      setNewRecycler({
        ...newRecycler,
        scraps: [...newRecycler.newRecycler, appName],
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "type") {
      setNewRecycler({ ...newRecycler, [name]: value });
    } else {
      setNewRecycler({ ...newRecycler, [name]: value });
    }
  };

  const addScrap = () => {
    if (currentScrapType === "") {
      return toast.error("Please Select Scrap Type");
    }
    setNewRecycler({
      ...newRecycler,
      scraps: [
        ...newRecycler.scraps,
        {
          name: currentScrapName,
          type: currentScrapType,
          variant: currentScrapVariant,
          processingCapacity: currentProcessingCapacity,
          quantityOfRecycled: currentQuantityOfRecycled,
          category: currentCategory,
          price: currentPrice,
          availableCredits: currentAvailableCredits,
          processOutput: currentProcessOutput,
        },
      ],
    });
    setCureentScrapName("");
    setCureentScrapType("");
    setCureentScrapVariant("");
    setCureentCategory("");
    setCureentProcessingCapacity("");
    setCureentQuantityOfRecycled("");
  };

  function removeFromArray(item) {
    setNewRecycler((prevUserDetails) => {
      const updatedScraps = prevUserDetails.scraps.filter(
        (scrap) => scrap.name !== item.name || scrap.type !== item.type
      );

      return { ...prevUserDetails, scraps: updatedScraps };
    });
  }
  return (
    <div>
      <b className="normal-size">Organization Details:</b>
      <div className="flex align-center space-between gap-2 mb-1 mt-2 ">
        <div className="flex column full-width ">
          <label>
            Organization Name: <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.name,
              placeHolder: "Enter Recycler Name",
              icon: "person",
              iconColor: "#6f2da8",
              name: "organizationName",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label>
            Organization Email <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.email,
              placeHolder: "Enter Recycler Email",
              icon: "email",
              iconColor: "#6f2da8",
              name: "organizationEmail",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>

      <div className="flex align-center space-between gap-2 mb-1">
        <div className="flex column full-width ">
          <label>
            Pan Card: <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.name,
              placeHolder: "Enter Recycler Pan Card",
              icon: "credit_card",
              iconColor: "#6f2da8",
              name: "panCard",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label>
            CTO Certificate <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.email,
              placeHolder: "Enter Recycler CTO Certificate",
              icon: "developer_guide",
              iconColor: "#6f2da8",
              name: "CTOCertificate",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>

      <div className="flex align-center space-between gap-2 mb-1">
        <div className="flex column full-width ">
          <label>
            GST Number <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.name,
              placeHolder: "Enter Recycler GST number",
              icon: "admin_panel_settings",
              iconColor: "#6f2da8",
              name: "gst",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label className="flex">
            Fire NOC
            <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.fireNoc,
              placeHolder: "Enter fire NOC",
              icon: "settings_suggest",
              iconColor: "#6f2da8",
              name: "fireNoc",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>

      <div className="flex align-center space-between gap-2 mb-1">
        <div className="flex column full-width ">
          <label>Cibil Score</label>
          <Input
            state={{
              value: newRecycler.email,
              placeHolder: "Enter Recycler Cibil Score",
              icon: "speed",
              iconColor: "#6f2da8",
              name: "cibilScore",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <p
            className="flex"
            style={{ color: ewasteRecycler ? "#000" : "#999" }}
          >
            E-waste Authorization
            <RequireStare />
          </p>
          <Input
            state={{
              value: newRecycler.EwastAuthorization,
              placeHolder: " Enter E-wast Authorization",
              icon: "local_police",
              iconColor: "#6f2da8",
              name: "EwastAuthorization",
              disabled: !ewasteRecycler,
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>

      {plasticRecycler && (
        <div className="flex align-center space-between gap-2 mb-1">
          <div className="flex column full-width ">
            <label>plastic Authorization</label>
            <Input
              state={{
                value: newRecycler.plasticAuthorization,
                placeHolder: "Enter plastic Authorization",
                icon: "speed",
                iconColor: "#6f2da8",
                name: "plasticAuthorization",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width ">
            <label className="flex">
              PWP Registeration
              <RequireStare />
            </label>
            <Input
              state={{
                value: newRecycler.PWPRegisteration,
                placeHolder: "Enter PWP Registeration",
                icon: "cycle",
                iconColor: "#6f2da8",
                name: "PWPRegisteration",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      )}

      {/* <div className="flex align-center space-between gap-2 mb-1">
        <div className="flex column full-width ">
          <label className="flex">
            Processing Capacity
            <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.processingCapacity,
              placeHolder: "Enter processing Capacity",
              icon: "settings_suggest",
              iconColor: "#6f2da8",
              name: "processingCapacity"
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label className="flex">
            Quantity of Recycled
            <RequireStare />
          </label>
          <Input
            state={{
              value: newRecycler.quantityOfRecycled,
              placeHolder: "Enter quantity Of Recycled",
              icon: "cycle",
              iconColor: "#6f2da8",
              name: "quantityOfRecycled"
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div> */}

      <hr />
      {/* <label className="mt-2">
        Customer App and Platform Access Management:
        <RequireStare />
      </label>
          <AppsList handlePlatformAccess={handlePlatformAccess} />  */}
      <div className=" align-center gap-2 mt-2">
        <div className="full-width mt-1 ">
          <label>
            Scraps: <RequireStare />
          </label>

          <ScrapsList
            scraps={newRecycler.scraps}
            removeFromArray={removeFromArray}
          />
          <div className="flex align-center gap-1 column full-width  ">
            <div className=" flex full-width gap-1 align-center mt-1 column ">
              <div className="flex gap-1  full-width">
                <div className="flex column full-width ">
                  <label className="flex">Select Type</label>
                  <select
                    className="browser-default full-width"
                    onChange={(e) => setCureentScrapType(e.target.value)}
                  >
                    <option>--select--</option>
                    <option value="battery">Battery</option>
                    <option value="tyres">Tyres</option>
                    <option value="eWaste">E-Waste</option>
                    <option value="plastic">Plastic</option>
                    <option value="paper">Paper</option>
                    <option value="metal">Metal</option>
                    <option value="used oil">Used Oil</option>
                  </select>
                </div>

                <div className="flex column full-width ">
                  <label className="flex">Scrap Name</label>
                  <Input
                    state={{
                      value: currentScrapName,
                      placeHolder: "enter name",
                      icon: "",
                      iconColor: "#0E9D1C",
                    }}
                    onChange={(e) => setCureentScrapName(e.target.value)}
                    style={{ marginBottom: "10px ", width: "100%" }}
                  />
                </div>
                <div className="flex column full-width ">
                  <label className="flex">Scrap Variant</label>
                  <Input
                    state={{
                      value: currentScrapVariant,
                      placeHolder: "enter variant",
                      icon: "",
                      iconColor: "#0E9D1C",
                    }}
                    onChange={(e) => setCureentScrapVariant(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </div>
              <div className="flex align-center space-between full-width gap-1">
                <div className="flex column full-width ">
                  <label className="flex">Processing Capacity</label>
                  <Input
                    state={{
                      value: currentProcessingCapacity,
                      placeHolder: "Enter processing Capacity",
                      icon: "settings_suggest",
                      iconColor: "#6f2da8",
                      name: "processingCapacity",
                    }}
                    onChange={(e) => {
                      setCureentProcessingCapacity(e.target.value);
                    }}
                  />
                </div>

                <div className="flex column full-width ">
                  <label className="flex">Quantity of Recycled</label>
                  <Input
                    state={{
                      value: currentQuantityOfRecycled,
                      placeHolder: "Enter quantity Of Recycled",
                      icon: "cycle",
                      iconColor: "#6f2da8",
                      name: "currentQuantityOfRecycled",
                    }}
                    onChange={(e) => {
                      setCureentQuantityOfRecycled(e.target.value);
                    }}
                  />
                </div>

                <div className="flex column full-width ">
                  <label className="flex">Available Credits</label>
                  <Input
                    state={{
                      value: currentAvailableCredits,
                      placeHolder: "Enter Available Credits",
                      icon: "cycle",
                      iconColor: "#6f2da8",
                      name: "currentQuantityOfRecycled",
                    }}
                    onChange={(e) => {
                      setCureentAvailableCredits(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex align-center space-between full-width gap-1">
                <div className="flex column full-width ">
                  <label className="flex">Select Category</label>
                  <select
                    className="browser-default full-width"
                    onChange={(e) => setCureentCategory(e.target.value)}
                  >
                    <option>--select--</option>
                    <option value="CAT I">CAT-I</option>
                    <option value="CAT II">CAT-II</option>
                    <option value="CAT III">CAT-III</option>
                    <option value="CAT IV">CAT-IV</option>
                  </select>
                </div>
                <div className="flex column full-width ">
                  <label className="flex">Scrap Price</label>
                  <Input
                    state={{
                      value: currentPrice,
                      placeHolder: "enter price",
                      icon: "",
                      iconColor: "#0E9D1C",
                    }}
                    onChange={(e) => setCureentPrice(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />
                </div>

                <div className="flex column full-width ">
                  <label className="flex">Process Output</label>
                  <Input
                    state={{
                      value: currentProcessOutput,
                      placeHolder: "enter process output",
                      icon: "",
                      iconColor: "#0E9D1C",
                    }}
                    onChange={(e) => setCurrentProcessOutput(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </div>

              <span className="flex full-width justify-end ">
                <button
                  type="button"
                  className="btn"
                  onClick={() => addScrap()}
                >
                  Add
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="full-width mt-1 ">
        <Modal
          trigger={
            <span className="flex space-between  align-center">
              <label>
                Locations: <RequireStare />
              </label>

              <p className="purple-text btn-outline ">
                <span class="material-symbols-outlined normal-size ">
                  add_location_alt
                </span>
                Add a New Location
              </p>
            </span>
          }
          actions={[]}
        >
          <Map
            values={values}
            setValues={setValues}
            addLocation={addNewLocation}
            newRecycler={newRecycler}
            setNewRecycler={setNewRecycler}
          />
        </Modal>
        <LocationsList
          newRecycler={newRecycler}
          deleteLocation={deleteLocation}
        />
      </div>
      <span className="flex align-end gap-1  justify-end flex-end mt-1 ">
        <Link to={"/recyclers"}>
          <Button onClick={() => navigate("recyclers")} className="btn-outline">
            Close
          </Button>
        </Link>
        <Button onClick={() => setStep(1)}>Next</Button>
      </span>
    </div>
  );
}

export default RecyclerDetails;
