import React, { useEffect } from "react";
import SingleBid from "./SingleBid";
import { useDispatch, useSelector } from "react-redux";
import { getPostBidsAction } from "../../redux/action/offersAction";
import { useParams } from "react-router-dom";
import Loading from "../../modules/Loading";

function BidsOnPost() {
  const { postID } = useParams();
  const dispatch = useDispatch();
  const { loading, postBids } = useSelector((state) => state.postBidsStore);
  useEffect(() => {
    dispatch(getPostBidsAction(postID));
  }, [dispatch, postID]);
  if (loading) {
    return <Loading />;
  }

  if (postBids) {
    return (
      <div className="flex align-center justify-center  full-width full-height white box column">
        <img src="../images/nousers.png" style={{ width: "130px" }} />
        <p className="grey-text small-text mt-2 ">
          There No Bids On This Post.
        </p>
      </div>
    );
  }
  return (
    <div className="box full-width full-height p-2  flex column ">
      <b className="normal-size mb-1 ">Bids on this scrap</b>
      <div className="flex gap-1 flex-wrap ">
        {postBids.map((item, index) => {
          return <SingleBid key={index} singleBid={item} />;
        })}
      </div>
    </div>
  );
}

export default BidsOnPost;
