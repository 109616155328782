import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleRecyclerAction } from "../../redux/action/recycler";
import Notes from "./Notes";
import {
  PickupsList,
  TransactionsHistory,
  SingleRecyclerDetails,
} from "../../components";
import { Row } from "react-materialize";

function SingleRecycler() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, singleRecycler } = useSelector(
    (state) => state.singleRecyclerData
  );
  useEffect(() => {
    dispatch(getSingleRecyclerAction(id));
  }, [dispatch]);
  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Row>
        <div className="col s5  box white" style={{ minHeight: "82vh" }}>
          <SingleRecyclerDetails singleRecycler={singleRecycler} />
        </div>
        <div className="col s7 flex column gap-1 ">
          <Notes notes={singleRecycler.notes} />
          <PickupsList />
          <TransactionsHistory />
        </div>
      </Row>
    </div>
  );
}

export default SingleRecycler;
