import { recyclerAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getAllRecyclersAction = (skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTING_RECYCLERS" });
  recyclerAdmin
    .get(`/recycler/read/?skip=${skip}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: "GOT_RECYCLERS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_RECYCLERS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSingleRecyclerAction = (id) => async (dispatch) => {
  dispatch({ type: "GETING_SINGLE_RECYCLER" });
  recyclerAdmin
    .get(`/recycler/read/single-recycler/${id}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_RECYCLER", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CONNOT_GET_SINGLE_RECYCLER" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const addNewRecyclerAction =
  (data, navigate, setLoading) => async (dispatch) => {
    dispatch({ type: "CREATE_NEW_RECYCLER" });
    setLoading(true);
    recyclerAdmin
      .post("/recycler/create", data)
      .then((res) => {
        setLoading(false);
        dispatch({ type: "NEW_RECYCLER_CREATED" });
        toast.success(res.data);
        dispatch(getAllRecyclersAction());
        navigate("/recyclers");
      })
      .catch((error) => {
        setLoading(false);
        dispatch({ type: "CANNOT_CREATE_RECYCLER" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const deleteRecyclerAction = (id) => async (dispatch) => {
  dispatch({ type: "DELETEING_RECYCLER" });
  recyclerAdmin
    .delete(`/recycler/delete/${id}`)
    .then((res) => {
      dispatch({ type: "RECYCLER_DELETED" });
      dispatch(getAllRecyclersAction());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_DELETE_RECYCLER" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const editRecyclerDetailsAction =
  (id, data, setLoading) => async (dispatch) => {
    dispatch({ type: "EDITING_RECYCLER" });
    recyclerAdmin
      .put(`recycler/update//${id}`, data)
      .then((res) => {
        dispatch({ type: "RECYCLER_EDITED" });
        setLoading(false);
        toast.success(res.data);
        window.location.reload(false);
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_EDIT_RECYCLER" });
      });
  };

export const addContactDetailAction =
  (id, data, setLoading) => async (dispatch) => {
    setLoading(true);
    recyclerAdmin
      .put(`/recycler/update/add-contact-details/${id}`, {
        contactDetails: data,
      })
      .then((res) => {
        window.location.reload();
        setLoading(false);
      })
      .then((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const deleteScrapAction = (recyclerId, scrapId) => async (dispatch) => {
  recyclerAdmin
    .delete(`/recycler/delete/contact-details/${recyclerId}/${scrapId}`)
    .then((res) => {
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deleteRecyclerScrapAction =
  (recyclerId, scrapId) => async (dispatch) => {
    recyclerAdmin
      .delete(`/recycler/delete/scrap/${recyclerId}/${scrapId}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const editRecyclerScrapAction =
  (recyclerId, scrapId, data, setLoading) => async (dispatch) => {
    setLoading(true);
    recyclerAdmin
      .put(`/recycler/update/scrap/${recyclerId}/${scrapId}`, { scrap: data })
      .then((res) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const addRecyclerScrapAction =
  (recyclerId, data, setLoading) => async (dispatch) => {
    setLoading(true);
    recyclerAdmin
      .put(`/recycler/update/add-scrap/${recyclerId}`, { scrap: data })
      .then((res) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const deleteRecyclerLocationAction =
  (recyclerId, locationId) => async (dispatch) => {
    recyclerAdmin
      .delete(`/recycler/delete/location/${recyclerId}/${locationId}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const filterReyclerDataAction =
  (skip, limit, state, type, category, organizationName) =>
  async (dispatch) => {
    dispatch({ type: "FILTTERING_DATA" });
    recyclerAdmin
      .get(
        `/recycler/read/filter/?state=${state}&type=${type}&category=${category}&organizationName=${organizationName}&skip=${skip}&limit=${limit}`
      )
      .then((res) => {
        dispatch({ type: "DATA_FILTERED", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_FILTER_DATA" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getEcoDataHubAnalyticsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ANALYTICS" });

  recyclerAdmin
    .get("/analytics/read")
    .then((res) => {
      dispatch({ type: "GOT_ANALYTICS_DATA", payload: res.data });
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_GET_ANALYTICS" });
    });
};

export const getAllCustomersAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_CUSTOMERS" });
  recyclerAdmin
    .get("/accounts/customer/read")
    .then((res) => {
      dispatch({ type: "GOT_CUSTOMERS", payload: res.data });
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_GET_CUSTOMER" });
    });
};

export const editRecyclerContactDetailsAction =
  (id, editItemId, data) => async (dispatch) => {
    recyclerAdmin
      .put(`/recycler/update/contact-details/${id}/${editItemId}`, {
        contactDetails: data,
      })
      .then((res) => {
        toast.success(res.data);
        dispatch(getSingleRecyclerAction(id));
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      });
  };
export const deleteRecyclerContactDetailsAction =
  (id, itemId) => async (dispatch) => {
    recyclerAdmin
      .delete(`/recycler/delete/contact-details/${id}/${itemId}`)
      .then((res) => {
        toast.success(res.data);
        dispatch(getSingleRecyclerAction(id));
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const uploadFileAction =
  (id, data, setRenderKey) => async (dispatch) => {
    const uploadFile = toast.loading("upload file...");
    recyclerAdmin
      .post(`/recycler/update/file/${id}`, data)
      .then((res) => {
        toast.update(uploadFile, {
          render: requestAnimationFrame.data,
          isLoading: false,
          autoClose: 5000,
        });
        setRenderKey(Math.random());
        dispatch(refreshSingleRecyclerAction(id));
      })
      .catch((error) => {
        toast.update(uploadFile, {
          render: error.response ? error.response?.data : error.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

export const refreshSingleRecyclerAction = (id) => async (dispatch) => {
  recyclerAdmin
    .get(`/recycler/read/single-recycler/${id}`)
    .then((res) => {
      dispatch({
        type: "SINGLE_RECYCLER_REFRESHED",
        documents: res?.data?.documents,
        locations: res?.data?.locations,
        notes: res?.data?.notes,
      });
    })
    .catch((error) => {
      dispatch({ type: "CONNOT_GET_SINGLE_RECYCLER" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deleteFileAction = (id, fileId) => async (dispatch) => {
  const uploadFile = toast.loading("delete file...");
  recyclerAdmin
    .delete(`/recycler/update/file/${id}/${fileId}`)
    .then((res) => {
      toast.update(uploadFile, {
        render: res.data,
        isLoading: false,
        autoClose: 5000,
      });
      dispatch(refreshSingleRecyclerAction(id));
    })
    .catch((error) => {
      toast.update(uploadFile, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};

export const addNewLocationAction = (id, data) => async (dispatch) => {
  dispatch({ type: "ADDING_NEW_LOCATION" });
  const addLocation = toast.loading("Adding new location...");
  recyclerAdmin
    .put(`/recycler/update/add-location/${id}`, data)
    .then((res) => {
      toast.update(addLocation, {
        render: res.data,
        isLoading: false,
        autoClose: 5000,
      });
      dispatch({ type: "NEW_LOCATION_ADDED", payload: res.data });
      dispatch(refreshSingleRecyclerAction(id));
    })
    .catch((error) => {
      toast.update(addLocation, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      dispatch({ type: "CANNOT_ADD_NEW_LOCATION" });
    });
};

export const getVerifiedUserByDate =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GET_VERIFIED_USERS" });
    recyclerAdmin
      .get(`/analytics/read/verifyed?startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        dispatch({ type: "GOT_VERIFIED_USERS", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_VERIFIED_USERS" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const addNotesAction = (id, data) => async (dispatch) => {
  dispatch({ type: "ADD_NOTE" });
  const addNote = toast.loading("Adding new note...");
  recyclerAdmin
    .put(`/recycler/update/add-note/${id}`, data)
    .then((res) => {
      dispatch(refreshSingleRecyclerAction(id));
      dispatch({ type: "NOTE_ADDED" });
      toast.update(addNote, {
        render: res.data,
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      toast.update(addNote, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};

export const deleteNoteAction = (id, noteId) => async (dispatch) => {
  const deleteNote = toast.loading("Deleting note...");
  recyclerAdmin
    .put(`/recycler/update/delete-note/${id}/${noteId}`)
    .then((res) => {
      dispatch(refreshSingleRecyclerAction(id));
      dispatch({ type: "NOTE_DELETED" });
      toast.update(deleteNote, {
        render: res.data,
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      toast.update(deleteNote, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};
