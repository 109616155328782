import React, { useEffect, useState } from "react";
import { Button , Tab, Tabs  } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { getAllRecyclersAction } from "../../redux/action/recycler";
import { useNavigate } from "react-router-dom";
import EcoDataHubAnalytics from "../../components/recycler/EcoDataHubAnalytics";
import EcoDataHubFilter from "../../components/recycler/EcoDataHubFilter";
import RecyclerTable from "../../components/recycler/RecyclerTable";

function Recyclers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfRow, setNumberOfRow] = useState(40);
  const [state, setState] = useState("");
  const [type, setType] = useState("");
  const [renderKey, setRenderKey] = useState(Math.random());
  const [organizationName, setOrganizationName] = useState("");
  const [category, setCategory] = useState("");

  const clearFilter = () => {
    setState("");
    setType("");
    setOrganizationName("");
    setCategory("");
    setRenderKey(Math.random());
    setCurrentPage(0);
    dispatch(getAllRecyclersAction(1, numberOfRow));
  };

  return (
<Tabs
  className="tab-demo z-depth-1 tabs-fixed-width"
  scope="tabs-23"
>
 
  <Tab
    active
    options={{
      duration: 300,
      onShow: null,
      responsiveThreshold: Infinity,
      swipeable: false
    }}
    title="Reyclers"
  >
      <div className="flex full-width justify-end  ">
        <Button
          onClick={() => {
            navigate("/add-recycler");
          }}
          className="green"
        >
          + Add New{" "}
        </Button>
      </div>
    <div className="flex align-center space-between mb-1 ">
        <b>List Of Recyclers</b>
        <div style={{ width: "75%" }}>
          <EcoDataHubFilter
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            numberOfRow={numberOfRow}
            clearFilter={clearFilter}
            state={state} 
            organizationName={organizationName}
            type={type}
            category={category}
            setOrganizationName={setOrganizationName}
            setState={setState}
            setCategory={setCategory}
            setType={setType}
            renderKey={renderKey}
          />
        </div>
      </div>
      <div>
        
        <RecyclerTable
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          numberOfRow={numberOfRow}
          filter={{
            state: state,
            type: type,
            category: category,
            organizationName: organizationName,
            
          }}
        />
      </div>
  </Tab>
 
  <Tab
    options={{
      duration: 300,
      onShow: null,
      responsiveThreshold: Infinity,
      swipeable: false
    }}
    title="Analytics"
  >
   <EcoDataHubAnalytics />
  </Tab>
 
</Tabs>

    
  );
}

export default Recyclers;

{/* <div>
      <div className="flex full-width justify-end mb-1 ">
        <Button
          onClick={() => {
            navigate("/add-recycler");
          }}
        >
          + Add New{" "}
        </Button>
      </div>
      
      <div className="flex align-center space-between mb-1 ">
        <b>List Of Recyclers</b>
        <div style={{ width: "75%" }}>
          <EcoDataHubFilter
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            numberOfRow={numberOfRow}
            clearFilter={clearFilter}
            state={state} 
            organizationName={organizationName}
            type={type}
            category={category}
            setOrganizationName={setOrganizationName}
            setState={setState}
            setCategory={setCategory}
            setType={setType}
            renderKey={renderKey}
          />
        </div>
      </div>

      <div>
        <RecyclerTable
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          numberOfRow={numberOfRow}
          filter={{
            state: state,
            type: type,
            category: category,
            organizationName: organizationName,
            
          }}
        />
      </div>
    </div> */}