import React, { useState, useEffect } from "react";
import { Row } from "react-materialize";
import { MarketPriceContent } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { MarketPriceFilter } from "../components/marketPrice/MarketPriceFilter";
import { getSingleCityAction } from "../redux/action/marketPriceAction";

function MarketPrice() {
  const dispatch = useDispatch();
  const [material, setMaterial] = useState(null);
  const { allCities, loading } = useSelector((state) => state.getAllCities);
  const listOfScraps = [];

  const getAllScraps = (allCities) => {
    allCities[0]?.scraps.forEach((scrap) => {
      const singleScrap = {
        value: { name: scrap.scrap.name, variant: scrap.scrap.variant },
        label: `${scrap.scrap.name}-${scrap.scrap.variant}`,
        id: scrap._id
      };
      listOfScraps.push(singleScrap);
    });
  };

  useEffect(() => {
    if (!loading) {
      getAllScraps(allCities);
    }
  }, [dispatch, loading]);

  return (
    <Row className="row">
      <div className="col s3">
        <MarketPriceFilter
          setMaterial={setMaterial}
          listOfScraps={listOfScraps}
          material={material}
        />
      </div>
      <div className=" col s9">
        <MarketPriceContent material={material} />
      </div>
    </Row>
  );
}

export default MarketPrice;

// if (material?.name || material?.variant) {
//   const scrapsFiltred = item.scraps?.filter((singleScrap) => {
//     return (
//       singleScrap.name === material?.name &&
//       singleScrap?.variant === material.variant
//     );
//   });
//   setAllScraps(scrapsFiltred);
// } else {
//   setAllScraps(item.scraps);
// }
