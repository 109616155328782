import React, { useState, PureComponent } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

function OverallSubscription({ subscriptions }) {
  const [activeIndex, setActiveIndex] = useState(null);
  // const [data, setData] = useState([]);
  let pieGraphData = [];

  for (const item in subscriptions) {
    pieGraphData.push({ name: item, value: subscriptions[item] });
  }

  const tooltipStyle = {
    backgroundColor: "#f1eaf7",
    borderColor: "#6f2da8",
    borderStyle: "solid",
    borderWidth: 0.1,
    border: "none",
    borderRadius: "1%",
    fontSize: "14px",
    padding: "10px",
  };

  const COLORS = [
    "Blue",
    "Green",
    "red",
    "Orange ",
    "Purple ",
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="box p-1" style={{ width: "100%" }}>
      <b>Overall Subscription</b>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            data={pieGraphData}
            cx={"50%"}
            cy={"50%"}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            // activeIndex={activeIndex}
            // onMouseEnter={onPieEnter}
            // onMouseLeave={onPieLeave}
          >
            {pieGraphData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}`} />
          <Legend formatter={(value) => `${value}`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default OverallSubscription;
