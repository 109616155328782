import React, { useEffect } from "react";
import { Dropdown } from "react-materialize";
import Notification from "../../pages/main/components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { getAccountNotification } from "../../redux/action/accountsAction";
import {
  getProfileAction,
  seenAllNotificationsAction
} from "../../redux/action/accountsAction";
import { useNavigate } from "react-router-dom";

function HeaderRightSide() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state) => state.profileStore);
  const navigate = useNavigate();

  function seenNotifications() {
    if (profile.newNotificationsAdmin > 0) {
      dispatch(seenAllNotificationsAction());
    }
  }

  useEffect(() => {
    dispatch(getAccountNotification());
    dispatch(getProfileAction());
  }, [dispatch]);

  return (
    <div className="flex align-center ">
      <div className="search-input flex align-center ">
        <span className="material-symbols-outlined grey-text bold ">
          search
        </span>
        <input
          className="browser-default"
          type="text"
          placeholder="search..."
        />
        <span className="material-symbols-outlined grey-text  ">tune</span>
      </div>

      <span style={{margin:"10px"}} >  
      <button className="btn"  onClick={()=> navigate('/add-post') } >Add Offer</button>
      </span>
      <Dropdown
        options={{
          alignment: "right",
          onCloseEnd: seenNotifications()
        }}
        trigger={
          <div role="button" className="notification-icon">
            <span
              class="material-symbols-outlined big-icon mh-1 white  circle"
              style={{ padding: "10px" }}
            >
              notifications
            </span>
            {profile.newNotificationsAdmin > 0 && (
              <span className="new-notification-indicator">
                {profile.newNotificationsAdmin}
              </span>
            )}
          </div>
        }
        className="header-notification "
      >
        <Notification />
      </Dropdown>

      <img
        src="../images/userImage.png"
        className="circle  "
        style={{ width: "4.5vh", height: "4.5vh" }}
      />
    </div>
  );
}

export default HeaderRightSide;
