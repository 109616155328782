import React, { useState } from "react";
import { Input } from "../../modules";
import { createCityAction } from "../../redux/action/marketPriceAction";
import { useDispatch } from "react-redux";

function AddNewCity() {
  const dispatch = useDispatch();

  const [newCity, setNewCity] = useState("");
  const [newCityError, setNewCityError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newCity === "") {
      setNewCityError("Enter City Name");
    } else {
      setNewCityError("");
      dispatch(createCityAction(newCity));
    }
  };

  return (
    <div>
      <form className=" flex column  " onSubmit={handleSubmit}>
        <b className="normal-size">Add New City</b>
        <b className="mt-2">Enter City Name:</b>
        <Input
          state={{
            value: newCity,
            placeHolder: "Enter City Name",
            icon: "location_city",
            iconColor: "#999",
          }}
          onChange={(e) => {
            setNewCity(e.target.value);
          }}
        />
        <p className="small-text red-text ">{newCityError}</p>

        <div className="flex justify-end gap-1 align-center ">
          <button className="btn-outline">Close</button>
          <button className="btn-submit" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewCity;
