import React, { useEffect } from "react";
import { Aside, Header } from "../components";
import FooterView from "./FooterView";
import { useSelector } from "react-redux";

function Frame({ children }) {
  const { profile } = useSelector((state) => state.profileStore);

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  const disableCopy = (event) => {
    event.preventDefault();
  };

  // useEffect(() => {
  //   document.addEventListener("contextmenu", disableRightClick);
  //   document.addEventListener("copy", disableCopy);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //     document.removeEventListener("copy", disableCopy);
  //   };
  // }, []);

  return (
    <div className="overlay ">
      <Aside />
      <div className="flex column app-body ">
        <Header />
        <section className="section">{children}</section>
      </div>
    </div>
  );
}

export default Frame;
