import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function OverallPostsAndBids() {
  const data = {
    labels: ["Buyers Bid", "Sellers Bid", "Buyers Post", "Sellers Post"],
    datasets: [
      {
        label: "# of Votes",
        data: [1200, 250, 300, 160],
        backgroundColor: ["#B763FF", "#FD6EC4", "#6A78FA", "#FFC95B"]
      }
    ]
  };
  return (
    <div className="box p-1 ">
      <b className="normal-size ">Overall Posts & Bids</b>
      <div className="flex justify-center">
        <div style={{ width: "350px", height: "400px" }}>
          <Doughnut data={data} className="mv-2" />
        </div>
      </div>
    </div>
  );
}

export default OverallPostsAndBids;
