import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatePriceSubscriptionPlansAction } from "../../../redux/action/subscriptionAction";
import { getCurrentDateTime } from "../../../helper/helper";
import { useNavigate } from "react-router-dom";

function UpdateMarketPriceSubscraptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, usersList } = useSelector(
    (state) => state.updatePriceSubscraiptions
  );

  useEffect(() => {
    dispatch(getUpdatePriceSubscriptionPlansAction());
  }, [dispatch]);
  return (
    <div>
      <div className="flex align-center  mb-2">
        <span
          class="material-symbols-outlined bold hover"
          onClick={() => navigate(-1)}
        >
          arrow_back
        </span>
        <b>Update Market Price Subscraptions</b>
      </div>
      <table className="striped">
        <thead>
          <tr className="small-text">
            <th>Name</th>
            <th>phoneNumber</th>
            <th>Subscription</th>
            <th>Number of Cities</th>
            <th>expiresOn</th>
          </tr>
        </thead>
        <tbody>
          {usersList.map((item, index) => {
            return (
              <tr className="small-text" key={index}>
                <td>{item.name}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.updatePricePack.name}</td>
                <td>{item.updatePricePack.cities?.length}</td>
                <td>{getCurrentDateTime(item.updatePricePack.expiresOn)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UpdateMarketPriceSubscraptions;
