import React from "react";

function PickupsList() {
  return (
    <div className="white box p-1 ">
      <div className="flex align-center space-between ">
        <b>Pickups History</b>
        <button className="btn-outline">View All</button>
      </div>
      {/*  <table className="striped">
        <thead>
          <tr className="capitalize small-text  ">
            <td>Date</td>
            <td>Quantity</td>
            <td>Price</td>
            <td>GST</td>
            <td>Status</td>
          </tr>
        </thead>

        <tbody className="small-text">
          <tr>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
          </tr>

          <tr>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
          </tr>

          <tr>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
          </tr>

          <tr>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
            <td>07.06.2023</td>
          </tr>
        </tbody>
  </table> */}

      <div className="flex align-center flex justify-center ">
        <p> No Pickups History</p>
      </div>
    </div>
  );
}

export default PickupsList;
