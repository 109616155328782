import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { readNotificationsAction } from "../../../redux/action/accountsAction";
import { useNavigate } from "react-router-dom";
import { getCurrentDateTime } from "../../../helper/helper";

function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, notifications } = useSelector(
    (state) => state.accountNotifications
  );
  return (
    <div className=" full-width ">
      <div className="p-1">
        <b className="normal-size  ">Notifications & Updates </b>
      </div>

      <div>
        {notifications.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                borderBottom: "1px  solid #efefef",
                padding: "7px 10px "
              }}
              className={!item.read && "pink lighten-6 "}
              onClick={() => {
                dispatch(readNotificationsAction(item._id));
                navigate(`/post-view/${item.post}`);
              }}
            >
              <div className="flex space-between  ">
                <p style={{ fontWeight: "500", fontSize: "15px" }}>
                  {item.title}
                </p>
                <p className="small-text grey-text ">
                  {getCurrentDateTime(item.timestamp)}
                </p>
              </div>

              <p style={{ fontWeight: "400", fontSize: "12px", color: "#999" }}>
                {item.body}
              </p>
            </div>
          );
        })}

        <div className="flex justify-center mt-1 ">
          <p className="small-text grey-text ">
            This is All Notifications You Have.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Notification;
