import React from "react";
import { CardPanel, Col, Row } from "react-materialize";
import { getPriceOffer } from "../helper/helper";

function SubscriptionCard({ state }) {
  console.log(state);
  return (
    <Col className="mt-1 hover box " style={{ width: "20vw" }}>
      <CardPanel className=" flex column align-center">
        <span
          class="material-symbols-outlined p-1 circle mt-1 lighten-4 white-text mb-1 "
          style={{ backgroundColor: state.color }}
        >
          subscriptions
        </span>
        <b>{state.planName}</b>
        <p>{state.numberOfMonths} Month</p>
        <del className=" grey-text ">₹{state.price}</del>

        <spna className="flex align-center mt-1 ">
          <p className="red-text small-text ">-{state.savingsPercentage}%</p>
          &nbsp;
        </spna>

        <div>
          {state.featurs.map((item, index) => {
            return (
              <Row className="flex align-center gap-1" key={index}>
                <span className="material-symbols-outlined purple lighten-4  box ">
                  check
                </span>
                <p className="small-text">{item}</p>
              </Row>
            );
          })}
        </div>
        <span>
          <p
            className=" noOfUsersBG "
            style={{
              ...styles.noOfUsersBG,
              backgroundColor: state.secondary_color,
              color: state.color
            }}
          >
            {state.numberOfUsers} Active users
          </p>
        </span>
      </CardPanel>
    </Col>
  );
}

const styles = {
  noOfUsersBG: {
    padding: "7px 25px",
    borderRadius: "25px"
  }
};

export default SubscriptionCard;
