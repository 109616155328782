import React from "react";
import { scrapMarketAdminURL } from "../redux/config/configURL";

function ScrapImage({ scrapID }) {
  return (
    <div>
      <img
        src={`${scrapMarketAdminURL}/scraps/read/image/${scrapID}`}
        width={"100%"}
        height={"25px"}
        alt="post-image"
        style={{ objectFit: "contain" }}
      />
    </div>
  );
}

export default ScrapImage;
