import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getSubscriptionPlansAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_SUBSCRIPTION" });
  scrapMarketAdmin
    .get("/subscriptions/read")
    .then((res) => {
      dispatch({ type: "GOT_SUBSCRIPTION", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SUBSCRIPTION" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getUpdatePriceSubscriptionPlansAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_SUBSCRIPTION" });
  scrapMarketAdmin
    .get("/market-price/subscriptions/read")
    .then((res) => {
      dispatch({ type: "GOT_SUBSCRIPTION_PLAN", payload: res.data });
    })
    .then((error) => {
      dispatch({ type: "CANNOT_GET_SUBSCRIPTION_PLAN" });
    });
};

export const addNewSubscriptionPlanAction =
  (newSubscriptionPlan) => async (dispatch) => {
    dispatch({ type: "ADDING_NEW_PLANE" });
    scrapMarketAdmin
      .post("/subscriptions/create", newSubscriptionPlan)
      .then((res) => {
        dispatch({ type: "NEW_PLAN_ADDED" });
        toast.success(res.data);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);

        dispatch({ type: "CANNOT_ADD_NEW_PALN" });
      });
  };

export const editSubscriptionPlansAction =
  (subscriptionPlan) => async (dispatch) => {
    dispatch({ type: "EDTTING_SUBSCRIPTION_PLAN" });
    scrapMarketAdmin
      .put("/subscriptions/update", { subscriptionPlan: subscriptionPlan })
      .then((res) => {
        dispatch({ type: "SUBSCRIPTION_PLANA_EDITED" });
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_EDIT_SUBSCRIPTION_PLANS" });
      });
  };

export const deleteSubscriptionPlanAction = (id) => async () => {
  scrapMarketAdmin
    .delete(`/subscription/delete/${id}`)
    .then((res) => {
      toast.success(res.data);
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getAllSubscriptionPlanArchiveAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_SUBSCRIPTION_ARCHIVE" });
  scrapMarketAdmin
    .get("/subscriptions/archive")
    .then((res) => {
      dispatch({ type: "GOT_SUBSCRIPTION_PLAN", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SUBSCRIPTION_ARCHIVE" });
    });
};
