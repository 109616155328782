import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Dropdown } from "react-materialize";
import { addToArchiveAction } from "../../redux/action/marketPriceAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../modules/Loading";
import ScrapImage from "../../modules/ScrapImage";

export function MarketPriceFilter({ setMaterial }) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectOption] = useState(null);

  const { allCities, loading } = useSelector((state) => state.getAllCities);
  const listOfScraps = [];

  const addToArchive = (scrapID) => {
    dispatch(addToArchiveAction(scrapID));
  };

  const getAllScraps = (allCities) => {
    allCities[0]?.scraps.forEach((scrap) => {
      const singleScrap = {
        value: { name: scrap.scrap.name, variant: scrap.scrap.variant },
        label: `${scrap.scrap.name}-${scrap.scrap.variant}`,
        id: scrap._id,
      };
      listOfScraps.push(singleScrap);
    });
  };

  useEffect(() => {
    if (!loading) {
      getAllScraps(allCities);
    }
  }, [dispatch, loading]);

  useEffect(() => {});
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="box full-height p-1 ">
      <div>
        <span className="flex align-center space-between mb-2  ">
          <b>Type of material</b>
        </span>
        <Select
          value={selectedOption}
          onChange={(e) => {
            setSelectOption();
            // setMaterialSelected(e.value);
            setMaterial(e.value);
          }}
          options={listOfScraps}
          onFocus={{ borderColor: "red" }}
        />

        {allCities[0]?.scraps?.map((scrap, index) => {
          return (
            <div
              className="flex align-center space-between box hover "
              style={{ padding: "10px", marginTop: "7px" }}
              key={index}
            >
              <div
                className="flex align-center "
                onClick={() => {
                  setMaterial({
                    name: scrap.scrap.name,
                    variant: scrap.scrap.variant,
                  });
                }}
              >
                <ScrapImage scrapID={scrap.scrap?._id} />
                <b className="small-text">
                  &nbsp;{scrap.scrap.name}-{scrap.scrap.variant}
                </b>
              </div>

              <Dropdown
                id={index}
                options={{
                  alignment: "right",
                }}
                trigger={
                  <div role="button">
                    <span class="material-symbols-outlined big-icon  ">
                      more_vert
                    </span>
                  </div>
                }
              >
                <ul>
                  <li
                    className="flex align-center small-text border-bottom  "
                    onClick={() => {
                      setMaterial({
                        name: scrap.scrap.name,
                        variant: scrap.scrap.variant,
                      });
                    }}
                  >
                    <span class="material-symbols-outlined cercle-purple-text ">
                      visibility
                    </span>
                    <b>View</b>
                  </li>
                  <li
                    className="flex align-center small-text"
                    onClick={() => addToArchive(scrap.scrap._id)}
                  >
                    <span class="material-symbols-outlined cercle-purple-text">
                      inventory_2
                    </span>
                    <b>Archive</b>{" "}
                  </li>
                </ul>
              </Dropdown>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// export { materialSelected }
