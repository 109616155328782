import React from "react";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

// export const getCurrentDateTime = (dateString) => {
//   const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = date.getMonth() + 1;
//   const day = date.getDate();
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   const seconds = date.getSeconds();
//   const formattedDate =
//     year +
//     "-" +
//     month +
//     "-" +
//     day +
//     " " +
//     hours +
//     ":" +
//     minutes +
//     ":" +
//     seconds;

//   return formattedDate;
// };

export const getCurrentDateTime = (dateString) => {
  const date = new Date(dateString);
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = monthsOfYear[date.getUTCMonth()];
  const dayOfMonth = date.getUTCDate();
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;
  return formattedDate;
};

export const decodeToken = async (token) => {
  let decoded = await (() => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      return null;
    }
  })();
  return decoded;
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function selectMaterial(e) {
  return e;
}

export function truncateString(str, maxWords) {
  let truncated = str.split(" ", maxWords).join(" ");

  if (str.split(" ").length > maxWords) {
    truncated += "...";
  }

  return truncated;
}

export function getPriceOffer(discountPercentage, price) {
  const discountAmount = (discountPercentage * price) / 100;

  return Math.floor(price - discountAmount);
}

export function getFirstAndLastLetters(words) {
  if (!words) {
    return;
  }
  const firstLetter = words[0][0];

  const lastWord = words[words.length - 1];
  const lastLetter = lastWord[0];

  return `${firstLetter}${lastLetter}`;
}

export function getWordStr(str) {
  return str?.split(/\s+/).slice(0, 5).join(" ");
}

export function rankData(data) {
  const uniqueSet = new Set(
    data.map((item) => {
      if (item.type == "battery") {
        return item.type + " (" + item.variant + ")";
      } else {
        return item.type;
      }
    })
  );

  // item.type
  const uniqueArray = Array.from(uniqueSet);

  return uniqueArray;
}
