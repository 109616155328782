import React from "react";
import { Col, CardPanel } from "react-materialize";

function PostsAnalytics({ buyingPost, sellingPost }) {
  return (
    <div className=" full-width mb-1 ">
      <div className="flex gap-1 ">
        <CardPanel className=" flex p-1 align-center" style={{ width: "100%" }}>
          <img src="../images/all-post.jpg" style={{ width: "90px" }} />
          <span className="flex column ml-1">
            <b className="normal-size">Total Posts</b>
            <b className="large-text">{buyingPost + sellingPost }</b>
            <p className="small-text grey-text ">Total Posts</p>
          </span>
        </CardPanel>

        <CardPanel
          className=" flex p-1 align-center "
          style={{ width: "100%" }}
        >
          <img src="../images/selling-post.jpg" style={{ width: "90px" }} />
          <span className="flex column ml-1">
            <b className="normal-size">Total Selling Posts</b>
            <b className="large-text">{sellingPost}</b>
            <p className="small-text grey-text ">Total Buying Posts</p>
          </span>
        </CardPanel>

        <CardPanel
          className=" flex p-1  align-center"
          style={{ width: "100%" }}
        >
          <img src="../images/buying-post.jpg" style={{ width: "90px" }} />
          <span className="flex column ml-1">
            <b className="normal-size">Total Buying Posts</b>
            <b className="large-text">{buyingPost}</b>
            <p className="small-text grey-text ">Total Buying Posts</p>
          </span>
        </CardPanel>
      </div>
    </div>
  );
}

export default PostsAnalytics;
