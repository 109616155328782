import React, { useEffect } from "react";
import {

  OverallSubscription,
  SellingPostsAnalyticsChart,
  BuyingPostsAnalyticsChart,
  UsersAnalyticsChart,
  // SubscriptionsCard,
  RecentActivityAnalytics,
  BestSellersAnalytics,
  RecentBuyersCard,
  RecentSellersCard,
  VerifiedThisMonth,
  // PostsAnalytics
} from "../components";
import { Row, Col } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsAction } from "../redux/action/accountsAction";
import UsersAnalytics from "../components/analytics/UsersAnalytics";

function Analytics() {
  const dispatch = useDispatch();
  const { analytics, loading } = useSelector((state) => state.analyticsStore);

  useEffect(() => {
    dispatch(getAnalyticsAction());
  }, [dispatch]);
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <>
      <Row className="  row">
        <UsersAnalytics
          allUsers={analytics.numberOfAccounts}
          freeUsers={analytics.freeUsers}
          paidUsers={analytics.paidUsers}
          verifiedUsers={analytics.verifiedUsers}
        />
      </Row>

      <Row className="row mt-1 ">
        <Col className="col s8  ">
          {/* <SubscriptionsCard
            subscriptions={analytics.subscriptions}
            loading={loading}
          /> */}
        </Col>
        <Col className="col s4">
          <OverallSubscription subscriptions={analytics.subscriptions} />
        </Col>
      </Row>

      <div className="mt-2">
        {/* <PostsAnalytics
          buyingPost={analytics.buyingPosts}
          sellingPost={analytics.sellingPosts}
        /> */}
      </div>

      <Row className="row">
        <Col className="col s6">
          <RecentBuyersCard recentBuyingPosts={analytics.recentBuyingPosts} />
        </Col>
        <Col className="col s6">
          <RecentSellersCard
            recentSellingPosts={analytics.recentSellingPosts}
          />
        </Col>
      </Row>

      <Row className="row mt-1 ">
        <Col className="col s7">
          <BestSellersAnalytics />
        </Col>
        <Col className="col s5">
          <RecentActivityAnalytics />
        </Col>
      </Row>

      <Row className="row ">
        <div className="col  s6">
          <SellingPostsAnalyticsChart />
        </div>
        <div className="col s6">
          <BuyingPostsAnalyticsChart />
        </div>
      </Row>

      {/*<VerifiedThisMonth />*/}

 

 

      <UsersAnalyticsChart />
    </>
  );
}

export default Analytics;
