import axios from "axios";
import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getAllPaymentsActions = (startDate, endDate) => (dispatch) => {
  dispatch({ type: "GETTING_PAYMENTS" });
  scrapMarketAdmin
    .get(`/payment/read/?startDate=${startDate}&endDate=${endDate}`)
    .then((res) => {
      dispatch({ type: "GOT_PAYMENTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PAYMENTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};
