import React, { useEffect } from "react";
import { getCurrentDateTime } from "../../helper/helper";
import PostImage from "../../modules/PostImage";
import ImagesSlider from "../../modules/ImagesSlider";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPostDetails } from "../../redux/action/offersAction";

function PostDEtails() {
  const dispatch = useDispatch();
  const { postDetails, loading } = useSelector(
    (state) => state.postDetailsStore
  );
  console.log(postDetails);
  const { postID } = useParams();
  useEffect(() => {
    dispatch(getPostDetails(postID));
  }, [dispatch]);
  return (
    <div className="box p-2 full-height ">
      <ImagesSlider images={postDetails.images} postID={postDetails._id} />
      <div className="flex space-between mt-2 ">
        <div>
          <b className="normal-size">
            {postDetails.scrap?.name}-{postDetails.scrap?.variant}
          </b>
          <p className="grey-text small-text ">{postDetails.type} scrap</p>
        </div>
        <p className="grey-text">{postDetails.quantity} Tonne</p>
      </div>
      <span className="flex align-end ">
        <b className=" normal-size green-text">₹{postDetails.price}</b>
        <p>/kg</p>
      </span>

      <div className="p-1 box-outLine mt-2 ">
        <p className="grey-text mb-1 ">Properties</p>

        <div className="flex align-center space-between mv-1 ">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              location_on
            </span>
            <p>Location:</p>
          </span>
          <p>
            {postDetails.location?.state},{postDetails.location?.city}
          </p>
        </div>

        <div className="flex align-center space-between mv-1 ">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              local_shipping
            </span>
            <p>Transport Enabled:</p>
          </span>
          <p>{postDetails.transportEnabled ? "Yes" : "No"}</p>
        </div>

        <div className="flex align-center space-between mv-1 ">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              apartment
            </span>
            <p>site Visit Enabled:</p>
          </span>
          <p>{postDetails.siteVisitEnabled ? "Yes" : "No"}</p>
        </div>

        <div className="flex align-center space-between mv-1">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">person</span>
            <p>Posted by:</p>
          </span>
          <p>{postDetails.account?.name}</p>
        </div>

        <div className="flex align-center space-between mv-1">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">phone</span>
            <p>Contact Information:</p>
          </span>
          <p>{postDetails.account?.phoneNumber}</p>
        </div>

        <div className="flex align-center space-between mv-1">
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              calendar_month
            </span>
            <p>Posted on :</p>
          </span>
          <p>{getCurrentDateTime(postDetails.createdAt)}</p>
        </div>
      </div>
    </div>
  );
}

export default PostDEtails;
