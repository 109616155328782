import React from "react";
import { Tab, Tabs } from "react-materialize";
import AllHistory from "./ClientAllHistory";
import ActiveBids from "./ClientActiveBids";
import ActivePosts from "./ClientActivePosts";

function ClientRecord({ singleAccount }) {
  return (
    <div>
      <Tabs>
        <Tab title="Active Posts">
          <ActivePosts posts={singleAccount.posts} />
        </Tab>
        <Tab title="Active Bids">
          <ActiveBids bids={singleAccount.bids} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default ClientRecord;
