import React, { useEffect } from "react";
import { SubscriptionCard } from "../../../modules";
import Loading from "../../../modules/Loading";

function Subscriptions({ subscriptionList, loading }) {
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="gap-1 flex flex-wrap ">
        {subscriptionList.map((plan, index) => {
          return (
            <SubscriptionCard
              state={{
                planName: plan.name,
                discountPercentage: plan.discountPercentage,
                price: plan.price,
                numberOfUsers: plan.numberOfUsers,
                offerPrice: plan.offerPrice,
                numberOfMonths: plan.numberOfMonths,
                featurs: plan.features,
                color: "#6A78FA",
                secondary_color: "#F1F7FF",
                savingsPercentage: plan.savingsPercentage
              }}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Subscriptions;
