import React, { useEffect, useState } from "react";
import Input from "../../modules/InputFeild";
import { RequireStare } from "../../modules";
import { useDispatch } from "react-redux";
import { recyclerURL } from "../../redux/config/configURL";
import {
  editRecyclerDetailsAction,
  uploadFileAction,
  deleteFileAction,
} from "../../redux/action/recycler";
import { Link, useParams } from "react-router-dom";

function EditRecyclerDetails({ singleRecycler }) {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [reRenderKey, setRenderKey] = useState(Math.random());
  const [recyclerDetails, setRecyclerDetails] = useState({
    organizationName: "",
    organizationEmail: "",
    EwastAuthorization: "",
    pecCard: "",
    CTOCertificate: "",
    gst: "",
    fireNoc: "",
    cibilScore: "",
    plasticAuthorization: "",
    PWPRegisteration: "",
    onsetDate:""
  });

  const [ewasteFile, setewasteFile] = useState("");
  const [ctoFile, setCTOFile] = useState("");
  const [PWPFile, setPWPFile] = useState("");


  const handleButtonClick = (fileId) => {
    const url =   `${recyclerURL}recycler/read/files/${id}/${fileId}`;
    window.open(url, '_blank');
  };


  function handleUpload(file, fileType) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    dispatch(uploadFileAction(id, formData, setRenderKey));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecyclerDetails({ ...recyclerDetails, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(editRecyclerDetailsAction(id, recyclerDetails, setLoading));
  };

  useEffect(() => {
    setRecyclerDetails({
      organizationName: singleRecycler.organizationName,
      organizationEmail: singleRecycler.organizationEmail,
      EwastAuthorization: singleRecycler.EwastAuthorization,
      pecCard: singleRecycler.panCard,
      CTOCertificate: singleRecycler.CTOCertificate,
      gst: singleRecycler.gst,
      fireNoc: singleRecycler.fireNoc,
      cibilScore: singleRecycler.cibilScore,
      plasticAuthorization: singleRecycler.plasticAuthorization,
      PWPRegisteration: singleRecycler.PWPRegisteration,
      onsetDate:singleRecycler.onsetDate
    });
  }, [singleRecycler]);

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <b className="normal-size  ">Organization Details:</b>
        <div className="flex align-center space-between gap-2 mb-1 ">
          <div className="flex column full-width ">
            <label>
              Organization Name: <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.organizationName,
                placeHolder: "Enter Recycler Name",
                icon: "person",
                iconColor: "#6f2da8",
                name: "organizationName",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width ">
            <label>
              Organization Email <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.organizationEmail,
                placeHolder: "Enter Recycler Email",
                icon: "email",
                iconColor: "#6f2da8",
                name: "organizationEmail",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>

        <div className="flex align-center space-between gap-2 mb-1">
          <div className="flex column full-width ">
            <label>
              Pan Card: <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.pecCard,
                placeHolder: "Enter Recycler Pan Card",
                icon: "credit_card",
                iconColor: "#6f2da8",
                name: "panCard",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="flex column full-width ">
            <label>plastic Authorization</label>
            <Input
              state={{
                value: recyclerDetails.plasticAuthorization,
                placeHolder: "Enter plastic Authorization",
                icon: "speed",
                iconColor: "#6f2da8",
                name: "plasticAuthorization",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>

        <div className="flex align-center space-between gap-2 mb-1">
          <div className="flex column full-width ">
            <label>
              GST Number <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.gst,
                placeHolder: "Enter Recycler GST number",
                icon: "admin_panel_settings",
                iconColor: "#6f2da8",
                name: "gst",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="flex column full-width ">
            <label className="flex">
              Fire NOC
              <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.fireNoc,
                placeHolder: "Enter fire NOC",
                icon: "settings_suggest",
                iconColor: "#6f2da8",
                name: "fireNOC",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>

        <div className="flex align-start space-between gap-2 mb-1">

        <span className="full-width" > 
          <div className="flex column  ">
            <label>Onset Date</label>
            <Input
              state={{
                value: recyclerDetails.onsetDate,
                placeHolder: "Enter Recycler onset date",
                icon: "calendar_month",
                iconColor: "#6f2da8",
                name: "onsetDate",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="flex column full-width mt-1">
          <label>Cibil Score</label>
          <Input
            state={{
              value: recyclerDetails.cibilScore,
              placeHolder: "Enter Recycler cibil score",
              icon: "speed",
              iconColor: "#6f2da8",
              name: "cibilScore",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        </span>
          <div className="flex column full-width ">
            <p className="flex">
              E-waste Authorization
              <RequireStare />
            </p>
            <Input
              state={{
                value: recyclerDetails.EwastAuthorization,
                placeHolder: " Enter E-wast Authorization",
                icon: "local_police",
                iconColor: "#6f2da8",
                name: "EwastAuthorization",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div className="flex mt-1 space-between align-center ">
              <input
                type="file"
                onChange={(e) => setewasteFile(e.target.files[0])}
                accept=".pdf"
              />
              {ewasteFile && (
                <span
                  class="material-symbols-outlined green-text  hover "
                  onClick={() => handleUpload(ewasteFile, "E-waste")}
                >
                  check_circle
                </span>
              )}
            </div>

            <div className="mt-1">
              {singleRecycler.documents?.map((file, index) => {
                if (file.fileType == "E-waste") {
                  return (
                    <div
                      className="flex align-center space-between full-width "
                      key={index}
                    >
                      <span className="flex">
                        <span class="material-symbols-outlined">
                          picture_as_pdf
                        </span>
                        <div className="flex align-center gap-1 " > 
                        <span>{file.fileName}</span>
                        <p className="small-text cercle-purple hover white-text"
                        style={{ padding:"2px 8px" , borderRadius:"5px" }}
                        onClick={() => handleButtonClick(file.fileId)}
                        >View</p>
                        </div>
                      </span>

                      <span
                        onClick={() =>
                          dispatch(deleteFileAction(id, file.fileId))
                        }
                        className="material-symbols-outlined red-text hover "
                      >
                        delete
                      </span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>

        <div className="flex align-center space-between gap-2 mb-1">
          <div className="flex column full-width ">
            <label>
              CTO Certificate <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.CTOCertificate,
                placeHolder: "Enter Recycler CTO Certificate",
                icon: "developer_guide",
                iconColor: "#6f2da8",
                name: "CTOCertificate",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div className="flex mt-1 space-between align-center ">
              <input
                type="file"
                onChange={(e) => setCTOFile(e.target.files[0])}
                accept=".pdf"
              />
              {ctoFile && (
                <span
                  class="material-symbols-outlined green-text  hover "
                  onClick={() => handleUpload(ctoFile, "CTO")}
                >
                  check_circle
                </span>
              )}
            </div>
            <div className="mt-1">
              {singleRecycler.documents?.map((file, index) => {
                if (file.fileType == "CTO") {
                  return (
                    <div
                      className="flex align-center space-between full-width "
                      key={index}
                    >
                      <span className="flex">
                        <span class="material-symbols-outlined">
                          picture_as_pdf
                        </span>
                        <div className="flex align-center gap-1 " > 
                        <span>{file.fileName}</span>
                        <p className="small-text cercle-purple hover white-text"
                        style={{ padding:"2px 8px" , borderRadius:"5px" }}
                        onClick={() => handleButtonClick(file.fileId)}
                        >View</p>
                        </div>
                      </span>

                      <span
                        onClick={() =>
                          dispatch(deleteFileAction(id, file.fileId))
                        }
                        className="material-symbols-outlined red-text hover "
                      >
                        delete
                      </span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="flex column full-width ">
            <label className="flex">
              PWP Registeration
              <RequireStare />
            </label>
            <Input
              state={{
                value: recyclerDetails.PWPRegisteration,
                placeHolder: "Enter PWP Registeration",
                icon: "cycle",
                iconColor: "#6f2da8",
                name: "PWPRegisteration",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <div className="flex mt-1 space-between align-center ">
              <input
                type="file"
                onChange={(e) => setPWPFile(e.target.files[0])}
                accept=".pdf"
              />
              {PWPFile && (
                <span
                  class="material-symbols-outlined green-text  hover "
                  onClick={() => handleUpload(PWPFile, "PWP")}
                >
                  check_circle
                </span>
              )}
            </div>
            <div className="mt-1">
              {singleRecycler.documents?.map((file, index) => {
                if (file.fileType == "PWP") {
                  return (
                    <div
                      className="flex align-center space-between full-width "
                      key={index}
                    >
                      <span className="flex">
                        <span class="material-symbols-outlined">
                          picture_as_pdf
                        </span>
                        <div className="flex align-center gap-1 " > 
                        <span>{file.fileName}</span>
                        <p className="small-text cercle-purple white-text hover"
                        style={{ padding:"2px 8px" , borderRadius:"5px" }}
                        onClick={() => handleButtonClick(file.fileId)}
                        >View</p>
                        </div>
                      </span>

                      <span
                        onClick={() =>
                          dispatch(deleteFileAction(id, file.fileId))
                        }
                        className="material-symbols-outlined red-text hover "
                      >
                        delete
                      </span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>

        <div className="flex full-width justify-end align-center gap-1">
          <Link to='/recyclers' > 
          <button className="btn-outline">Cancel</button>
          </Link>
          <button type="submit" className="btn">
            {loading ? "loading..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditRecyclerDetails;
