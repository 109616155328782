import React, { useEffect } from "react";
import { getArchiveAction } from "../../redux/action/marketPriceAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../modules/Loading";
import ScrapImage from "../../modules/ScrapImage";
import { unArchiveScrapAction } from "../../redux/action/marketPriceAction";

function Archive({ setOpenArchive }) {
  const dispatch = useDispatch();

  const removeHandle = (scrapID) => {
    dispatch(unArchiveScrapAction(scrapID));
  };

  const { loading, archive } = useSelector((state) => state.archiveStore);
  useEffect(() => {
    dispatch(getArchiveAction());
  }, [dispatch]);

  return (
    <div className="p-1 slide-consent white archive ">
      {loading && archive.length == 0 && <Loading />}
      <div className="flex align-center space-between ">
        <b>Archive </b>
        <span
          style={{ padding: "6px" }}
          className="material-symbols-outlined normal-size hover brown lighten-5  circle"
          onClick={() => setOpenArchive(false)}
        >
          close
        </span>
      </div>

      {archive.length == 0 && (
        <div className="flex  justify-center align-center column mt-4">
          <span
            className="material-symbols-outlined brown lighten-5  circle grey-text p-1  "
            style={{ fontSize: "30px" }}
          >
            inventory_2
          </span>
          <p className="grey-text small-text mt-1 ">
            There No Item In Archive.
          </p>
        </div>
      )}

      {archive.map((item, index) => {
        return (
          <div
            className="flex align-center space-between box hover "
            style={{ padding: "10px", marginTop: "7px" }}
            key={index}
          >
            <div className="flex align-center ">
              <ScrapImage scrapID={item?._id} />
              <b className="small-text">
                &nbsp;{item.name}-{item.variant}
              </b>
            </div>
            <span
              className="material-symbols-outlined brown lighten-5  circle cercle-purple-text "
              onClick={() => removeHandle(item._id)}
            >
              remove
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Archive;
