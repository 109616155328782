import React, { useEffect } from "react";
import Input from "../../../modules/InputFeild";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getPostDetails,
  getPostBidsAction,
  deleteBidAction,
  deletePostImageAction,
  updatePostAction
} from "../../../redux/action/offersAction";
import { Switch, Col } from "react-materialize";
import SingleBid from "../../../components/scrapMarket/SingleBid";
import { scrapMarketAdminURL } from "../../../redux/config/configURL";
import { useState } from "react";
import { toast } from "react-toastify";

function EditPost() {
  const dispatch = useDispatch();
  const { postID } = useParams();
  const { postDetails, loading } = useSelector(
    (state) => state.postDetailsStore
  );

  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [transport, setTransport] = useState(false);
  const [siteVisit, setSiteVisit] = useState(false);

  const { postBids } = useSelector((state) => state.postBidsStore);

  const handleSubmit = (e) => {
    if (!price || !quantity) {
      return toast.error("Please Complete All Requirment File.");
    } else {
      const data = new FormData();
      data.append("id", postID);
      data.append("price", price);
      data.append("quantity", quantity);
      data.append("transportEnabled", transport);
      data.append("siteVisitEnabled", siteVisit);
      dispatch(updatePostAction(data, postID));
    }
  };

  useEffect(() => {
    setQuantity(postDetails.quantity);
    setPrice(postDetails.price);
    setSiteVisit(postDetails.siteVisitEnabled);
    setTransport(postDetails.transportEnabled);
  }, [postDetails.price, postDetails.quantity, postID]);

  useEffect(() => {
    dispatch(getPostDetails(postID));
    dispatch(getPostBidsAction(postID));
  }, [dispatch, postID]);
  return (
    <div>
      <div className="flex space-between mb-1">
        <b>Edit Post</b>
        <div className="flex align-center gap-1  ">
          <button className="primary-btn" onClick={() => handleSubmit()}>
            SAVE CHANGES{" "}
          </button>
          <button className="btn-outline">CLOSE</button>
        </div>
      </div>
      <div className="row">
        <Col className="col s4  ">
          <div className="box p-1 full-height ">
            <b>Post Information</b>

            <form className="full-width">
              <label>Posted By</label>
              <Input
                state={{
                  placeHolder: "user Name",
                  value: postDetails.account?.name
                }}
              />
              <label>Phone Number</label>
              <Input
                state={{
                  placeHolder: "Enter ",
                  value: postDetails.account?.phoneNumber
                }}
              />
              <label>Price</label>
              <Input
                state={{
                  value: price,
                  placeHolder: "Enter Price.",
                  icon: "finance_chip",
                  iconColor: "#6f2da8"
                }}
                onChange={(e) => setPrice(e.target.value)}
              />

              <label>Quantity</label>

              <Input
                state={{
                  value: quantity,
                  placeHolder: "Enter Quantity.",
                  icon: "production_quantity_limits",
                  iconColor: "#6f2da8"
                }}
                onChange={(e) => setQuantity(e.target.value)}
              />

              <div className="flex align-center space-between mv-1 ">
                <span className="flex align-center ">
                  <span class="material-symbols-outlined purple-text ">
                    local_shipping
                  </span>
                  <p>&nbsp;Transport Enabled:</p>
                </span>
                <Switch
                  id="Switch-21"
                  onChange={() => setTransport(!transport)}
                  checked={transport}
                />
              </div>

              <div className="flex align-center space-between mv-1 ">
                <span className="flex align-center ">
                  <span class="material-symbols-outlined purple-text ">
                    apartment
                  </span>
                  <p>&nbsp;site Visit Enabled:</p>
                </span>

                <Switch
                  id="Switch-20"
                  onChange={() => setSiteVisit(!siteVisit)}
                  checked={siteVisit}
                />
              </div>
            </form>
          </div>
        </Col>
        <Col className="col  column flex gap-1 s8">
          <div className="box p-1">
            <b className="normal-size mb-1 ">Post Images</b>
            <div className="flex-wrap flex gap-1  mt-2 ">
              {postDetails.images?.map((image, index) => {
                return (
                  <div key={index} style={{ position: "relative" }}>
                    <span
                      class="material-symbols-outlined red lighten-5 red-text hover "
                      style={{
                        right: "0px",
                        zIndex: 2,
                        position: "absolute",
                        padding: "5px",
                        borderRadius: "5px"
                      }}
                      onClick={() =>
                        dispatch(deletePostImageAction(postID, index + 1))
                      }
                    >
                      delete
                    </span>
                    <img
                      src={`${scrapMarketAdminURL}/posts/read/image/${
                        postDetails._id
                      }?count=${index + 1}`}
                      width={"100%"}
                      height={"180px"}
                      alt="post-image"
                      style={{ objectFit: "contain", borderRadius: "10px" }}
                    />
                  </div>
                );
              })}
              <div
                className=" flex align-center justify-center hover "
                role={"button"}
                style={{
                  width: "180px",
                  border: "2px solid #6f2da8 ",
                  borderRadius: "10px"
                }}
              >
                <b className="bold cercle-purple-text ">+ Add Image</b>
              </div>
            </div>
          </div>
          <div className="box full-width full-height p-2  flex column ">
            <b className="normal-size mb-1 ">Bids on this scrap</b>
            <div className="flex gap-1 flex-wrap ">
              {postBids?.map((item, index) => {
                if (postBids.length == 0) {
                  return (
                    <div className="flex align-center justify-center  full-width full-height ">
                      <p>There No Bids On This Post.</p>
                    </div>
                  );
                }
                return (
                  <div style={{ position: "relative" }}>
                    <span
                      class="material-symbols-outlined red lighten-5 red-text hover "
                      style={{
                        right: "0px",
                        zIndex: 2,
                        position: "absolute",
                        padding: "5px",
                        borderRadius: "5px"
                      }}
                      onClick={() => dispatch(deleteBidAction(item._id))}
                    >
                      delete
                    </span>
                    <SingleBid key={index} singleBid={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default EditPost;
