import React from "react";
import HeaderRightSide from "./HeaderRightSide";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const { profile } = useSelector((state) => state.profileStore);
  const navigate = useNavigate();




  return (
    <div className="header-frame">
      {/* <p className="large-text">Scrap Market Dashboard</p> */}
      <div> 
      <p className="large-text">Dashboard</p>
      <p className="small-text " >ScrapTrade</p>
      </div>

      <HeaderRightSide />
    </div>
  );
}

export default Header;
