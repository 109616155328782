import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateOfferNotesAuction,
  deletePostNotAction
} from "../../../redux/action/offersAction";
import { Button, Textarea } from "react-materialize";

function OfferNotes({ notes, postId }) {
  const dispatch = useDispatch();
  const [addNotLoading, setAddNotLoading] = useState(false);
  const [newNote, setNewNote] = useState({
    message: ""
  });

  const addNewNote = (e) => {
    e.preventDefault();
    dispatch(updateOfferNotesAuction(newNote, postId, setAddNotLoading));
  };

  return (
    <div>
      <b>Post Notes Management</b>
      {(notes?.length == 0 || !notes) && (
        <div className="flex column justify-center align-center ">
          {" "}
          <img
            src="../images/no-note.png"
            style={{ width: "100px", height: "100px" }}
          />
          <p className="small-text grey-text ">There no note for now.</p>
        </div>
      )}

      <ul className="mt-2">
        {notes?.map((note, indexNot) => {
          return (
            <li className="flex align-center space-between ">
              <p className="capitalize">
                {indexNot + 1}.&nbsp;{note.message}
              </p>
              <span
                class="material-symbols-outlined hover  red-text normal-size"
                onClick={() => {
                  dispatch(deletePostNotAction(postId, note._id));
                }}
              >
                delete
              </span>
            </li>
          );
        })}
      </ul>

      <form onSubmit={addNewNote}>
        <b className="mt-1">Add New Note:</b>
        <Textarea
          className="browser-default input-field "
          placeholder="Add New Note."
          onChange={(e) => setNewNote({ message: e.target.value })}
        />
        <div className="flex align-center gap-1 justify-end align-end ">
          {" "}
          <Button className="btn-outline" modal="close" node="button">
            Close
          </Button>{" "}
          <button
            className="primary-btn"
            style={{ width: "100px" }}
            type="submit"
          >
            {addNotLoading ? "loading" : "Submit"}
          </button>{" "}
        </div>
      </form>
    </div>
  );
}

export default OfferNotes;
