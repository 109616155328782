import React, { useEffect, useState } from "react";
import { Tabs, Tab, Dropdown } from "react-materialize";
import UpdateMarketPriceForm from "./UpdateMarketPriceForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllCitiesAction } from "../../redux/action/marketPriceAction";
import Loading from "../../modules/Loading";
import { Modal } from "react-materialize";
import AddNewCity from "./AddNewCity";
import AddNewScrap from "./AddNewScrap";
import Archive from "./Archive";

function MarketPriceUpdation({ material }) {
  const dispatch = useDispatch();
  const [openArchive, setOpenArchive] = useState(false);

  const { allCities, loading } = useSelector((state) => state.getAllCities);

  const [activeCity, setActiveCity] = useState("");

  const handleClick = (id) => {
    window.location.replace(`/marketPrice#${id}`);
  };

  useEffect(() => {
    dispatch(getAllCitiesAction());
  }, [dispatch]);

  useEffect(() => {
    if (allCities) {
      setActiveCity(allCities[0]?.name);
    }
  }, [dispatch, allCities]);

  if (loading || !allCities) {
    return <Loading />;
  }

  return (
    <div className="box white full-height  p-1">
      <div className="flex space-between ">
        <div className="flex align-center gap-1">
          <b>Market Price Updation - {activeCity} </b>
          {material && (
            <b>
              {material?.name} - {material?.variant}{" "}
            </b>
          )}
        </div>
        <Dropdown
          id={"drop_down"}
          options={{
            alignment: "right",
          }}
          trigger={
            <div role="button">
              <span class="material-symbols-outlined big-icon  ">menu</span>
            </div>
          }
        >
          <ul>
            <li className="flex align-center small-text border-bottom  ">
              <Modal
                actions={[]}
                id="Modal-add-city"
                open={false}
                trigger={
                  <div className=" p-1 flex align-center gap-1 full-width">
                    <span class="material-symbols-outlined normal-size">
                      add_location
                    </span>
                    <b>Add City</b>
                  </div>
                }
              >
                <AddNewCity />
              </Modal>
            </li>

            <li className="flex align-center small-text border-bottom ">
              <Modal
                actions={[]}
                id="Modal-add-scrap"
                open={false}
                trigger={
                  <div className=" p-1 flex align-center gap-1 full-width ">
                    <span class="material-symbols-outlined normal-size">
                      docs_add_on
                    </span>
                    <b>Add Scrap</b>
                  </div>
                }
              >
                <AddNewScrap />
              </Modal>
            </li>

            <div
              className=" p-1 flex align-center gap-1 full-width"
              onClick={() => setOpenArchive(true)}
            >
              <span class="material-symbols-outlined normal-size ">
                inventory_2
              </span>
              <b>Archive</b>
            </div>
          </ul>
        </Dropdown>
      </div>

      {allCities.length > 0 ? (
        <div>
          <Tabs
            scope="tabs-22"
            options={{
              onShow: (e) => handleClick(e.id),
            }}
          >
            {allCities.map((item, index) => {
              return (
                <Tab title={item.name} key={index} idx={`city_${index}`}>
                  <UpdateMarketPriceForm
                    scraps={item.scraps}
                    cityId={item._id}
                    material={material}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </div>
      ) : (
        <Loading />
      )}
      {openArchive && <Archive setOpenArchive={setOpenArchive} />}
    </div>
  );
}

export default MarketPriceUpdation;
