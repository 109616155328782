import React, { useEffect } from "react";
import Input from "../../../modules/InputFeild";
import { Button } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { getSingleAccountAction } from "../../../redux/action/accountsAction";
import { useNavigate, useParams } from "react-router-dom";

function EditClientProfile() {
  const dispatch = useDispatch();
  const { accountID } = useParams();
  const navigate = useNavigate();

  const { singleAccount, loading } = useSelector(
    (state) => state.getSingleAccountStore
  );

  useEffect(() => {
    dispatch(getSingleAccountAction(accountID));
  }, [dispatch, accountID]);
  return (
    <div>
      <div className="flex align-center space-between">
        <span className="flex align-center gap-1 ">
          <span
            class="material-symbols-outlined hover "
            onClick={() => navigate(-1)}
          >
            arrow_back
          </span>
          <b className="normal-size">Edit Profile</b>
        </span>

        <Button>Save Changes</Button>
      </div>

      <div className="row mt-2 ">
        <div className="col s4">
          <div className="box p-1 flex column align-center ">
            <img src="../images/userImage.png" className="profile-img" />
            <form className="full-width">
              <label>Name</label>
              <Input
                state={{ placeHolder: "user Name", value: singleAccount.name }}
              />
              <label>Email</label>
              <Input
                state={{
                  placeHolder: "Enter Email",
                  value: singleAccount.email
                }}
              />
              <label>Phone Number</label>
              <Input
                state={{
                  placeHolder: "Enter Phone Number",
                  value: singleAccount.phoneNumber
                }}
              />
              <label>GST Number</label>
              <Input state={{ placeHolder: "GST Number" }} />

              <label>Company Name</label>
              <Input
                state={{
                  placeHolder: "Company Name",
                  value: singleAccount.organization
                }}
              />
              <label>Location</label>
              <Input
                state={{
                  placeHolder: "Company Location",
                  value: singleAccount.location?.district
                    ? singleAccount.location.district
                    : singleAccount.location?.city
                    ? singleAccount.location.city
                    : singleAccount.location?.state
                    ? singleAccount.location.state
                    : singleAccount.location?.country
                    ? singleAccount.location.country
                    : "Unknown"
                }}
              />
            </form>
          </div>
        </div>
        <div className="col s8 flex column gap-1  box p-1">
          <div className="flex  space-between  ">
            <div>
              <p>Total Price</p>
              <p className="input-field grey lighten-4 p-1 ">100</p>

              <div className="flex  column  ">
                <p className="normal-size mt-1">Subscription</p>
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>1 Month (299 coins)</span>
                </label>
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>3 Months (519 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>6 Months (899 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>1 Year (1,624 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Premium Pro (11,999 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Lifetime Platinum (9999 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Life Gold (34,999 coins)</span>
                </label>
              </div>
            </div>

            <div>
              <p>Total Bids</p>
              <p className="input-field grey lighten-4 p-1 ">40</p>

              <div className="flex  column  ">
                <p className="normal-size mt-1 ">Additional Credits</p>
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>625 coins (₹2,500)</span>
                </label>
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>3 Months (519 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>6 Months (899 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>1 Year (1,624 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Premium Pro (11,999 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Lifetime Platinum (9999 coins)</span>
                </label>{" "}
                <label>
                  <input class="with-gap" name="group3" type="radio" checked />
                  <span>Life Gold (34,999 coins)</span>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h6>Send message as notification for them</h6>
            <textarea
              type="text"
              className="input-field input-area p-1 mt-2 "
              style={{ height: "150px" }}
            />
            <div className="flex align-end justify-end mt-2 ">
              <button className="btn-outline">Send Notification</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditClientProfile;
