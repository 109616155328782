import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const appmanagementAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_APP_MANAGEMENT" });
  scrapMarketAdmin
    .get("/app-management/read")
    .then((res) => {
      dispatch({ type: "GOT_APP_MANAGEMENT", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_APP_MANAGEMENT" });
    });
};
export const updateAppManagementAction = (data) => async (dispatch) => {
  scrapMarketAdmin
    .get("/app-management/read")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: "REFERSH_APP_MANAGEMENT", update: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};
export const updateMarketPriceAction = () => async (dispatch) => {
  const id = toast.loading("updating market price...");
  dispatch({ type: "UPDATING_MARKET_PRICE" });
  scrapMarketAdmin
    .put("/app-management/update/top-market-price")
    .then((res) => {
      console.log(res.data);
      dispatch(updateAppManagementAction());
      toast.update(id, {
        render: "market price updated successfully",
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_UPDATE_MARKET_PRICE" });
      toast.update(id, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};



export const deleteUserManualAction = (videoId) => async (dispatch) => {
  const id = toast.loading("deleting video...");

  dispatch({ type: "DELETING_USER_MANUAL" });
  scrapMarketAdmin
    .put(`/app-management/update/remove-video/${videoId}`)
    .then((res) => {
      dispatch({ type: "REFERSH_APP_MANAGEMENT" });
      dispatch(updateAppManagementAction());

      toast.update(id, {
        render: "video deleted successfully",
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_DELETE_USER_MANUAL" });
      toast.update(id, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};

export const deleteCarouselAction = (carouselId) => async (dispatch) => {
  const id = toast.loading("deleting carousel image...");
  dispatch({ type: "DELETING_CAROUSEL" });
  scrapMarketAdmin
    .delete(`/app-management/delete/carousel/${carouselId}`)
    .then((res) => {
      dispatch(updateAppManagementAction());

      dispatch({ type: "CAROUSEL_DELETED" });
      toast.update(id, {
        render: "carousel image deleted successfully",
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_DELETE_CAROUSEL" });
      toast.update(id, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};

export const addNewUserManualAction = (data) => async (dispatch) => {
  const id = toast.loading("adding new video...");

  dispatch({ type: "ADDING_NEW_USER_MANUAL" });
  scrapMarketAdmin
    .put(`/app-management/create/add-video-usermanual`, data)
    .then((res) => {
      dispatch(updateAppManagementAction());

      toast.update(id, {
        render: "video added successfully",
        isLoading: false,
        autoClose: 5000,
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_NEW_USER_MANUAL" });
      toast.update(id, {
        render: error.response ? error.response?.data : error.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    });
};
