import React, { useState } from "react";
import Input from "../../modules/InputFeild";
import { useDispatch } from "react-redux";
import { addRecyclerScrapAction } from "../../redux/action/recycler";
import { useParams } from "react-router-dom";

function AddScrapForm() {
  const dispacth = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [newScrap, setNewScrap] = useState({
    name: "",
    type: "",
    variant: "",
    processingCapacity: "",
    quantityOfRecycled: "",
    category: "",
    price: "",
    availableCredits: "",
    processOutput: ""
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    dispacth(addRecyclerScrapAction(id, newScrap, setLoading));
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewScrap({ ...newScrap, [name]: value });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" align-center gap-2">
          <div className="full-width mt-1 ">
            <label>Add New Scrap</label>
            <div className="flex align-center gap-1 column full-width  ">
              <div className=" flex full-width gap-1 align-center mt-1 column ">
                <div className="flex gap-1  full-width">
                  <div className="flex column full-width ">
                    <label className="flex">Select Type</label>
                    <select
                      className="browser-default full-width"
                      onChange={(e) => handleChange(e)}
                      name="type"
                    >
                      <option>--select--</option>
                      <option value="battery">battery</option>
                      <option value="tyres">tyres</option>
                      <option value="eWaste">eWaste</option>
                      <option value="plastic">plastic</option>
                      <option value="paper">paper</option>
                      <option value="metal">metal</option>
                      <option value="solid-waste">solid-waste</option>
                    </select>
                  </div>

                  <div className="flex column full-width ">
                    <label className="flex">Enter Scrap Name</label>
                    <Input
                      state={{
                        value: newScrap.name,
                        placeHolder: "enter scrap name",
                        icon: "",
                        iconColor: "#0E9D1C",
                        name: "name"
                      }}
                      onChange={(e) => handleChange(e)}
                      style={{ marginBottom: "10px ", width: "100%" }}
                    />
                  </div>
                  <div className="flex column full-width ">
                    <label className="flex">Scrap Variant</label>
                    <Input
                      state={{
                        value: newScrap.variant,
                        placeHolder: "enter variant",
                        icon: "",
                        name: "variant",
                        iconColor: "#0E9D1C"
                      }}
                      onChange={(e) => handleChange(e)}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                </div>
                <div className="flex align-center space-between full-width gap-1">
                  <div className="flex column full-width ">
                    <label className="flex">Processing Capacity</label>
                    <Input
                      state={{
                        value: newScrap.processingCapacity,
                        placeHolder: "Enter processing Capacity",
                        icon: "settings_suggest",
                        iconColor: "#6f2da8",
                        name: "processingCapacity"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div className="flex column full-width ">
                    <label className="flex">Quantity of Recycled</label>
                    <Input
                      state={{
                        value: newScrap.quantityOfRecycled,
                        placeHolder: "Enter quantity Of Recycled",
                        icon: "cycle",
                        iconColor: "#6f2da8",
                        name: "quantityOfRecycled"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div className="flex column full-width ">
                    <label className="flex">Available Credits</label>
                    <Input
                      state={{
                        value: newScrap.availableCredits,
                        placeHolder: "Enter available credits",
                        icon: "cycle",
                        iconColor: "#6f2da8",
                        name: "availableCredits"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="flex align-center space-between full-width gap-1">
                  <div className="flex column full-width ">
                    <label className="flex">Select Category</label>
                    <select
                      className="browser-default full-width"
                      onChange={(e) => handleChange(e)}
                      name="category"
                      value={newScrap.category}
                    >
                      <option>--select--</option>
                      <option value="CAT I">CAT-I</option>
                      <option value="CAT II">CAT-II</option>
                      <option value="CAT III">CAT-III</option>
                      <option value="CAT IV">CAT-IV</option>
                    </select>
                  </div>
                  <div className="flex column full-width ">
                    <label className="flex">Scrap Price</label>
                    <Input
                      state={{
                        value: newScrap.price,
                        placeHolder: "enter price",
                        icon: "",
                        iconColor: "#0E9D1C",
                        name: "price"
                      }}
                      onChange={(e) => handleChange(e)}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>

                  <div className="flex column full-width ">
                    <label className="flex">Process Output</label>
                    <Input
                      state={{
                        value: newScrap.processOutput,
                        placeHolder: "enter process output",
                        icon: "",
                        iconColor: "#0E9D1C",
                        name: "processOutput"
                      }}
                      onChange={(e) => handleChange(e)}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex full-width justify-end mt-1">
            <button
              className="btn"
              disabled={
                !newScrap.name ||
                !newScrap.variant ||
                !newScrap.category ||
                !newScrap.processingCapacity ||
                !newScrap.quantityOfRecycled ||
                !newScrap.availableCredits
              }
            >
              {!loading ? "Submit" : "loading..."}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddScrapForm;
