import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsAction } from "../../../redux/action/accountsAction";

function AccountsAnalytics() {
  const dispatch = useDispatch();
  const { analytics, loading } = useSelector((state) => state.analyticsStore);
  useEffect(() => {
    dispatch(getAnalyticsAction());
  }, [dispatch]);

  return (
    <div className={"flex   gap-1 mv-2"}>
      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border cercle-purple"></span>

        <div className=" circle " style={{ backgroundColor: "#F4E7FF" }}>
          <span className="material-symbols-outlined  p-1 cercle-purple-text  ">
            person
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Total Users</p>
          <b className="card-title mt-1 "> {analytics.numberOfAccounts + 0}</b>
          <p className="small-text grey-text ">Number Of Total Users</p>
        </div>
      </div>

      <div
      className="flex align-center full-width white p-1 box  "
      style={{ position: "relative" }}
    >
      <span className="card-border orange"></span>

      <div className="circle orange lighten-5 ">
        <span className="material-symbols-outlined  p-1 orange-text">
          person
        </span>
      </div>

      <div className="ml-1">
        <p className="normal-size">Total Verified Users</p>
        <b className="card-title mt-1 ">{analytics.verifiedUsers + 0}</b>
        <p className="small-text grey-text ">Number Of Verified Users</p>
      </div>
    </div>

      {/* <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border green"></span>

        <div className="circle  green lighten-5">
          <span className="material-symbols-outlined  p-1 green-text">
            person
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Total Paid Users</p>
          <b className="card-title mt-1 ">
            {analytics.paidUsers ? analytics.paidUsers : 0}
          </b>
          <p className="small-text grey-text ">Number Of Paid Users</p>
        </div>
      </div> */}
{/* 
      <div
        className="flex align-center full-width white p-1 box  "
        style={{ position: "relative" }}
      >
        <span className="card-border red"></span>

        <div className="circle red lighten-5 ">
          <span className="material-symbols-outlined  p-1 red-text">
            person
          </span>
        </div>

        <div className="ml-1">
          <p className="normal-size">Total Free Users</p>
          <b className="card-title mt-1 ">{analytics.freeUsers + 0}</b>
          <p className="small-text grey-text ">Number Of Free Users</p>
        </div>
      </div> */}
{/* 
      */}
    </div>
  );
}

export default AccountsAnalytics;
