import React from "react";
import { CardPanel, Col, Row } from "react-materialize";

function ClientWallet({ singleAccount }) {
  return (
    <div>
      <Col m={3} s={12}>
        <CardPanel className=" flex align-center">
          <span class="material-symbols-outlined big-icon mr-1 purple p-1 circle lighten-5 purple-text">
            package
          </span>
          <span>
            <p className="grey-text">All Posts</p>
            <b className="normal-size">{singleAccount.posts?.length}</b>
          </span>
        </CardPanel>
      </Col>

      <Col m={3} s={12}>
        <CardPanel className=" flex align-center">
          <span class="material-symbols-outlined big-icon mr-1 purple p-1 circle lighten-5 purple-text">
            pan_tool
          </span>
          <span>
            <p className="grey-text">All Bids</p>
            <b className="normal-size">{singleAccount.bids?.length}</b>
          </span>
        </CardPanel>
      </Col>

      <Col m={3} s={12}>
        <CardPanel className=" flex align-center">
          <span class="material-symbols-outlined big-icon mr-1 purple p-1 circle lighten-5 purple-text">
            subscriptions
          </span>
          <span>
            <p className="grey-text">Subscription</p>
            <b className="normal-size">{singleAccount.subscription?.name}</b>
          </span>
        </CardPanel>
      </Col>

      <Col m={3} s={12}>
        <CardPanel className=" flex align-center">
          <span class="material-symbols-outlined big-icon mr-1 purple p-1 circle lighten-5 purple-text">
            toll
          </span>
          <span>
            <p className="grey-text">Selected Language</p>
            <b className="normal-size">{singleAccount.selectedLanguage}</b>
          </span>
        </CardPanel>
      </Col>
    </div>
  );
}

export default ClientWallet;
