export const authenticationService = {
  baseURL: 'https://api.auth.cerclex.com',
};

export const scrap_market_admin = {
  baseURL: 'https://api.scrap-market.cerclex.com',
};

export const recycler = {
  baseURL: 'https://api.datahub.thinktrash.co/',
};


;
