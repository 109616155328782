import React from "react";
import { SingleUserCard } from "../../modules";

function UsersAnalytics({ allUsers, freeUsers, paidUsers, verifiedUsers }) {
  return (
    <div className="flex full-width  ">
      <SingleUserCard
        state={{
          total: allUsers,
          title: "All Users",
          color: "#6f2da8",
          avg: "08",
          iconBG: "#F1F7FF",
          icon: "person",
        }}
      />

      <SingleUserCard
        state={{
          total: freeUsers,
          title: "Free Users",
          color: "#FF5733",
          avg: "08",
          iconBG: "#F1F7FF",
          icon: "person",
        }}
      />

      <SingleUserCard
        state={{
          total: paidUsers,
          title: "Paid Users",
          color: "#00FF0B",
          avg: "08",
          iconBG: "#F1F7FF",
          icon: "payments",
        }}
      />

      <SingleUserCard
        state={{
          total: verifiedUsers,
          title: "Verified Users",
          color: "#8eadcd",
          avg: "08",
          iconBG: "#F1F7FF",
          icon: "admin_panel_settings",
        }}
      />
    </div>
  );
}

export default UsersAnalytics;
