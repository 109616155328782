export const getBestSellerReducer = (
  state = { bestSellers: [], loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_BEST_SELLER":
      return { ...state, loading: true };
    case "GOT_BEST_SELLER":
      return { ...state, loading: false, bestSellers: action.payload };
    case "CANNOT_GET_BEST_SELLER":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getRecentJoinedReducer = (
  state = {
    loading: false,
    recentJoined: []
  },
  action
) => {
  switch (action.type) {
    case "GETTING_RECENT_JOINED":
      return { loading: true };
    case "GOT_RECENT_JOINED":
      return { loading: false, recentJoined: action.payload };
    case "CANNOT_GET_RECENT_JOINED":
      return { loading: false };
    default:
      return state;
  }
};

export const getRecentPostsReducer = (
  state = {
    loading: false,
    recentPosts: []
  },
  action
) => {
  switch (action.type) {
    case "GETTING_RECENT_POSTS":
      return { loading: true };
    case "GOT_RECENT_POSTS":
      return { loading: false, recentPosts: action.payload };
    case "CANNOT_GET_RECENT_POSTS":
      return { loading: false };
    default:
      return state;
  }
};

export const getSellingPostAnalyticsReducer = (
  state = { loading: false, sellingPosts: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_SELLING_POTS_ANALYTICS":
      return { ...state, loading: true };
    case "GOT_SELLING_POSTS_ANALYTICS":
      return { ...state, loading: false, sellingPosts: action.payload };
    case "CANNOT_GET_SELLING_POSTS_ANALYTICS":
      return { ...state, loading: false };
    default:
      return { ...state, loading: false };
  }
};

export const getBuyingPostAnalyticsReducer = (
  state = { loading: false, buyingPosts: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_BUYING_POSTS_ANALYTICS":
      return { ...state, loading: true };
    case "GOT_BIUING_POSTS_ANALYTICS":
      return { ...state, loading: false, buyingPosts: action.payload };
    case "CANNOT_GET_BUYING_POSTS_ANALYTICS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getJoinedUsersAnalyticsReducer = (
  state = { loading: false, joinedUsers: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_JOINED_USERS_ANALYTICS":
      return { ...state, loading: true };
    case "GOT_JOINED_USERS_ANALYTICS":
      return { ...state, loading: false, joinedUsers: action.payload };
    case "CANNOT_GET_JOINED_USERS_ANALYTICS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getVerifiedThisMonthReducer = (
  state = { loading: false, verifiedUsers: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_VERIFIED":
      return { ...state, loading: true };
    case "GOT_VERIFIED":
      return { ...state, loading: false, verifiedUsers: action.payload };
    case "CANNOT_VERIFIED":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAppliedForCreditLineReducer = (
  state = {
    accounts: [],
    loading: false
  },
  action
) => {
  switch (action.type) {
    case "GETTING_APPLIED_FOR_CREDIT_LINE":
      return { ...state, loading: true };
    case "GOT_APPLIED_FOR_CREDIT_LINE":
      return { ...state, loading: false, accounts: action.payload };
    case "CANNOT_GET_APPLIED_FOR_CREDIT_LINE":
      return { ...state, loading: false };
    default:
      return state;
  }
};
