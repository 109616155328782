export const getSubscriptionReducer = (
  state = { loading: false, subscriptionList: [], addNewPlanLoading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_SUBSCRIPTION":
      return { ...state, loading: true };
    case "GOT_SUBSCRIPTION":
      return { ...state, loading: false, subscriptionList: action.payload };
    case "CANNOT_GET_SUBSCRIPTION":
      return { ...state, loading: false };
    case "ADDING_NEW_PLANE":
      return { ...state, addNewPlanLoading: true };
    case "CANNOT_ADD_NEW_PALN":
      return { ...state, addNewPlanLoading: true };
    case "NEW_PLAN_ADDED":
      return { ...state, addNewPlanLoading: false };
    default:
      return state;
  }
};

export const editSubscriptionPlansReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case "EDTTING_SUBSCRIPTION_PLAN":
      return { ...state, loading: true };
    case "SUBSCRIPTION_PLANA_EDITED":
      return { ...state, loading: false };
    case "CANNOT_EDIT_SUBSCRIPTION_PLANS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const updatePriceSubscriptionReducer = (
  state = { loading: false, usersList: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_SUBSCRIPTION":
      return { ...state, loading: true };
    case "GOT_SUBSCRIPTION_PLAN":
      return { ...state, loading: false, usersList: action.payload };
    case "CANNOT_GET_SUBSCRIPTION_PLAN":
      return { ...state, loading: false };
    default:
      return state;
  }
};
