import React, { useState } from "react";
import { deleteUserManualAction } from "../../../redux/action/appManagementAction";
import { useDispatch } from "react-redux";
import { Modal } from "react-materialize";
import { Input } from "../../../modules";
import { addNewUserManualAction } from "../../../redux/action/appManagementAction";

function UserManual({ userManual }) {
  const dispatch = useDispatch();
  const [newVideo, setNewVideo] = useState({
    properties: "",
    URL: ""
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addNewUserManualAction(newVideo));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "type") {
      const newValue = value.toUpperCase();
      setNewVideo({ ...newVideo, [name]: newValue });
    } else {
      setNewVideo({ ...newVideo, [name]: value });
    }
  };

  return (
    <div className="mt-1">
      <span className="flex align-center space-between ">
        <b>User Manual</b>

        <Modal
          bottomSheet={false}
          fixedFooter={false}
          id="Modal-0"
          open={false}
          trigger={
            <button className="btn" node="button">
              Add video
            </button>
          }
        >
          <form className="flex column gap-1 " onSubmit={handleSubmit}>
            <b>Add Video</b>
            <Input
              state={{
                value: newVideo.properties,
                placeHolder: "Enter Video properties",
                icon: "",
                iconColor: "#999",
                name: "properties"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />

            <Input
              state={{
                value: newVideo.URL,
                placeHolder: "Enter Video URL",
                icon: "",
                iconColor: "#999",
                name: "URL"
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />

            <div className="flex align-center justify-end mt-1 ">
              <button className="btn">Save</button>
            </div>
          </form>
        </Modal>
      </span>
      <div className="white p-1 box mt-1">
        {userManual?.map((manual, index) => {
          return (
            <span className="flex gap-1 " key={index}>
              <span class="material-symbols-outlined red-text ">
                youtube_activity
              </span>
              <b>{manual.properties}</b>
              <p style={{ width: "50%" }} className="blue-text">
                {manual.URL}
              </p>
              <span
                className=" red-text material-symbols-outlined"
                onClick={() => dispatch(deleteUserManualAction(manual._id))}
              >
                delete
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default UserManual;
