import React, { useState } from "react";
import { Input } from "../../../modules";
import { Checkbox, Button } from "react-materialize";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Switch } from "react-materialize";
import { addNewSubscriptionPlanAction } from "../../../redux/action/subscriptionAction";

function AddSubscription() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState("");

  const handleInputChange = (event) => {
    setNewFeature(event.target.value);
  };

  const handleAddFeature = () => {
    if (newFeature.trim() !== "") {
      setNewSbscriptionPlan({
        ...newSubscriptionPlan,
        features: [...newSubscriptionPlan.features, newFeature]
      });
      setNewFeature("");
    }
  };

  const [newSubscriptionPlan, setNewSbscriptionPlan] = useState({
    name: "",
    numberOfMonths: "",
    features: [],
    offerPrice: "",
    costSavingsPercentage: "",
    minCities: "",
    initialCityPrice: "",
    additionalCityPrice: "",
    recommended: "",
  });

  const handleChange = (e, name) => {
    setNewSbscriptionPlan({
      ...newSubscriptionPlan,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    if (
      !newSubscriptionPlan.name ||
      !newSubscriptionPlan.numberOfMonths ||
      !newSubscriptionPlan.offerPrice
    ) {
      toast.error("All Field is required");
    } else {
      dispatch(addNewSubscriptionPlanAction(newSubscriptionPlan));
    }
  };

  return (
    <div className="flex column ">
      <b className="normal-size">Add New Plan</b>

      <form>
        <span className="flex gap-1 mt-1 ">
          <div className="full-width">
            <span className="">
              <p>Plan Name:</p>
              <Input
                state={{
                  value: newSubscriptionPlan.name,
                  placeHolder: "Plan Name",
                  icon: "subscriptions",
                  iconColor: "#0E9D1C",
                  name: "name"
                }}
                onChange={(e) => handleChange(e, "name")}
              />
            </span>

            <span className="">
              <p>Price (Offer):</p>
              <Input
                state={{
                  value: newSubscriptionPlan.offerPrice,
                  placeHolder: "Price (Offer)",
                  icon: "payments",
                  iconColor: "#0E9D1C",
                  name: "offerPrice",
                  type: "number"
                }}
                onChange={(e) => {
                  handleChange(e, "offerPrice");
                }}
              />
            </span>
          </div>

          <div className="full-width">
            <span className="">
              <p>Number Of Months:</p>
              <Input
                state={{
                  value: newSubscriptionPlan.numberOfMonths,
                  placeHolder: "Number Of Months",
                  icon: "calendar_month",
                  iconColor: "#0E9D1C",
                  name: "numberOfMonths",
                  type: "number"
                }}
                onChange={(e) => handleChange(e, "numberOfMonths")}
              />
            </span>

            <span className="">
              <p>Cost Savings Percentage</p>
              <Input
                state={{
                  value: newSubscriptionPlan.costSavingsPercentage,
                  placeHolder: "Cost Savings Percentage",
                  icon: "payments",
                  iconColor: "#0E9D1C",
                  name: "costSavingsPercentage",
                  type: "number"
                }}
                onChange={(e) => handleChange(e, "costSavingsPercentage")}
              />
            </span>
          </div>
        </span>

        <div className="flex align-center space-between gap-1">
          <span className="full-width">
            <p>Initial City Price:</p>
            <Input
              state={{
                value: newSubscriptionPlan.initialCityPrice,
                placeHolder: "Price",
                icon: "payments",
                iconColor: "#0E9D1C",
                name: "initialCityPrice",
                type: "number"
              }}
              onChange={(e) => handleChange(e, "initialCityPrice")}
            />
          </span>

          <span className="full-width">
            <p>Additional City Price:</p>
            <Input
              state={{
                value: newSubscriptionPlan.additionalCityPrice,
                placeHolder: "Price ",
                icon: "payments",
                iconColor: "#0E9D1C",
                name: "additionalCityPrice",
                type: "number"
              }}
              onChange={(e) => handleChange(e, "additionalCityPrice")}
            />
          </span>
        </div>

        <div className="flex align-center space-between gap-1">
          <span className="full-width">
            <p>Minemum Cities:</p>
            <Input
              state={{
                value: newSubscriptionPlan.minCities,
                placeHolder: "Price",
                icon: "payments",
                iconColor: "#0E9D1C",
                name: "minCities",
                type: "number"
              }}
              onChange={(e) => handleChange(e, "minCities")}
            />
          </span>

          <span className="full-width">
            <p>Recommended:</p>
            <Switch
              id="Switch-20"
              offLabel="No"
              onChange={(e) =>
                setNewSbscriptionPlan({
                  ...newSubscriptionPlan,
                  recommended: e.target.checked
                })
              }
              onLabel="Yes"
            />
          </span>
        </div>

        <b className="mv-2">Features:</b>
        <div>
          {newSubscriptionPlan.features?.map((item, index) => {
            return (
              <div key={index}>
                <p>
                  {" "}
                  {index + 1}.{item}
                </p>
              </div>
            );
          })}
        </div>

        <div>
          <input
            type="text"
            value={newFeature}
            onChange={handleInputChange}
            className="browser-default full-width p-1 border box "
            placeholder="please enter single feature."
          />

          <button
            onClick={handleAddFeature}
            className="btn-outline full-width "
          >
            + Add Feature
          </button>
        </div>

        <div className="flex justify-end align-center gap-1">
          <Button className="btn-outline" flat modal="close" node="button">
            Close
          </Button>
          <Button
            className="btn"
            role="button"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddSubscription;
