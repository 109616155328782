export const getAppManagementReducer = (
  state = {
    loading: false,
    appManagement: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_APP_MANAGEMENT":
      return { ...state, loading: true };
    case "GOT_APP_MANAGEMENT":
      return { ...state, loading: false, appManagement: action.payload };
    case "REFERSH_APP_MANAGEMENT":
      return { ...state, loading: false, appManagement: action.update };
    case "CANNOT_GET_APP_MANAGEMENT":
      return { ...state, error: false, loading: false };
    default:
      return state;
  }
};
