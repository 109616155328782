import React from "react";
import { getFirstAndLastLetters } from "../../helper/helper";
import ScarpList from "../../components/recycler/ScarpList";

function SingleRecyclerDetails({ singleRecycler }) {
  if (!singleRecycler) {
    return <div></div>;
  }
  return (
    <div className=" p-1">
      <span className="flex column align-center">
        <div
          className="p-5 cercle-purple flex align-center justify-center circle normal-size bold"
          style={{ width: "140px", height: "140px" }}
        >
          <b style={{ fontSize: "25px" }} className="white-text">
            {getFirstAndLastLetters(
              singleRecycler.organizationName?.split(" ")
            )}
          </b>
        </div>

        <b>{singleRecycler.organizationName}</b>
        <p>{singleRecycler.organizationEmail}</p>
      </span>

      <div>
        <b>Contact Details:</b>
        {singleRecycler.contactDetails?.length == 0 && (
          <div className="p-2 box justify-center align-center flex  ">
            {" "}
            <p>No Contact Details</p>{" "}
          </div>
        )}
        {singleRecycler.contactDetails?.map((item, index) => {
          return (
            <div className=" p-1 box mt-1  " key={index}>
              <span className="flex gap-1">
                <span className=" purple-text material-symbols-outlined normal-size">
                  person
                </span>
                <p>{item.name}</p>
              </span>

              <span className="flex gap-1">
                <span className=" purple-text material-symbols-outlined normal-size">
                  email
                </span>
                <p>{item.email}</p>
              </span>

              <span className="flex gap-1 ">
                <span className=" purple-text material-symbols-outlined normal-size">
                  phone
                </span>
                <p>{item.phoneNumber}</p>
              </span>
            </div>
          );
        })}
      </div>
      <div className="mt-2">
        <b>Locations:</b>
        {singleRecycler.locations?.map((item, index) => {
          return (
            <div key={index}>
              <p className="small-text grey-text ">{item.nickname}</p>
              <p className="small-text grey-text">state: {item.state}</p>
              <p className="small-text grey-text">Address: {item.address}</p>
            </div>
          );
        })}
      </div> 

      {singleRecycler.get && (
        <div className="mt-1">
          <b>GST:</b>
          <p className="small-text grey-text">{singleRecycler.gst}</p>
        </div>
      )}

      <div className="mt-1">
        <b>List of Scrap:</b>
        <ScarpList scraps={singleRecycler.scraps} />
      </div>
    </div>
  );
}

export default SingleRecyclerDetails;
