import React, { useEffect } from "react";
import { Col, CardPanel } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { getEcoDataHubAnalyticsAction } from "../../redux/action/recycler";
import { useNavigate } from "react-router-dom";
import VerifiedUsersAnalytics from "./VerifiedUsersAnalytics";

function EcoDataHubAnalytics() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, analytics } = useSelector(
    (state) => state.ecoDataHubAnalytics
  );
  useEffect(() => {
    dispatch(getEcoDataHubAnalyticsAction());
  }, [dispatch]);

  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <div className=" full-width mb-1 ">
      <div className="flex gap-1 ">
        <CardPanel className=" flex p-1 align-center" style={{ width: "100%" }}>
          <span
            class="material-symbols-outlined"
            style={{ ...styles.iconStyle, backgroundColor: "#FFB6C1" }}
          >
            group
          </span>
          <span className="flex column ml-1">
            <b className="normal-size">Total Accounts</b>
            <b className="large-text">{analytics.allRecycler}</b>
            <p className="small-text grey-text ">Total Accounts.</p>
          </span>
        </CardPanel>

        <CardPanel
          className=" flex p-1 align-center "
          style={{ width: "100%" }}
        >
          <span
            class="material-symbols-outlined"
            style={{ ...styles.iconStyle, backgroundColor: "#90EE90" }}
          >
            verified
          </span>
          <span className="flex column ml-1">
            <b className="normal-size">Verified Accounts</b>
            <b className="large-text">{analytics.verifyed}</b>
            <p className="small-text grey-text ">Total Verified Accounts.</p>
          </span>
        </CardPanel>

        <CardPanel
          className=" flex p-1  align-center hover "
          style={{ width: "100%" }}
          onClick={() => navigate("/customers")}
        >
          <span
            class="material-symbols-outlined"
            style={{ ...styles.iconStyle, backgroundColor: "#CBC3E370" }}
          >
            group
          </span>
          <span className="flex column ml-1">
            <b className="normal-size">All customers</b>
            <b className="large-text">{analytics.customers}</b>
            <p className="small-text grey-text ">
              All customers registered in epr credit.
            </p>
          </span>
        </CardPanel>
      </div>
      <VerifiedUsersAnalytics />
    </div>
  );
}

const styles = {
  iconStyle: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "50%",
    fontSize: "5rem",
    color: "#6f2da8"
  }
};

export default EcoDataHubAnalytics;
