import React from "react";
import { Tabs, Tab } from "react-materialize";

function ScrapMarket() {
  return (
    <div>
      <Tabs>
        <Tab title="Total Post"></Tab>
        <Tab title="Seller Post"></Tab>
        <Tab title="Buyer Posts"></Tab>
        <Tab title="Total Bids"></Tab>
        <Tab title="Seller Bids"></Tab>
        <Tab title="Buyer Bids"></Tab>
      </Tabs>
    </div>
  );
}

export default ScrapMarket;
