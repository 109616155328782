import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getAllAccountsAction = (skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTINIG_ALL_PROFILE" });
  scrapMarketAdmin
    .get(`profile/read/all-profiles?skip=${skip}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: "GOT_ALL_ACCOUNTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ACCOUNTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const filterProfileAction =
  (profile, skip, limit) => async (dispatch) => {
    dispatch({ type: "FILTERING_PROFILE" });
    scrapMarketAdmin
      .get(
        `/profile/read/search?profile=${profile}&skip=${skip}&limit=${limit}`
      )
      .then((res) => {
        dispatch({ type: "PROFILE_FILTERED", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_FILTER_PROFILE" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getFilterAccountByType = (type, skip, limit) => (dispatch) => {
  dispatch({ type: "GETTING_PROFILES" });
  scrapMarketAdmin
    .get(`profile/read/filter-type/${type}?skip=${skip}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: "GOT_ALL_FILTER_PROFILE", payload: res.data });
    })
    .then((error) => {
      dispatch({ type: "CANNOT_GET_PROFILES" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

// subscribed&skip=0&limit=1

export const filterAccountAction =
  (account, skip, limit) => async (dispatch) => {
    dispatch({ type: "FILTER_ACCOUNT" });
    scrapMarketAdmin
      .get(
        `/profile/read/search?account=${account}&skip=${skip}&limit=${limit}`
      )
      .then((res) => {
        dispatch({ type: "ACCOUNT_FILTERED", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_FILTER_ACCOUNT" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getSingleAccountAction = (accountID) => (dispatch) => {
  dispatch({ type: "GETTING_SINGLE_ACCOUNT" });
  scrapMarketAdmin
    .get(`/profile/read/single/${accountID}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_ACCOUNT", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ACCOUNT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const verifyAccountAction =
  (accountID, formData) => async (dispatch) => {
    dispatch({ type: "VERIFY_ACCOUNT" });
    scrapMarketAdmin
      .put(`/accounts/update/verify-account/${accountID}`, formData)
      .then((res) => {
        toast.success(res.data);
        window.location.href = "/userList";
        dispatch(getAllAccountsAction);
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_VERIFY_ACCOUNT" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const unVerifyAccount = (accountID) => async (dispatch) => {
  dispatch({ type: "UNVERFIY_ACCOUNT" });
  scrapMarketAdmin
    .put(`/account/update/unverify-account/${accountID}`)
    .then((res) => {
      toast.success("this account has been un verified");
      dispatch(getAllAccountsAction);
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_UNVERFIY_ACCOUNT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getAnalyticsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ANALYTICS" });
  scrapMarketAdmin
    .get("/analytics/read/overview")
    .then((res) => {
      dispatch({ type: "GOT_ANALYTICS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ANALYTICS" });
    });
};

export const getAccountNotification = () => async (dispatch) => {
  dispatch({ type: "GETTING_NOTIFICATIONS" });

  scrapMarketAdmin
    .get("profile/read/notifications-admin")
    .then((res) => {
      dispatch({ type: "GOT_NOTIFICATIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_NOTIFICATIONS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const readNotificationsAction = (notificationID) => async (dispatch) => {
  scrapMarketAdmin
    .put(`/profile/update/seen-notification/${notificationID}`)
    .then((res) => {
      dispatch(getAccountNotification());
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getProfileAction = () => async (dispatch) => {
  dispatch({ type: "GET_PROFILE" });
  scrapMarketAdmin
    .get("/profile/read")
    .then((res) => {
      localStorage.setItem("isAdmin", res.data?.isAdmin);
      dispatch({ type: "GOT_PROFILE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PROFILE" });
    });
};

export const seenAllNotificationsAction = () => async (dispatch) => {
  scrapMarketAdmin
    .put("/profile/update/seen-notifications-admin")
    .then((res) => {
      dispatch(getProfileAction());
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const unsuspendActionAccount = (accountID) => async (dispatch) => {
  dispatch({ type: "UN_BLOCKING_ACCOUNT" });
  scrapMarketAdmin
    .put(`/accounts/update/unsuspend/${accountID}`)
    .then((res) => {
      dispatch({ type: "ACCOUNT_SUSPEND" });
      toast.success(res.data);

      dispatch(getAllAccountsAction());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_SUSPEND_ACCOUNT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const suspendAccountAction = (accountID) => async (dispatch) => {
  dispatch({ type: "SUSPEND_ACCOUNT" });
  scrapMarketAdmin
    .put(`/accounts/update/suspend/${accountID}`)
    .then((res) => {
      dispatch({ type: "ACCOUNT_SUSPENDED" });
      toast.success(res.data);
      dispatch(getAllAccountsAction());
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const addNewNotAction = (accountID, noteContent) => async (dispatch) => {
  dispatch({ type: "ADD_NEW_ACTION" });
  scrapMarketAdmin
    .put(`/profile/update/add-note/${accountID}`, { note: noteContent })
    .then((res) => {
      toast.success("Note has been added.");
      dispatch({ type: "NOT_ADDED" });
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_ADD_NOTE" });
    });
};

export const deleteNoteAction = (accountID, noteIndex) => async (dispatch) => {
  dispatch({ type: "DELETEING_NOTE" });
  scrapMarketAdmin
    .delete(`/profile/delete/note/${accountID}/${noteIndex}`)
    .then((res) => {
      toast.success("note has been deleted.");
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_DELETE_NOTE" });
    });
};

export const addLeadOwnerAction =
  (accountID, leadOwner) => async (dispatch) => {
    dispatch({ type: "SELECTING_LEAD" });
    scrapMarketAdmin
      .put(`/profile/update/lead-owner/${accountID}`, {
        leadOwner: leadOwner
      })
      .then((res) => {
        toast.success("Lead Has Been Added.");
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
        dispatch({ type: "CANNOT_ADD_LEAD" });
      });
  };

export const getAdminsAccounts = () => async (dispatch) => {
  dispatch({ type: "GETING_ADMINS_ACCOUNTS" });
  scrapMarketAdmin
    .get("/access-permission/read/all-admins")
    .then((res) => {
      dispatch({ type: "GOT_ADMINS_ACCOUNTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ADMINS_ACCOUNTS" });
    });
};

export const addAccessPermissionAction =
  (id, permission) => async (dispatch) => {
    dispatch({ type: "EDIT_ACCOUNT_PERMISSION" });
    scrapMarketAdmin
      .put(
        `/access-permission/update/add-access/${id}?permission=${permission}`
      )
      .then((res) => {
        dispatch({ type: "ACCOUNT_PERMOSSION_EDITED" });
        toast.success(res.data);
        dispatch(getSingleAccountAction(id));
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
        toast.error("error2");
      });
  };

export const removeAccessPermissionAction =
  (id, permission) => async (dispatch) => {
    dispatch({ type: "EDIT_ACCOUNT_PERMISSION" });
    scrapMarketAdmin
      .put(
        `/access-permission/update/remove-access/${id}?permission=${permission}`
      )
      .then((res) => {
        toast.success(res.data);
        dispatch({ type: "ACCOUNT_PERMOSSION_EDITED" });
        dispatch(getSingleAccountAction(id));
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
        toast.error("error1");
      });
  };

export const inviteAdminToScrapTradeAction = (data) => async (dispatch) => {
  scrapMarketAdmin
    .put("/access-permission/update/add-admin/", data)
    .then((res) => {
      toast.success(res.data);
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};
