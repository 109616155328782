import React ,{ useState } from "react";
import Select from "react-select";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart
} from "recharts";
function AllMarketPriceChart() {

  const [selectedOption, setSelectOption] = useState(null);

  const options = [
    { value: "Metals", label: "Metals" },
    { value: "plastic", label: "Plastic" },
    { value: "can", label: "Can" }
  ];


  const data = [
    {
      name: "Page A",
      item: 500,
      pv: 2400,
      amt: 2400
    },
    {
      name: "Page B",
      item: 2900,
      pv: 1398,
      amt: 2210
    },
    {
      name: "Page C",
      item: 2000,
      pv: 9800,
      amt: 2290
    },
    {
      name: "Page D",
      item: 780,
      pv: 3908,
      amt: 2000
    },
    {
      name: "Page E",
      item: 1890,
      pv: 4800,
      amt: 2181
    },
    {
      name: "Page F",
      item: 390,
      pv: 3800,
      amt: 2500
    },
    {
      name: "Page G",
      item: 3490,
      pv: 4300,
      amt: 2100
    }
  ];
  return (
    <div className="mt-1 box white p-1 chart-dashboard ">
      <div className="flex space-between align-center  ">
        <p className="normal-size">Copper Market Price</p>
        <div style={{  width:"180px" }} >
        <Select
        value={selectedOption}
        onChange={setSelectOption}
        options={options}
        onFocus={{ borderColor: "red" }}

      
        />
        </div>
      </div>

      <ResponsiveContainer width="100%" height="95%">
        <LineChart width={600} height={400} data={data}>
          <Line type="monotone" dataKey="pv" stroke="#6f2da8" strokeWidth={3} />
          <XAxis dataKey="name" style={{ fontSize: "10px" }} />
          <YAxis style={{ fontSize: "10px", margin: 0, border: "none" }} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default AllMarketPriceChart;
