export const getAllAccountsReducer = (
  state = { loading: false, accounts: [] },
  action
) => {
  switch (action.type) {
    case "GETTINIG_ALL_PROFILE":
    case "GETTING_PROFILES":
    case "GETTING_MORE_ACCOUNTS":
      return { ...state, loading: true };
    case "GOT_ALL_ACCOUNTS":
      return { ...state, loading: false, accounts: action.payload };
    case "FILTERING_PROFILE":
      return { ...state, loading: true };
    case "PROFILE_FILTERED":
    case "GOT_ALL_FILTER_PROFILE":
      return { ...state, loading: false, accounts: action.payload };
    case "CANNOT_FILTER_PROFILE":
      return { ...state, loading: false };
    case "FILTER_ACCOUNT":
      return { ...state, loading: true };
    case "GOT_MORE_ACCOUNTS":
      return {
        ...state,
        loading: false,
        accounts: state.accounts.concat(action.payload)
      };
    case "CANNOT_FILTER_ACCOUNT":
      return { ...state, loading: false };
    case "ACCOUNT_FILTERED":
      return { ...state, loading: false, accounts: action.payload };
    case "CANNOT_GET_ACCOUNTS":
    case "CANNOT_GET_PROFILES":
    case "CANNOT_GET_MORE_ACCOUNTS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleAccountReducer = (
  state = { singleAccount: {}, loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_SINGLE_ACCOUNT":
      return { ...state, loading: true };
    case "GOT_SINGLE_ACCOUNT":
      return { ...state, loading: false, singleAccount: action.payload };
    case "CANNOT_GET_ACCOUNT":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAnalyticsReducer = (
  state = { loading: false, analytics: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_ANALYTICS":
      return { ...state, loading: false };
    case "GOT_ANALYTICS":
      return { ...state, loading: false, analytics: action.payload };
    case "CANNOT_GET_ANALYTICS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getNotificationReducer = (
  state = { loading: false, notifications: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_NOTIFICATIONS":
      return { ...state, loading: true };
    case "GOT_NOTIFICATIONS":
      return { ...state, loading: false, notifications: action.payload };
    case "CANNOT_GET_NOTIFICATIONS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getProfileReducer = (
  state = { loading: false, profile: {} },
  action
) => {
  switch (action.type) {
    case "GET_PROFILE":
      return { ...state, loading: true };
    case "GOT_PROFILE":
      return { ...state, loading: false, profile: action.payload };
    case "CANNOT_GET_PROFILE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const addNewNoteReducer = (state = { addNotLoading: false }, action) => {
  switch (action.type) {
    case "ADD_NEW_ACTION":
      return { ...state, addNotLoading: true };
    case "NOT_ADDED":
      return { ...state, addNotLoading: false };
    case "CANNOT_ADD_NOTE":
      return { ...state, addNotLoading: false };
    default:
      return state;
  }
};

export const getAdminsAccountsReducer = (
  state = {
    loading: false,
    adminsAccounts: []
  },
  action
) => {
  switch (action.type) {
    case "GETING_ADMINS_ACCOUNTS":
      return { ...state, loading: true };
    case "GOT_ADMINS_ACCOUNTS":
      return { ...state, loading: false, adminsAccounts: action.payload };
    case "CANNOT_GET_ADMINS_ACCOUNTS":
      return { ...state, loading: false };
    default:
      return state;
  }
};
