import React from "react";
import { Slider, Slide, Caption } from "react-materialize";
import { scrapMarketAdminURL } from "../redux/config/configURL";

function ImagesSlider({ images, postID }) {
  if (images?.length == 0) {
    return (
      <div>
        <img
          src={`${scrapMarketAdminURL}/posts/read/image/${postID}`}
          width={"100%"}
          height={"300px"}
          alt="post-image"
          style={{ objectFit: "contain" }}
        />
      </div>
    );
  }
  return (
    <div>
      <Slider
        fullscreen={false}
        options={{
          duration: 5,
          height: 380,
          indicators: true,
          interval: 6000
        }}
      >
        {images?.map((image, index) => {
          return (
            <Slide key={index}>
              <img
                src={`${scrapMarketAdminURL}/posts/read/image/${postID}?count=${
                  index + 1
                }`}
                width={"100%"}
                height={"300px"}
                alt="post-image"
                style={{ objectFit: "contain" }}
                key={index}
              />
            </Slide>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImagesSlider;
