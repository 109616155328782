import React, { useEffect } from "react";
import Select from "react-select";
import states from "./indiaState.json";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  filterReyclerDataAction,
  getAllRecyclersAction,
} from "../../redux/action/recycler";
import Input from "../../modules/InputFeild";

function EcoDataHubFilter({
  state,
  organizationName,
  type,
  category,
  clearFilter,
  setOrganizationName,
  setState,
  setCategory,
  setType,
  renderKey,
  currentPage,
  numberOfRow,
}) {
  const dispatch = useDispatch();
  let options = [];
  const scrapType = [
    {
      value: "battery",
      label: "Battery",
    },
    {
      value: "tyre",
      label: "Tyre",
    },
    {
      value: "eWaste",
      label: "E-Waste",
    },
    {
      value: "plastic",
      label: "Plastic",
    },
    {
      value: "paper",
      label: "Paper",
    },
    {
      value: "metal",
      label: "Metal",
    },
    {
      value: "used oil",
      label: "Used Oil",
    },
  ];

  const categoriesOptions = [
    { value: "CAT I", label: "CAT I" },
    { value: "CAT II", label: "CAT II" },
    { value: "CAT III", label: "CAT III" },
    { value: "CAT IV", label: "CAT IV" },
  ];

  states.states.forEach((item) => {
    options.push({ value: item, label: item });
  });

  function handleSubmit() {
    dispatch(
      filterReyclerDataAction(
        numberOfRow * currentPage,
        numberOfRow,
        state,
        type,
        category,
        organizationName
      )
    );
  }
  return (
    <from className="flex gap-1 full-width justify-center align-end  ">
      <div className="full-width">
        {/* <p>Search by name:</p> */}
        <Input
          state={{
            value: organizationName,
            placeHolder: "Enter name",
            icon: "domain",
            iconColor: "#6f2da8",
          }}
          onChange={(e) => setOrganizationName(e.target.value)}
          key={renderKey}
        />
      </div>
      <div className="full-width">
        {/* <p>Type:</p> */}
        <Select
          options={scrapType}
          onChange={(e) => setType(e.value)}
          key={renderKey}
        />
      </div>
      <div className="full-width">
        {/* <p>State:</p> */}
        <Select
          options={options}
          onChange={(e) => setState(e.value)}
          key={renderKey}
        />
      </div>
      <div className="full-width">
        {/* <p>Category:</p> */}
        <Select
          options={categoriesOptions}
          onChange={(e) => setCategory(e.value)}
          key={renderKey}
        />
      </div>
      <button className="btn btn-outline " onClick={() => clearFilter()}>
        Clear
      </button>
      <button className="btn" type="submit" onClick={() => handleSubmit()}>
        Search
      </button>
    </from>
  );
}

export default EcoDataHubFilter;
