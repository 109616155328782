import React from "react";
import ReactLoading from "react-loading";

export default function SpinLoading() {
  return (
    <div>
      <ReactLoading type={"spin"} color="#6f2da8" style={{ width: "30px" }} />
    </div>
  );
}
