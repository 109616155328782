import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getBuyingPostsAction = (skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTING_BUYING_POSTS" });
  scrapMarketAdmin
    .get(`/posts/read/buying-posts?skip=${skip}&limit=${limit}`)
    .then((res) => {
      console.log(res.data?.length);
      dispatch({ type: "FOT_BIUING_POSTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_BUYING_POSTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
  // .get(`/posts/read/admin-buying-posts?skip=${skip}&limit=${limit}`)
};

export const getPostDetails = (postID) => async (dispatch) => {
  dispatch({ type: "GETTING_POST_DETAILS" });
  scrapMarketAdmin
    .get(`/posts/read/${postID}`)
    .then((res) => {
      dispatch({ type: "GOT_POST_DETAILS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_POST_DETAILS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSellingPostsAction = (skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTING_SELLING_POSTS" });
  scrapMarketAdmin
    .get(`/posts/read/selling-posts?skip=${skip}&limit=${limit}`)
    .then((res) => {
      dispatch({ type: "GOT_SELLING_POSTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SELLING_POST" });
      toast.error(error.response ? error.response?.data : error.message);
    });
  // .get(`/posts/read/admin-selling-posts?skip=${skip}&limit=${limit}`)
};

export const getPostBidsAction = (postId) => async (dispatch) => {
  dispatch({ type: "GETTING_POST_BIDS" });
  scrapMarketAdmin
    .get(`/bids/read/${postId}`)
    .then((res) => {
      dispatch({ type: "GOT_POST_BIDS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_BIDS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSingleBidAction = (bidID) => async (dispatch) => {
  dispatch({ type: "GETTING_BID" });
  scrapMarketAdmin
    .get(`/bids/read/single/${bidID}`)
    .then((res) => {
      dispatch({ type: "GOT_BID", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_BID" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deletePostAction = (postID, navigate) => async (dispatch) => {
  dispatch({ type: "DELETING_POST" });
  const data = {
    ids: [postID]
  };
  scrapMarketAdmin
    .delete("posts/delete/admin", { data })
    .then((res) => {
      dispatch({ type: "POST_DELETED" });
      toast.success(res.data);
      navigate("/offers");
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_DELETE_POST" });
    });
};

export const deleteTablePostAction = (postID, navigate) => async (dispatch) => {
  dispatch({ type: "DELETING_POST" });
  const data = {
    ids: [postID]
  };
  scrapMarketAdmin
    .delete("posts/delete/admin", { data })
    .then((res) => {
      dispatch({ type: "POST_DELETED" });
      toast.success(res.data);
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
      dispatch({ type: "CANNOT_DELETE_POST" });
    });
};

export const getAllScrapsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_SCRAPS" });
  scrapMarketAdmin
    .get("/scraps/read")
    .then((res) => {
      dispatch({ type: "GOT_ALL_SCRAPS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SCRAPS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const filterBuyingPostsAction = (scrpasIDs) => async (dispatch) => {
  dispatch({ type: "FILTERING_POSTS" });
  scrapMarketAdmin
    .post("/posts/read/search?type=buy", { scraps: scrpasIDs })
    .then((res) => {
      dispatch({ type: "POSTS_FILTERED", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_FILTER_POSTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const filterSellingPostsAction = (scrpasIDs) => async (dispatch) => {
  dispatch({ type: "FILTERING_SELLING_POSTS" });
  scrapMarketAdmin
    .post("/posts/read/search?type=sell", { scraps: scrpasIDs })
    .then((res) => {
      dispatch({ type: "POSTS_SELLING_FILTERED", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_FILTER_SELLING_POSTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deleteBidAction = (bidIDs) => async (dispatch) => {
  // dispatch({ type: "DELETE_BID" });
  // const data = {
  //   ids: [bidIDs]
  // };
  // scrapMarketAdmin
  //   .delete("/bids/delete", data)
  //   .then((res) => {
  //     toast.success(res.data);
  //   })
  //   .catch((error) => {
  //     toast.error(error.response ? error.response?.data : error.message);
  //   });
};

export const deletePostImageAction = (postID, count) => async (dispatch) => {
  dispatch({ type: "DELETE_POST_IMAGE" });

  scrapMarketAdmin
    .delete(`/posts/delete/image/${postID}?count=${count}`)
    .then((res) => {
      toast.success(res.data);
      window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const updatePostAction = (data, postID) => async (dispatch) => {
  dispatch({ type: "UPDATE_POST" });
  scrapMarketAdmin
    .put("/posts/update", data)
    .then((res) => {
      toast.success(res.data);
      dispatch(getPostDetails(postID));
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const addNewOfferAction =
  (offer, setLoadingAction) => async (dispatch) => {
    setLoadingAction(true);
    dispatch({ type: "ADDING_NEW_OFFER" });
    scrapMarketAdmin.post("/posts/create/pending", offer).then((res) => {
      setLoadingAction(true);
      window.location.reload();
      toast.success("New offer added");
      dispatch({ type: "NEW_OFFER_ADDED" }).catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);

        dispatch({ type: "CANNOT_ADD_NEW_OFFER" });
      });
    });
  };

export const getPendingPostsAction = (skip, limit) => async (dispatch) => {
  dispatch({ type: "GETTING_PENDING_POSTS" });
  scrapMarketAdmin
    .get(`/posts/read/pending-posts?skip=${skip}&limit=${limit}`)

    .then((res) => {
      dispatch({ type: "GOT_PENDING_POSTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_PENDING_POST" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const updateSoldOferAction = (id, newStatus) => async (dispatch) => {
  const toastId = toast.loading("Updating post status...");
  scrapMarketAdmin
    .put(`/posts/update/sold/${id}?status=${newStatus}`)
    .then((res) => {
      toast.update(toastId, {
        render: "Post status updated",
        type: "success",
        isLoading: false,
        autoClose: 2000
      });
    })
    .catch((error) => {
      toast.update(toastId, {
        render: "Cannot update post status",
        type: "error",
        isLoading: false,
        autoClose: 2000
      });
    });
};

export const verifiedOferAction = (id) => async (dispatch) => {
  const toastId = toast.loading("Updating post status...");
  scrapMarketAdmin
    .put(`/posts/update/verify-post/${id}`)
    .then((res) => {
      toast.update(toastId, {
        render: "Post status updated",
        type: "success",
        isLoading: false,
        autoClose: 2000
      });
    })
    .catch((error) => {
      console.log(error);
      toast.update(toastId, {
        render: "Cannot update post status",
        type: "error",
        isLoading: false,
        autoClose: 2000
      });
    });
};

export const updateOfferNotesAuction =
  (notes, postId, setAddNotLoading) => async (dispatch) => {
    scrapMarketAdmin
      .put(`/posts/update/update-notes/${postId}`, { notes: notes })
      .then((res) => {
        setAddNotLoading(false);
        toast.success("Notes updated");
      })
      .catch((error) => {
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const deletePostNotAction = (postId, notId) => async (dispatch) => {
  scrapMarketAdmin
    .delete(`/posts/delete/single-note/${postId}/${notId}`)
    .then((res) => {
      toast.success("Note deleted");
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};
