import React, { useEffect } from "react";
import { getAllCustomersAction } from "../../redux/action/recycler";
import { useDispatch, useSelector } from "react-redux";

function Customers() {
  const dispatch = useDispatch();
  const { loading, customers } = useSelector((state) => state.customersData);

  useEffect(() => {
    dispatch(getAllCustomersAction());
  }, [dispatch]);
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <div>
      <b>Customers</b>

      <div>
        <table className="striped">
          <thead className="small-text">
            <tr>
              <th>Name</th>
              <th>phoneNumber</th>
              <th>Email</th>
              <th>Organization</th>
              <th>RequireCredits</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((item, index) => {
              return (
                <tr>
                  <td>{item.name}</td>

                  <td>{item.phoneNumber}</td>

                  <td>{item.email}</td>

                  <td>{item.organization}</td>
                  <td>
                    {item.requireCredits.map((item, index) => {
                      return "("+item.category +  " "+ item.requireCredites+") " 
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Customers;
