import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../modules/InputFeild";
import { editRecyclerContactDetailsAction , deleteRecyclerContactDetailsAction } from "../../redux/action/recycler";
import { toast } from "react-toastify";

function EditContactDetails({ contactDetails }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editItemId, setEditItemId] = useState("");

  const [editData, setEditData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleSubmit = (itemID) => {
    if (editData.name == "") {
      return toast.error("Please Enter Name");
    }
    dispatch(editRecyclerContactDetailsAction(id, editItemId, editData));
  };

  return (
    <div>
      {contactDetails?.map((item, index) => {
        if (editItemId == item._id) {
          return (
            <div
              className=" p-1 box mt-1  gap-1 flex column"
              style={{ position: "relative" }}
              key={index}
            >
              <span className="flex ">
               
                <Input
                  state={{
                    value: editData.name,
                    placeHolder: "Enter Name",
                    icon: "person",
                    iconColor: "#6f2da8",
                    name: "name",
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </span>

              <span className="flex gap-1">
               
                <Input
                  state={{
                    value: editData.email,
                    placeHolder: "Enter email",
                    icon: "email",
                    iconColor: "#6f2da8",
                    name: "email",
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </span>

              <span className="flex gap-1 ">
               
                <Input
                  state={{
                    value: editData.phoneNumber,
                    placeHolder: "Enter phone number",
                    icon: "phone",
                    iconColor: "#6f2da8",
                    name: "phoneNumber",
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </span>
              <span
                 class="material-symbols-outlined green-text hover p-1"
                 onClick={() => handleSubmit(item._id)}
                style={{
                  top: "0",
                  right: "10px",
                  zindex: "999",
                  position: "absolute",
                }}
              >
                check_circle
              </span>
              <span
               class="material-symbols-outlined red-text p-1 hover"
               onClick={() => setEditItemId("")}
                style={{
                  top: "0",
                  right: "40px",
                  zindex: "999",
                  position: "absolute",
                }}
              >
                cancel
              </span>
            </div>
          );
        } else {
          return (
            <div
              className=" p-1 box mt-1 "
              style={{ position: "relative" }}
              key={index}
            >
              <span className="flex gap-1">
                <span className=" purple-text material-symbols-outlined normal-size">
                  person
                </span>
                <p>{item.name}</p>
              </span>

              <span className="flex gap-1">
                <span className=" purple-text material-symbols-outlined normal-size">
                  email
                </span>
                <p>{item.email}</p>
              </span>

              <span className="flex gap-1 ">
                <span className=" purple-text material-symbols-outlined normal-size">
                  phone
                </span>
                <p>{item.phoneNumber}</p>
              </span>
             {  editItemId == item._id ? <span
                onClick={() =>
                  dispatch(deleteRecyclerContactDetailsAction(id, item._id))
                }
                className="material-symbols-outlined red-text p-1 hover "
                style={{
                  top: "0",
                  right: "10px",
                  zindex: "999",
                  position: "absolute",
                }}
              >
                delete2
              </span> : 
              <span
              onClick={() =>
                dispatch(deleteRecyclerContactDetailsAction(id, item._id))
              }
              className="material-symbols-outlined red-text p-1 hover "
              style={{
                top: "0",
                right: "10px",
                zindex: "999",
                position: "absolute",
              }}
            >
              delete
            </span>
              
              }
              <span
                className="material-symbols-outlined  p-1 hover "
                style={{
                  top: "0",
                  right: "40px",
                  zindex: "999",
                  position: "absolute",
                }}
                onClick={() => {
                  setEditItemId(item._id);
                  setEditData({
                    name: item.name,
                    email: item.email,
                    phoneNumber: item.phoneNumber,
                  });
                }}
              >
                edit
              </span>
            </div>
          );
        }
      })}
    </div>
  );
}

export default EditContactDetails;
