import React, { useState } from "react";
import { CardPanel, Col, Row } from "react-materialize";
import Input from "./InputFeild";

function EditAdditionalCard({ state }) {
  const [planeDuration, setPlaneDuration] = useState(null);
  const [amount, setAmount] = useState(null);
  const [coins, setCoins] = useState(null);

  return (
    <Col m={2} s={12} className="mt-2 hover">
      <CardPanel className=" flex column align-center">
        <span
          class="material-symbols-outlined card-icon  lighten-4 white-text mb-1 mt-1 "
          style={{ backgroundColor: state.color }}
        >
          toll
        </span>
        <span className="flex aling-center justify-center mv-1">
          <p
            className=" noOfUsersBG "
            style={{
              ...styles.noOfUsersBG,
              backgroundColor: state.secondary_color,
              color: state.color
            }}
          >
            {state.activeUsers} Active users
          </p>
        </span>

        <div>
          <Input
            state={{ value: planeDuration, placeHolder: "plane Duration" }}
            onChange={setPlaneDuration}
          />
          <Input
            state={{
              value: planeDuration,
              placeHolder: "plane Amount",
              icon: "currency_rupee",
              iconColor: "#0E9D1C"
            }}
            onChange={setPlaneDuration}
          />
          <Input
            state={{
              value: planeDuration,
              placeHolder: "coin Number",
              icon: "database",
              iconColor: "#FFD400"
            }}
            onChange={setPlaneDuration}
          />
        </div>
      </CardPanel>
    </Col>
  );
}

const styles = {
  noOfUsersBG: {
    padding: "7px 25px",
    borderRadius: "25px"
  }
};
export default EditAdditionalCard;
