import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserAtion } from "../../../redux/action/marketPriceAction";
import { getProfileAction } from "../../../redux/action/accountsAction";
import { useNavigate } from "react-router-dom";

function NotAllow() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state) => state.profileStore);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (profile.isAdmin) {
      navigate("/");
    }
  }, [profile]);

  if (loading) {
    return <div className="flex align-center justify-center">loading...</div>;
  }

  return (
    <div
      className="flex column align-center justify-center  "
      style={{ height: "100vh" }}
    >
      <img src="../images/not-allow.png" style={{ width: "200px" }} />
      <b className="normal-size mt-1 mb-2 ">
        “Sorry, You Are Not Allowed to Access This Page”
      </b>
      <button className="btn mt-2" onClick={() => dispatch(logoutUserAtion())}>
        LogOut
      </button>
    </div>
  );
}

export default NotAllow;
