import React, { useEffect } from "react";
import { Button } from "react-materialize";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleAccountAction } from "../../../redux/action/accountsAction";
import Loading from "../../../modules/Loading";
import ClientRecord from "../components/ClientRecord";
import SendMessage from "../components/SendMessage";
import ClientWallet from "../components/ClientWallet";
import ClientRecentNotifications from "../components/ClientRecentNotifications";
// import ClientAccountInfo from "../components/ClientAccountInfo";


function ClientDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { singleAccount, loading } = useSelector(
    (state) => state.getSingleAccountStore
  );
  const { accountID } = useParams();

  useEffect(() => {
    dispatch(getSingleAccountAction(accountID));
  }, [dispatch]);

  console.log(singleAccount);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="flex align-center space-between">
        <span className="flex align-center ">
          <span
            class="material-symbols-outlined hover "
            onClick={() => navigate(-1)}
          >
            keyboard_backspace
          </span>
          <p>&nbsp;Profile View</p>
        </span>
        <div className="flex gap-1 align-center ">
          <button className="btn red" disabled>
            Delete Profile
          </button>
          <Button onClick={() => navigate(`/edit-client-profile/${accountID}`)}>
            Edit Profile
          </Button>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col s4">
            {/* <ClientAccountInfo singleAccount={singleAccount} /> */}
          </div>
          <div className="col s8 flex column gap-1  ">
            <ClientWallet singleAccount={singleAccount} />
            <ClientRecentNotifications singleAccount={singleAccount} />
          </div>
        </div>

        <div className="row">
          <div className="col s8">
            <ClientRecord singleAccount={singleAccount} />
          </div>
          <div className="col s4">
            <SendMessage />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetails;
//
