import React, { useEffect } from "react";
import { Tabs, Tab } from "react-materialize";
import {
  Subscriptions,
  AdditionalCredits,
  CreateNewOffer,
  EditSubscriptions
} from ".";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionPlansAction } from "../../../redux/action/subscriptionAction";

function SubscriptionsPage() {
  const dispatch = useDispatch();
  const { loading, subscriptionList } = useSelector(
    (state) => state.subscriptionPlan
  );
  useEffect(() => {
    dispatch(getSubscriptionPlansAction());
  }, [dispatch]);


  return (
    <div className="white p-1 box " style={{ height: "100%" }}>
      <Tabs>
        <Tab title="Subscriptions">
          <Subscriptions
            subscriptionList={subscriptionList}
            loading={loading}
          />
        </Tab>
        <Tab title="Manage Subscriptions">
          <EditSubscriptions
            subscriptionList={subscriptionList}
            loading={loading}
          />
        </Tab>

        <Tab title="Archive"></Tab>
      </Tabs>
    </div>
  );
}

export default SubscriptionsPage;
// <Tab title="Add New Offer">
//   <CreateNewOffer />
// </Tab>
// <Tab title="Current Offer">
//   <CreateNewOffer />
// </Tab>
