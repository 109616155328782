export const getAllCitiesReducer = (
  state = { allCities: [], loading: false },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_CITIES":
      return { ...state, loading: true };
    case "GOT_ALL_CITIES":
      return { ...state, loading: false, allCities: action.payload };
    case "CANNOT_GET_CITIES":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleCityReducer = (
  state = { loading: false, singleCity: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_SINGLE_CITY":
      return { ...state, loading: true };
    case "GOT_SINGLE_CITY":
      return { ...state, loading: false, singleCity: action.payload };
    case "CANNOT_GET_SINGLE_CITIES":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getCityMarketPriceHistoryReducer = (
  state = { loading: false, marketPriceHistory: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_CITY_MARKET_PRICE":
      return { ...state, loading: true };
    case "GOT_CITY_MARKET_PRICE":
      return { ...state, loading: false, marketPriceHistory: action.payload };
    case "CANNOT_GET_MARKET_PRICE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const updateCityMarketPriceReducer = (
  state = { loading: false, updateStatus: {} },
  action
) => {
  switch (action.type) {
    case "UPDATTING_MARKET_PRICE":
      return { ...state, loading: false };
    case "MARKET_PRICE_UPDATE":
      return { ...state, loading: false, updateStatus: action.payload };
    case "CANNOT_UPDATE_MARKET_PRICE":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const createCityReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "ADDING_CITY":
      return { ...state, loading: true };
    case "CITY_CREATED":
      return { ...state, loading: false };
    case "CANNOT_ADD_CITY":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getArchiveReducer = (
  state = { loading: false, archive: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_ARCHIVE":
      return { ...state, loading: true };
    case "GOT_ARCHIVE":
      return { ...state, loading: false, archive: action.payload };
    case "CANNOT_GET_ARCHIVE":
      return { ...state, loading: false };
    default:
      return state;
  }
};
