import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";
import { decodeToken } from "../../helper/helper";
import { showLoading, hideLoading } from "../loading";
import { Auth } from "../config/configURL";
import { getProfileAction } from "./accountsAction";

export const confirmOTP =
  (phoneNumber, OTP, session, navigate) => async (dispatch) => {
    dispatch(showLoading());

    try {
      Auth.post("/account/login/step-2", {
        phoneNumber: phoneNumber,
        OTP: OTP,
        sessionId: session
      })
        .then((res) => {
          if (res.data?.session) {
            dispatch(hideLoading());
          } else {
            try {
              localStorage.setItem("CCXT", res.data);

              const config = {
                headers: {
                  "x-authentication-token": res.data
                }
              };

              scrapMarketAdmin
                .post("/accounts/authenticate", {}, config)
                .then((res) => {
                  dispatch(hideLoading());
                  localStorage.setItem("scrap_market_admin", res.data);
                  dispatch(getProfileAction());
                  window.location.href = "/";
                });
            } catch (error) {
              dispatch(hideLoading());

              toast.error(
                error.response ? error.response?.data : error.message
              );
            }
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          toast.error(error.response ? error.response?.data : error.message);
        });
    } catch (error) {
      dispatch(hideLoading());

      toast.error(error.response ? error.response?.data : error.message);
    }
  };

export const loginUser = (medium, password) => async (dispatch) => {
  dispatch(showLoading());
  Auth.post("/account/login/step-1", {
    medium: medium,
    password: password
  })
    .then((res) => {
      dispatch(hideLoading());
      try {
        localStorage.setItem("CCXT", res.data);

        const config = {
          headers: {
            "x-authentication-token": res.data
          }
        };

        scrapMarketAdmin
          .post("/accounts/authenticate", {}, config)
          .then((res) => {
            dispatch(hideLoading());
            localStorage.setItem("scrap_market_admin", res.data);
            dispatch(getProfileAction());
            window.location.href = "/";
          });
      } catch (error) {
        dispatch(hideLoading());

        toast.error(error.response ? error.response?.data : error.message);
      }
    })
    .catch((error) => {
      (() => {
        dispatch(hideLoading());
        toast.error(error.response ? error.response?.data : error.message);
      })();
    });
};

export const authenticateScrapMarket = (token) => async (dispatch) => {
  const decoded = await decodeToken(token);
  if (!decoded) return;

  try {
    const config = { headers: { "x-authentication-token": token } };

    const { data } = await scrapMarketAdmin.post(
      "/accounts/authenticate",
      {},
      config
    );
    localStorage.setItem("__scrapMarket", data);
    dispatch(getProfileAction());
    window.location.href = "/dashboard";
  } catch (error) {
    window.location.href = "/";
    toast.error("Authentication Failed");
  }
};

export const requestOtpAction = (phoneNumber, navigate) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const data = await Auth.post(`/account/login/step-1?method=OTP`, {
      medium: phoneNumber
    });
    dispatch(hideLoading());

    navigate(`/confirm-OTP/${data?.data.session} `, {
      state: { session: data?.data?.session, phoneNumber: phoneNumber }
    });
  } catch (error) {
    dispatch(hideLoading());
    toast.error(error.response ? error.response?.data : error.message);
  }
};
