import React, { useState } from "react";
import { Modal, Button } from "react-materialize";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutUserAtion } from "../../redux/action/marketPriceAction";
import { useDispatch } from "react-redux";

function Aside() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showTitle, setShowTile] = useState(false);
  const path = location.pathname;
  return (
    <aside
      className="aside-frame"
      onMouseEnter={() => {
        setShowTile(true);
      }}
      onMouseLeave={() => setShowTile(false)}
    >
      <span className="flex gap-1 align-center ">
        <img
          src="../images/appLogo.png "
          style={{ width: "50px", height: "50px" }}
          alt="app logo"
        />
        {showTitle && <b className="cercle-purple-text bold ">ScrapTrade</b>}
      </span>
      <ul>
        {/*  // <li
        //   className={
        //     path === "/admins-accounts"
        //       ? "aside-item aside-item-active"
        //       : "aside-item "
        //   }
        //   onClick={() => navigate("/admins-accounts")}
        //   style={{ width: showTitle && "14rem" }}
        // >
        //   <span class="material-symbols-outlined">groups_2</span>
        //   {showTitle && <b className="normal-size">Team Settings</b>}
    // </li> */}

        <li
          className={
            path === "/" ? "aside-item aside-item-active" : "aside-item "
          }
          onClick={() => navigate("/")}
          style={{ width: showTitle && "14rem" }}
        >
          <span className="material-symbols-outlined big-icon  ">
            grid_view
          </span>
          {showTitle && <b className="normal-size">Dashboard</b>}
        </li>

        <li
          onClick={() => navigate("/userList")}
          className={
            path === "/userList"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">group</span>
          {showTitle && <b className="normal-size">Accounts</b>}
        </li>

        <li
          className={
            path === "/marketPrice"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          onClick={() => navigate("/marketPrice")}
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">monitoring</span>
          {showTitle && <b className="normal-size">Market Price</b>}
        </li>
        <li
          className={
            path === "/offers" ? "aside-item aside-item-active" : "aside-item "
          }
          onClick={() => navigate("/offers")}
        >
          <span class="material-symbols-outlined big-icon ">approval</span>
          {showTitle && <b className="normal-size">Posts</b>}
        </li>

        <li
          className={
            path === "/subscriptionPage"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          onClick={() => navigate("/subscriptionPage")}
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">subscriptions</span>
          {showTitle && <b className="normal-size">subscriptions</b>}
        </li>

        <li
          className={
            path === "/app-management"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          onClick={() => navigate("/app-management")}
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">widgets</span>
          {showTitle && <b className="normal-size">Management</b>}
        </li>

        <li
          className={
            path === "/analytics"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          onClick={() => navigate("/analytics")}
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">bar_chart</span>
          {showTitle && <b className="normal-size">Analytics</b>}
        </li>

        <li
          onClick={() => navigate("/payments")}
          className={
            path === "/payments"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">payments</span>
          {showTitle && <b className="normal-size">Payments</b>}
        </li>

        <li
          onClick={() => navigate("/recyclers")}
          className={
            path === "/recyclers"
              ? "aside-item aside-item-active"
              : "aside-item "
          }
          style={{ width: showTitle && "14rem" }}
        >
          <span class="material-symbols-outlined big-icon ">recycling</span>
          {showTitle && <b className="normal-size">EPR Credits </b>}
        </li>
      </ul>

      <Modal
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
        id="Modal-10"
        open={false}
        options={{}}
        trigger={
          <li
            className={
              path === "/settngs"
                ? "aside-item aside-item-active"
                : "aside-item "
            }
            node="button"
          >
            <span className="material-symbols-outlined big-icon  ">logout</span>
          </li>
        }
      >
        <b className="normal-size">Confirm Logout </b>
        <p className="semi-bold">Are you sure you want to logout?</p>

        <div className="flex align-center justify-end ">
          <button
            className="green btn-small "
            onClick={() => dispatch(logoutUserAtion())}
          >
            Yes
          </button>
          ,
          <Button
            className="red btn-small"
            flat
            modal="close"
            node="button"
            waves="green"
          >
            No
          </Button>
        </div>
      </Modal>
    </aside>
  );
}

export default Aside;
