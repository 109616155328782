import axios from "axios";
import {
  authenticationService,
  scrap_market_admin,
  recycler
} from "./config";

function getAuthURL() {
  return `${authenticationService.baseURL}`;
}

function getRecyclerURL() {
  return `${recycler.baseURL}`;
}

function getRecycler() {
  let Recycler = axios.create(recycler);
  return Recycler;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT");
  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

function getScrapMarketAdminURL() {
  return `${scrap_market_admin.baseURL}`;
}

function getScrapMarketAdmin() {
  let Auth = axios.create(scrap_market_admin);
  const scrapMarketToken = localStorage.getItem("scrap_market_admin");

  if (scrapMarketToken)
    Auth.defaults.headers.common["X-Authentication-Token"] = scrapMarketToken;
  return Auth;
}

export const Auth = getAuth();
export const AuthURL = getAuthURL();
export const scrapMarketAdmin = getScrapMarketAdmin();
export const scrapMarketAdminURL = getScrapMarketAdminURL();
export const recyclerURL = getRecyclerURL();
export const recyclerAdmin = getRecycler();
