import React from "react";

function ScarpList({ scraps }) {
  if (!scraps) {
    return <div></div>;
  }
  return (
    <div>
      <table className="striped">
        <thead className="small-text">
          <tr>
            <th>Type</th>
            <th>Category</th>
            <th>Name</th>
            <th>Processing Capacity</th>
            <th>Quantity Of Recycled</th>
            <th>Available Credits</th>
            <th>Variant</th>
          </tr>
        </thead>
        <tbody>
          {scraps.map((item, key) => {
            console.log(item);
            return (
              <tr className="small-text">
                <td>{item.type}</td>
                <td>{item.category}</td>
                <td>{item.name}</td>
                <td>{item.processingCapacity}</td>
                <td>{item.quantityOfRecycled}</td>
                <td>{item.availableCredits}</td>
                <td>{item.variant}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ScarpList;
