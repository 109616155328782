import React, { useEffect } from "react";
import PostsAnalytics from "../components/PostsAnalytics";
import RecentSellersCard from "../components/RecentSellersCard";
import { Col, Row } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import LanguageAnalytics from "../components/LanguageAnalytics";
import { getAnalyticsAction } from "../../../redux/action/accountsAction";
import { getSingleCityAction } from "../../../redux/action/marketPriceAction";
import { getRecentPostsAction } from "../../../redux/action/analyticsAction";
import RecentBuyersCard from "../components/RecentBuyersCard";
import NewUsersJoined from "../components/NewUsersJoined";
import SellingPostsAnalyticsChart from "../components/SellingPostsAnalyticsChart";
import BuyingPostsAnalyticsChart from "../components/BuyingPostsAnalyticsChart";
import UpdateMarketPricePlan from "../components/UpdateMarketPricePlan";
import OverallUsers from "../components/OverallUsers";
import UsersAnalyticsChart from "../components/UsersAnalyticsChart";

function Dashboard() {
  const dispatch = useDispatch();
  const { analytics, loading } = useSelector((state) => state.analyticsStore);
  const { recentPosts, loading: recentPostsLoading } = useSelector(
    (state) => state.recentPosts
  );

  useEffect(() => {
    dispatch(getAnalyticsAction());
    dispatch(getSingleCityAction("64106bddc3ecdc8c57b6cd89"));
    dispatch(getRecentPostsAction());
  }, [dispatch]);

  return (
    <>
      <Row className="row mt-1 ">
        <Col className="col s8 column flex  ">
          <PostsAnalytics
            buyingPost={analytics.buyingPosts}
            sellingPost={analytics.sellingPosts}
          />
          <LanguageAnalytics analytics={analytics.languages} />
          {/* <SubscriptionsCard subscriptions={analytics.subscriptions} /> */}
          <Row className="row mt-1 ">
            <Col className="col s6">
              <RecentBuyersCard
                loading={recentPostsLoading}
                recentBuyingPosts={recentPosts?.buyingPosts}
              />
            </Col>
            <Col className="col s6">
              <RecentSellersCard
                loading={recentPostsLoading}
                recentSellingPosts={recentPosts?.sellingPosts}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col s4">
          <NewUsersJoined />
        </Col>
      </Row>

      <Row className="row ">
        <div className="col  s6">
          <SellingPostsAnalyticsChart />
        </div>
        <div className="col s6">
          <BuyingPostsAnalyticsChart />
        </div>
      </Row>

      <div className="flex gap-1 ">
        <UpdateMarketPricePlan />
        <OverallUsers
          allUsers={analytics.numberOfAccounts}
          freeUsers={analytics.freeUsers}
          paidUsers={analytics.paidUsers}
          verifiedUsers={analytics.verifiedUsers}
        />
      </div>
      <UsersAnalyticsChart />
      {/* <AppliedForCreditLine /> */}
    </>
  );
}

export default Dashboard;
