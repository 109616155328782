import React, { useState, useEffect } from "react";
import { getWordStr } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecyclerAction,
  filterReyclerDataAction
} from "../../redux/action/recycler";
import { Pagination, Modal, Button, Textarea } from "react-materialize";
import { getAllRecyclersAction } from "../../redux/action/recycler";
import Loading from "../../modules/Loading";
import { rankData } from "../../helper/helper";

function RecyclerTable({ currentPage, numberOfRow, setCurrentPage, filter }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, recyclerList } = useSelector((state) => state.recyclers);

  const handlePageChange = (e) => {
    setCurrentPage(e);
    if (filter) {
      dispatch(
        filterReyclerDataAction(
          numberOfRow * e,
          numberOfRow,
          filter.state,
          filter.type,
          filter.category,
          filter.organizationName
        )
      );
    } else {
      dispatch(getAllRecyclersAction(numberOfRow * currentPage, numberOfRow));
    }
  };

  useEffect(() => {
    dispatch(getAllRecyclersAction(currentPage, numberOfRow));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <table className="striped">
        <thead className="small-text">
          <tr>
            <th>Name</th>
            <th>email</th>
            <th>phoneNumber</th>
            <th>State</th>
            <th>Address</th>
            <th>Verified</th>
            <th>Class</th>
            <th>Type</th>
            <th>Capacity</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {recyclerList?.map((item, index) => {
            return (
              <tr key={index} className="small-text">
                <td>{item.organizationName}</td>
                <td>{item.organizationEmail}</td>

                <td>{item.contactDetails[0]?.phoneNumber}</td>

                <td>{item.locations[0]?.state}</td>
                <td>
                  {item.locations[0]?.address
                    ? item.locations[0]?.address
                    : item.locations[0]?.nickname}
                </td>
                <td>
                  {item.verified ? (
                    <div className="flex  active-status">
                      <span class="material-symbols-outlined green-text ">
                        verified
                      </span>
                      <p>&nbsp;Verify</p>
                    </div>
                  ) : (
                    <div className="  un-active-status">
                      <p>&nbsp;Not Verify</p>
                    </div>
                  )}
                </td>
                <td>{item.processingClass}</td>
                {item.scraps && <td>{rankData(item.scraps)}</td>}
                {item.scraps[0]?.category ? (
                  <td>{item.scraps[0].category}</td>
                ) : (
                  <td></td>
                )}
                <td className="hover gap-1 flex ">
                  <span
                    class="material-symbols-outlined"
                    onClick={() => navigate(`/edit-recycler/${item._id}`)}
                  >
                    edit
                  </span>
                  <a
                    class="material-symbols-outlined"
                    href={`https://admin.scrap-market.cerclex.com/single-recycler/${item._id}`}
                    target="_blank"
                  >
                    visibility
                  </a>

                  <Modal
                    actions={[]}
                    bottomSheet={false}
                    fixedFooter={false}
                    id="Modal-10"
                    open={false}
                    options={{}}
                    trigger={
                      <span class="material-symbols-outlined red-text">
                        delete
                      </span>
                    }
                  >
                    <b className="normal-size">Confirm Delete</b>
                    <p className="semi-bold">
                      Are you sure you want to delete {item.organizationName} ?
                    </p>

                    <div className="flex align-center justify-end gap-1">
                      <button
                        className="green btn-small "
                        onClick={() => dispatch(deleteRecyclerAction(item._id))}
                      >
                        Yes
                      </button>

                      <Button
                        className="red btn-small"
                        flat
                        modal="close"
                        node="button"
                        waves="green"
                      >
                        No
                      </Button>
                    </div>
                  </Modal>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {recyclerList.length == 0 && (
        <div
          style={{ height: "200px" }}
          className=" grey-text flex full-width card white column align-center p-2"
        >
          <span
            className="material-symbols-outlined  "
            style={{ fontSize: "5rem" }}
          >
            list_alt
          </span>
          <b>No data available</b>
        </div>
      )}
      <div className="flex mt-2 space-between ">
        <Pagination
          activePage={currentPage}
          items={currentPage + 1}
          leftBtn={
            <button
              class="material-symbols-outlined"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage == 1}
            >
              navigate_before
            </button>
          }
          rightBtn={
            <button
              class="material-symbols-outlined"
              onClick={(e) => {
                handlePageChange(currentPage + 1);
              }}
              disabled={numberOfRow > recyclerList?.length}
            >
              navigate_next
            </button>
          }
          className="mt-2"
          onSelect={(e) =>
            handlePageChange(
              e < currentPage
                ? e
                : numberOfRow > recyclerList?.length
                ? currentPage
                : e
            )
          }
        />
      </div>
    </div>
  );
}

export default RecyclerTable;
