import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getAllCitiesAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_CITIES" });
  scrapMarketAdmin
    .get("/cities/read/")
    .then((res) => {
      dispatch({ type: "GOT_ALL_CITIES", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_CITIES" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSingleCityAction = (cityId) => async (dispatch) => {
  dispatch({ type: "GETTING_SINGLE_CITY" });
  scrapMarketAdmin
    .get(`/cities/read/${cityId}`)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_CITY", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_SINGLE_CITIES" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getCityMarketPriceHistoryAction = (cityId) => async (dispatch) => {
  dispatch({ type: "GETTING_CITY_MARKET_PRICE" });
  scrapMarketAdmin
    .get(`/cities/read/market-price-history/${cityId}`)
    .then((res) => {
      dispatch({ type: "GOT_CITY_MARKET_PRICE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_MARKET_PRICE" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const createNewScrapAction = (newScrap) => async (dispatch) => {
  dispatch({ type: "CREATING_NEW_SCRAP" });
  scrapMarketAdmin
    .post("/scraps/create", newScrap)
    .then((res) => {
      toast.success("Scrap Has Been Added Successfully.");
      dispatch({ type: "NEW_SCRAP_CREATED" });
      window.location.reload();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_CREATE_NEW_SCRAP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const updateCityMarketPriceAction =
  (cityId, newPrice) => async (dispatch) => {
    dispatch({ type: "UPDATTING_MARKET_PRICE" });
    scrapMarketAdmin
      .put(`/cities/update/market-prices/${cityId}`, { scraps: newPrice })
      .then((res) => {
        dispatch({ type: "MARKET_PRICE_UPDATE", payload: res.data });
        toast.success(
          `The Price in ${res.data?.name} has been modified successfully.`
        );
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_UPDATE_MARKET_PRICE" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const createCityAction = (cityName) => async (dispatch) => {
  dispatch({ type: "ADDING_CITY" });
  scrapMarketAdmin
    .post("/cities/create", {
      name: cityName
    })
    .then((res) => {
      dispatch({ type: "CITY_CREATED", payload: res.data });
      toast.success("City has been added successfully");
      dispatch(getAllCitiesAction());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_CITY" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getBuyingPosts = () => async (dispatch) => {
  scrapMarketAdmin
    .get("/posts/read/selling-posts")
    .then((res) => {})
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_CITY" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSellingPosts = () => async (dispatch) => {
  scrapMarketAdmin
    .get("/posts/read/selling-posts")
    .then((res) => {})
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_CITY" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const addToArchiveAction = (scrap_id) => async (dispatch) => {
  dispatch({ type: "ADDING_TO_ARCHIVE" });
  scrapMarketAdmin
    .put(`/scraps/archive/add/${scrap_id}`)
    .then((res) => {
      toast.success(res.data);
      dispatch(getAllCitiesAction());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_TO_ARCHIVE" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const logoutUserAtion = () => {
  localStorage.removeItem("scrap_market_admin");
  localStorage.removeItem("isAdmin");
  window.location.href = "/";
};

export const getArchiveAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_ARCHIVE" });
  scrapMarketAdmin
    .get("/scraps/archive")
    .then((res) => {
      dispatch({ type: "GOT_ARCHIVE", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ARCHIVE" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const unArchiveScrapAction = (scrapID) => async (dispatch) => {
  dispatch({ type: "UN_ARCHIVE_SCRAP" });
  scrapMarketAdmin
    .put(`/scraps/archive/remove/${scrapID}`)
    .then((res) => {
      dispatch({ type: "UN_ARCHIVED_SCRAP", payload: res.data });
      toast.success(res.data);
      dispatch(getAllCitiesAction());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_UN_ARCHIVE_SCRAP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const editScrap = (data) => async (dispatch) => {
  scrapMarketAdmin
    .put("/scraps/update/6433e0dcdca9ce74b893734f", data)
    .then((res) => {
      toast.success("done");
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};
