import React from "react";
import ReactLoading from "react-loading";

function Loading() {
  return (
    <div
      className="flex column align-center justify-center "
      style={{ height: "80vh" }}
    >
      <ReactLoading type={"spinningBubbles"} color="#6f2da8" />
      <b className="purple-text mt-1 ">Loading...</b>
    </div>
  );
}

export default Loading;
