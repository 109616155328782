import React, { useEffect, useState } from "react";
import { Pagination, Modal, Button, Textarea } from "react-materialize";
import { Link } from "react-router-dom";
import { Checkbox} from "react-materialize";
import { getCurrentDateTime } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyAccountAction,
  unsuspendActionAccount,
  suspendAccountAction,
  addNewNotAction,
  deleteNoteAction,
  addLeadOwnerAction
} from "../../redux/action/accountsAction";
import Input from "../../modules/InputFeild";
import Loading from "../../modules/Loading";
import { useNavigate } from "react-router-dom";

function AllUsers({
  handlePageChange,
  currentPage,
  numberOfRow,
  setCurrentPage
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addNotLoading } = useSelector((state) => state.addNewNoteStore);
  const [newNote, setNewNote] = useState();
  const [accountSelected, setAccountSelected] = useState({
    accountID: "",
    accountNotes: []
  });
  const [accountGST, setAccountGST] = useState("");
  const [verificationDoc, setVerificationDoc] = useState("");
  const { loading, accounts } = useSelector(
    (state) => state.getAllAccountsStore
  );
  const selectLeadOwner = (accountID, leadOwner) => {
    dispatch(addLeadOwnerAction(accountID, leadOwner));
  };
  const deleteNote = (noteIndex) => {
    dispatch(deleteNoteAction(accountSelected.accountID, noteIndex));
  };
  const [error, setError] = useState("");

  const handleBlockAccount = (accountId, suspended) => {
    if (suspended) {
      dispatch(unsuspendActionAccount(accountId));
    } else {
      dispatch(suspendAccountAction(accountId));
    }
  };
  console.log(accounts)

  const addNewNote = (e) => {
    e.preventDefault();
    if (!accountSelected.accountID || !newNote) {
    } else {
      dispatch(addNewNotAction(accountSelected.accountID, newNote));
    }
  };
  const handleSubmit = (accountId) => {
    if (!accountGST) {
      return setError("Please enter GST number.");
    }
    const formData = new FormData();
    formData.append("gst", accountGST);
    if (verificationDoc) {
      formData.append("verificationDoc", verificationDoc);
    }

    dispatch(dispatch(verifyAccountAction(accountId, formData)));
  };

  useEffect(() => {
    if (accounts.length == 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [accounts]);

  useEffect(() => {
    renderTableBody();
  }, [dispatch]);

  const renderTableBody = () => {
    // const startIndex = (currentPage - 1) * rowsPerPage;
    // const endIndex = startIndex + rowsPerPage;
    // const rowsData = accounts?.slice(startIndex, endIndex);

    return accounts.map((account, index) => {
      return (
        <tr className="small-text" key={index}>
          <td>{numberOfRow * currentPage - numberOfRow + index + 1}</td>
          <td className="capitalize">{account?.name}</td>
          <td>{account.email}</td>
          <td>{account?.phoneNumber}</td>
          <td>
            {account.location?.district
              ? account.location.district
              : account.location?.city
              ? account.location.city
              : account.location?.state
              ? account.location.state
              : account.location?.country
              ? account.location.country
              : "Unknown"}
          </td>
          <td>{account.subscription?.name}</td>
          <td>
            {account.subscription?.startedOn &&
              getCurrentDateTime(account.subscription?.startedOn)}
          </td>

          <td>{account.gst}</td>

          <td>
            {account.verified ? (
              <div className="flex  active-status">
                <span class="material-symbols-outlined green-text ">
                  verified
                </span>
                <p>&nbsp;Verify</p>
              </div>
            ) : (
              <div className="  un-active-status">
                <p>&nbsp;Not Verify</p>
              </div>
            )}
          </td>

          <td>
            {account.verificationDoc ? (
              <div className="flex  active-status">
                <span class="material-symbols-outlined green-text ">
                  verified
                </span>
                <p>&nbsp;Verify</p>
              </div>
            ) : (
              <div className="  un-active-status">
                <p>&nbsp;Not Verify</p>
              </div>
            )}
          </td>

          <td>
            <select
              className="browser-default drop-down "
              onChange={(e) => selectLeadOwner(account._id, e.target.value)}
              value={account.leadOwner}
              key={index}
            >
              <option>
                {account.leadOwner ? account.leadOwner : "--select--"}
              </option>
              <option value={"Melvin"}> Melvin</option>
              <option value={"Sathiya Priya"}>Sathiya Priya</option>
              <option value={"Hardika"}>Hardika</option>
              <option value={"Yash"}>Yash</option>
            </select>
          </td>

          <td>
            <Modal
              id={index}
              open={false}
              trigger={
                <div className=" hover flex align-center justify-center  ">
                  <span className="material-symbols-outlined hover">
                    mode_comment
                  </span>
                  <p style={{ position: "absolute", marginBottom: "3px" }}>
                    {account.notes?.length}
                  </p>
                </div>
              }
              options={{
                onOpenStart: () =>
                  setAccountSelected({
                    ...accountSelected,
                    accountID: account._id,
                    accountNotes: account.notes
                  })
              }}
            >
              <b>User Notes Management</b>
              {accountSelected.accountNotes?.length == 0 && (
                <div className="flex column justify-center align-center ">
                  {" "}
                  <img
                    src="../images/no-note.png"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <p className="small-text grey-text ">
                    There no note for now.
                  </p>
                </div>
              )}
              
              <ul className="mt-2">
                {accountSelected.accountNotes?.map((note, indexNot) => {
                  return (
                    <li className="flex align-center space-between ">
                      <p className="capitalize">
                        {indexNot + 1}.&nbsp;{note}
                      </p>
                      <span
                        class="material-symbols-outlined hover  red-text normal-size "
                        onClick={() => deleteNote(indexNot)}
                      >
                        delete
                      </span>
                    </li>
                  );
                })}
              </ul>
              <hr />
              <form onSubmit={addNewNote}>
                <b className="mt-1">Add New Note:</b>
                <Textarea
                  className="browser-default input-field "
                  placeholder="Add New Note."
                  onChange={(e) => setNewNote(e.target.value)}
                />
                <div className="flex align-center gap-1 justify-end align-end ">
                  {" "}
                  <Button className="btn-outline" modal="close" node="button">
                    Close
                  </Button>{" "}
                  <button
                    className="primary-btn"
                    style={{ width: "100px" }}
                    type="submit"
                  >
                    {addNotLoading ? "loading" : "Submit"}
                  </button>{" "}
                </div>
              </form>
            </Modal>
          </td>

          <td className="gap-1 flex row justify-center">
            <Modal
              actions={[]}
              bottomSheet={false}
              fixedFooter={false}
              options={{ onCloseStart: () => setError("") }}
              id="Modal-11"
              open={false}
              trigger={
                account.suspended ? (
                  <span class="material-symbols-outlined hover red-text">
                    block
                  </span>
                ) : (
                  <span class="material-symbols-outlined hover green-text ">
                    block
                  </span>
                )
              }
            >
              <b className="normal-size  ">Block User Account</b>
              {!accountGST && (
                <p className="mt-1">
                  Are you sure you want to block this user's account?
                </p>
              )}

              <div className="flex justify-end gap-1 align-center mt-1 ">
                <button
                  className="btn-submit"
                  type="submit"
                  onClick={() =>
                    handleBlockAccount(account._id, account.suspended)
                  }
                  id={index}
                >
                  YES
                </button>
                <Button className="btn-outline" modal="close">
                  NO
                </Button>
              </div>
            </Modal>

            <Link to={`/client-profile/${account._id}`}>
              <span class="material-symbols-outlined mh-1 cercle-purple-text ">
                visibility
              </span>
            </Link>
            <span
              class="material-symbols-outlined hover blue-text"
              onClick={() => navigate(`/edit-client-profile/${account._id}`)}
            >
              edit
            </span>
            <Modal
              actions={[]}
              bottomSheet={false}
              fixedFooter={false}
              options={{ onCloseStart: () => setError("") }}
              id="Modal-10"
              open={false}
              trigger={
                <Checkbox
                  node="button"
                  id={index}
                  label="Verify"
                  checked={account.verified}
                  onChange={
                    account.gst
                      ? () => setAccountGST(account.gst)
                      : () => setAccountGST("")
                  }
                />
              }
            >
              <b className="normal-size  ">
                User Validation and GST/Document Submission
              </b>
              {!accountGST && (
                <p className="mt-1">
                  This user does not currently have a GST number on file. Please
                  enter the user's GST number below to verify their account.
                </p>
              )}

              <Input
                state={{
                  value: accountGST,
                  placeHolder: "Enter the user's GST number",
                  icon: "receipt_long",
                  iconColor: "#0E9D1C"
                }}
                onChange={(e) => {
                  setAccountGST(e.target.value);
                }}
              />
              <div className="  un-active-status inputWrapper ">
                <span class="material-symbols-outlined">cloud_upload</span>
                <p>&nbsp;SPCB/CPCB</p>
                <input
                  type={"file"}
                  className="fileInput"
                  onChange={(e) => setVerificationDoc(e.target.files[0])}
                />
              </div>
              {error && <p className="small-text red-text mt-1">{error}</p>}

              <div className="flex justify-end gap-1 align-center mt-1 ">
                <button
                  className="btn-submit"
                  type="submit"
                  onClick={() => handleSubmit(account._id)}
                  id={index}
                >
                  SUBMIT
                </button>
                <Button className="btn-outline" modal="close">
                  CLOSE
                </Button>
              </div>
            </Modal>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex column align-center ">
      {loading ? (
        <Loading />
      ) : (
        <table className="striped">
          <thead>
            <tr>
              <th className="small-text">#</th>
              <th className="small-text">Name</th>
              <th className="small-text">Email ID</th>
              <th className="small-text">Phone Number</th>
              <th className="small-text">Location</th>
              <th className="small-text">Subscription</th>
              <th className="small-text">Subscription Date</th>

              <th className="small-text">GST</th>
              <th className="small-text">State</th>
              <th className="small-text">SPCB/CPCB</th>
              <th className="small-text">Lead Owner</th>

              <th className="small-text">
                <span class="material-symbols-outlined">comment</span>
              </th>
              <th className="flex justify-center small-text">Action</th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      )}
      <div className="flex mt-2 space-between ">
        <Pagination
          activePage={currentPage}
          items={currentPage + 1}
          leftBtn={
            <button
              class="material-symbols-outlined"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={(currentPage = 1)}
            >
              navigate_before
            </button>
          }
          rightBtn={
            <button
              class="material-symbols-outlined"
              onClick={(e) => {
                handlePageChange(currentPage + 1);
                handlePageChange(e);
              }}
              disabled={numberOfRow > accounts?.length}
            >
              navigate_next
            </button>
          }
          className="mt-2"
          onSelect={(e) =>
            handlePageChange(
              e < currentPage
                ? e
                : numberOfRow > accounts?.length
                ? currentPage
                : e
            )
          }
        />
      </div>
    </div>
  );
}

export default AllUsers;
