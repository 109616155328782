import React from "react";
import { scrapMarketAdminURL } from "../redux/config/configURL";

function BidImage({ bidID }) {
  return (
    <div>
      <img
        src={`${scrapMarketAdminURL}/bids/read/image/${bidID}`}
        width={"100%"}
        height={"40px"}
        alt="post-image"
        style={{ objectFit: "contain ", borderRadius: "5px" }}
      />
    </div>
  );
}

export default BidImage;
