import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination, Checkbox, Modal, Button } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  updateSoldOferAction
} from "../../../redux/action/offersAction";
import OfferNotes from "./OfferNotes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBuyingPostsAction,
  filterBuyingPostsAction,
  verifiedOferAction,
} from "../../../redux/action/offersAction";
import Loading from "../../../modules/Loading";
import { getCurrentDateTime } from "../../../helper/helper";

function BuyerPosts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const numberOfRow = 100;
  const [scrapsSelected, setScrapsSelected] = useState([]);
  const { buyingPosts, loading } = useSelector(
    (state) => state.buyingPostsStore
  );

  const { scrapsList } = useSelector((state) => state.scrapsListStore);
  const allScraps = [];

  const getAllScraps = (scrapsList) => {
    scrapsList.forEach((scrap) => {
      const singleScrap = {
        value: scrap._id,
        label: `${scrap.name} - ${scrap.variant}`
      };
      allScraps.push(singleScrap);
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [skipPagination, setSkipPagination] = useState(0);

  const renderTableBody = () => {
    // const startIndex = (currentPage - 1) * rowsPerPage;
    // const endIndex = startIndex + rowsPerPage;
    // const rowsData = buyingPosts?.slice(startIndex, endIndex);

    return buyingPosts.map((item, index) => {
      return (
        <tr className="small-text" key={index}>
          <td>{index + 1}.</td>
          <td className="capitalize">
            {item.scrap.name}-{item.scrap.variant}
          </td>
          <td>{item?.account?.name}</td>
          <td>{item?.account?.phoneNumber}</td>
          <td>{getCurrentDateTime(item.createdAt)}</td>
          <td>{item.scrap.type}</td>
          <td>{item.bids.length}</td>
          <td>{item.quantity}</td>
          <td>{item.price}</td>
          <td>{item.verified ? "Verified" : "Not Verified"}</td>

          <td>
            {item.location?.district
              ? item.location.district
              : item.location?.city
              ? item.location.city
              : item.location?.state
              ? item.location.state
              : item.location?.country
              ? item.location.country
              : "Unknown"}
          </td>
          <td className="gap-1 flex row justify-center">
            <Modal
              actions={[]}
              bottomSheet={false}
              fixedFooter={false}
              id={`add_not_${item._id}`}
              open={false}
              options={{}}
              trigger={
                <span
                  class="material-symbols-outlined hover green-text"
                  node="button"
                >
                  description
                </span>
              }
            >
              <OfferNotes notes={item.notes} postId={item._id} />
            </Modal>

            <Link to={`/post-view/${item._id}`} className="primary-text">
              <span class="material-symbols-outlined mh-1 ">visibility</span>
            </Link>
            <span
              class="material-symbols-outlined blue-text hover "
              onClick={() => navigate(`/edit-post/${item._id}`)}
            >
              edit
            </span>
            <Checkbox
              id={item._id + 1}
              label="verify"
              checked={item.verified ? true : false}
              onChange={() => dispatch(verifiedOferAction(item._id))}
            />
            <Checkbox
              id={item._id}
              label="Sold"
              checked={item.status === "sold" ? true : false}
              onChange={() =>
                dispatch(
                  updateSoldOferAction(
                    item._id,
                    item.status == "open" ? "true" : "fasle"
                  )
                )
              }
            />
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    if (buyingPosts.length == 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [buyingPosts]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== 0) {
      setCurrentPage(pageNumber);
      setSkipPagination((pageNumber - 1) * numberOfRow);
    }
  };

  const handleSearch = () => {
    if (scrapsSelected.length == 0) {
      toast.error("Please Select Scrap Type.");
    } else {
      const scrapsIDs = [];
      scrapsSelected.forEach((item) => {
        scrapsIDs.push(item.value);
      });
      dispatch(filterBuyingPostsAction(scrapsIDs));
    }
  };

  useEffect(() => {
    renderTableBody();
  }, [dispatch, renderTableBody, setCurrentPage]);

  useEffect(() => {
    dispatch(getBuyingPostsAction(skipPagination, numberOfRow));
  }, [dispatch, skipPagination]);
  useEffect(() => {
    getAllScraps(scrapsList);
  }, [getAllScraps, scrapsList]);

  return (
    <div style={{ marginTop: "-70px" }} className="flex column align-center ">
      <div className="flex justify-end mb-1 full-width">
        <div className="flex gap-1  align-center ">
          <Select
            isMulti
            onChange={(e) => {
              setScrapsSelected(e);
            }}
            options={allScraps}
            onFocus={{ borderColor: "red" }}
            // style={{ width: "40vw" }}
            classNamePrefix="react-select browser-default "
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: "50px",
                borderColor: "#efefef",
                width: "35vw"
              })
            }}
          />

          <button
            className="btn-outline"
            onClick={handleSearch}
            style={{ height: "44px", zIndex: 1 }}
          >
            Search
          </button>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <table className="striped">
          <thead>
            <tr>
              <th className="small-text">#</th>
              <th className="small-text">Scrap</th>
              <th className="small-text">Name</th>
              <th className="small-text">Phone</th>
              <th className="small-text">Date</th>
              <th className="small-text">Type</th>
              <th className="small-text">Bids</th>
              <th className="small-text">Quantity</th>
              <th className="small-text">Price</th>
              <th className="small-text">Status</th>

              <th className="small-text">Location</th>
              <th className="small-text">Action</th>
            </tr>
          </thead>

          <tbody> {renderTableBody()} </tbody>
        </table>
      )}
      <Pagination
        activePage={currentPage}
        items={currentPage + 1}
        leftBtn={
          <button
            class="material-symbols-outlined"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            navigate_before
          </button>
        }
        rightBtn={
          <button
            class="material-symbols-outlined"
            onClick={(e) => {
              handlePageChange(currentPage + 1);
              handlePageChange(e);
            }}
            disabled={numberOfRow > buyingPosts?.length}
          >
            navigate_next
          </button>
        }
        className="mt-2"
        onSelect={(e) =>
          handlePageChange(
            e < currentPage
              ? e
              : numberOfRow > buyingPosts?.length
              ? currentPage
              : e
          )
        }
      />
    </div>
  );
}

export default BuyerPosts;
