export const getAllPaymentReducer = (
  state = { loading: false, paymentList: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_PAYMENTS":
      return { ...state, loading: true };
    case "GOT_PAYMENTS":
      return { ...state, loading: false, paymentList: action.payload };
    case "CANNOT_GET_PAYMENTS":
      return { ...state, loading: false };
    default:
      return state;
  }
};
