import React from "react";
import { scrapMarketAdminURL } from "../redux/config/configURL";

function PostSingleImage({ postID }) {
  return (
    <div>
      <img
        src={`${scrapMarketAdminURL}/posts/read/image/${postID}`}
        width={"100%"}
        height={"40px"}
        alt="post-image"
        style={{ objectFit: "contain ", borderRadius: "5px" }}
      />
    </div>
  );
}

export default PostSingleImage;
