import { scrapMarketAdmin } from "../config/configURL";
import { toast } from "react-toastify";

export const getBestSellerAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_BEST_SELLER" });
  scrapMarketAdmin
    .get("/analytics/read/best-accounts")
    .then((res) => {
      dispatch({ type: "GOT_BEST_SELLER", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_BEST_SELLER" });
      // toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getRecentPostsAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_RECENT_POSTS" });
  scrapMarketAdmin
    .get("/posts/read/recent-posts")
    .then((res) => {
      console.log(res.data);

      dispatch({ type: "GOT_RECENT_POSTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_RECENT_POSTS" });
      // toast.error(error.response? error.response?.data : error.message);
    });
};

export const getRecentJoinedAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_RECENT_JOINED" });
  scrapMarketAdmin
    .get("/analytics/read/recent-joined")
    .then((res) => {
      dispatch({ type: "GOT_RECENT_JOINED", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_RECENT_JOINED" });
      // toast.error(error.response? error.response?.data : error.message);
    });
};

export const getSellingPostAnalyticsAction =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_SELLING_POTS_ANALYTICS" });
    scrapMarketAdmin
      .get(
        `/analytics/read/buying-posts-by-date?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({ type: "GOT_SELLING_POSTS_ANALYTICS", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_SELLING_POSTS_ANALYTICS" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getBuyingPostAnalyticsAction =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_BUYING_POSTS_ANALYTICS" });
    scrapMarketAdmin
      .get(
        `/analytics/read/selling-posts-by-date?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({ type: "GOT_BIUING_POSTS_ANALYTICS", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_BUYING_POSTS_ANALYTICS" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getJoinedUsersAnalyticsAction =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GETTING_JOINED_USERS_ANALYTICS" });
    scrapMarketAdmin
      .get(
        `/analytics/read/users-joined-by-date?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({ type: "GOT_JOINED_USERS_ANALYTICS", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_JOINED_USERS_ANALYTICS" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getVerifiedThisMonthAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_VERIFIED" });
  scrapMarketAdmin
    .get("/analytics/read/users-verified-this-month")
    .then((res) => {
      dispatch({ type: "GOT_VERIFIED", payload: res.data });
    })
    .then((error) => {
      dispatch({ type: "CANNOT_VERIFIED" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getAppliedForCreditLineAction = () => async (dispatch) => {
  dispatch({ type: "GETTING_APPLIED_FOR_CREDIT_LINE" });
  scrapMarketAdmin
    .get("/analytics/read/applied-for-credit-line")
    .then((res) => {
      dispatch({ type: "GOT_APPLIED_FOR_CREDIT_LINE", payload: res.data });
    })
    .then((error) => {
      dispatch({ type: "CANNOT_GET_APPLIED_FOR_CREDIT_LINE" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};
