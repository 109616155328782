import React, { useState } from "react";
import { CardPanel, Col, Modal , Button } from "react-materialize";
import Input from "./InputFeild";
import { getPriceOffer } from "../helper/helper";
import { useDispatch } from "react-redux";
  import {deleteSubscriptionPlanAction} from '../redux/action/subscriptionAction'

function EditSubscriptionCard({
  state,
  index,
  subscriptionPlanStore,
  updateSubscriptionPlans,
  setUpdateSubscriptionPlans,
  setSubscriptionPlanStore,
}) {
  const handleChangeInputValue = (index, update, valueName) => {
    const newValue = [...subscriptionPlanStore];
    newValue[index] = { ...newValue[index], [valueName]: update };
    setSubscriptionPlanStore(newValue);
  };
  const dispatch = useDispatch()
  const handleInputChange = (planId, inputName, inputValue, index) => {
    const planIsPresent = updateSubscriptionPlans.some(
      (plan) => plan._id === planId
    );
    if (planIsPresent) {
      handleChangeInputValue(index, inputValue, inputName);

      const updatePlans = updateSubscriptionPlans.map((plan) => {
        if (plan._id === planId) {
          return { ...plan, [inputName]: inputValue };
        } else {
          return plan;
        }
      });

      setUpdateSubscriptionPlans(updatePlans);
    } else {
      const newPlan = { _id: planId, [inputName]: inputValue };
      handleChangeInputValue(index, inputValue, inputName);
      setUpdateSubscriptionPlans((editedPlansArray) => [
        ...editedPlansArray,
        newPlan,
      ]);
    }
  };

  return (
    <Col
      className="mt-1 hover box mh-1 "
      key={index}
      style={{ position: "relative" }}
    >
      <Modal
        actions={[]}
        bottomSheet={false}
        fixedFooter={false}
        id="Modal-10"
        open={false}
        options={{}}
        trigger={
          <div style={{ position: "absolute", right: "10px ", top: "10px" }}>
            <span class="material-symbols-outlined red-text ">delete</span>
          </div>
        }
      >
        <b className="normal-size">Confirm Delete </b>
        <p className="semi-bold">Are you sure you want to Delete this Plan?</p>

        <div className="flex align-center justify-end ">
          <button
            className="green btn-small "
            onClick={() => dispatch(deleteSubscriptionPlanAction(state.id))}
          >
            Yes
          </button>
          ,
          <Button
            className="red btn-small"
            flat
            modal="close"
            node="button"
            waves="green"
          >
            No
          </Button>
        </div>
      </Modal>

      <CardPanel className=" flex column align-center">
        <div className="flex column alugn-center">
          <span
            class="material-symbols-outlined p-1 circle  lighten-4 white-text mb-1 mt-1 "
            style={{ backgroundColor: state.color }}
          >
            subscriptions
          </span>

          <p className="red-text">-{state.discountPercentage}%</p>
        </div>

        <div>
          <Input
            state={{
              value: state.planName,

              placeHolder: "Plan Name",
              icon: "subscriptions",
              name: "name",
            }}
            onChange={(e) =>
              handleInputChange(state.id, e.target.name, e.target.value, index)
            }
          />
          <Input
            state={{
              value: state.duration,
              placeHolder: "Subscription Duration",
              icon: "calendar_month",
              name: "numberOfMonths",

              type: "number",
            }}
            onChange={(e) =>
              handleInputChange(state.id, e.target.name, e.target.value, index)
            }
          />
          <Input
            state={{
              value: state.price,
              placeHolder: "Price (Regular)",
              icon: "payments",
              name: "price",
            }}
            onChange={(e) =>
              handleInputChange(state.id, e.target.name, e.target.value, index)
            }
          />
          <Input
            state={{
              value: getPriceOffer(state.discountPercentage, state.price),
              placeHolder: "Price (Offer)",
              icon: "-percent",
              name: "offerPrice",
              disabled: true,
            }}
            onChange={(e) =>
              handleInputChange(state.id, e.target.name, e.target.value, index)
            }
          />
        </div>
      </CardPanel>
    </Col>
  );
}

const styles = {
  noOfUsersBG: {
    padding: "7px 25px",
    borderRadius: "25px",
  },
};
export default EditSubscriptionCard;
