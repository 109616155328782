import React, { useEffect } from "react";
import { CardPanel, Col, Row } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { getSingleBidAction } from "../../redux/action/offersAction";
import { getCurrentDateTime } from "../../helper/helper";
import PostImage from "../../modules/PostImage";
import ImagesSliderBids from "../../modules/ImagesSliderBids";
import ImagesSlider from "../../modules/ImagesSlider";

function SingleBid({ singleBid }) {
  const dispatch = useDispatch();

  const { loading, bidDetails } = useSelector((state) => state.singleBid);


  useEffect(() => {
    dispatch(getSingleBidAction(singleBid._id));
  }, [dispatch, singleBid]);

  if (loading) {
    return <p>loading</p>;
  }

  return (
    <Col className="box-outLine flex " style={{ width: "27vw" }}>
    <div style={{ width:"180px", height:"180px" }}>  
    <ImagesSliderBids images={singleBid.images} bidID={singleBid._id} />
    </div>
      <CardPanel>
        <div className="full-width ml-1 ">
          <div className="flex align-center space-between  full-width">
            <span className="flex align-end ">
              <b className="green-text normal-size ">₹{bidDetails.price}</b>
              &nbsp;/kg
            </span>
            <b>{bidDetails.quantity} tonnes</b>
          </div>
          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              location_on
            </span>
            &nbsp;
            <p className="small-text">
              {bidDetails.location?.state}-{bidDetails.location?.city}
            </p>
          </span>

          <div className="flex align-center space-between mv-1 ">
            <span className="flex align-center ">
              <span class="material-symbols-outlined purple-text ">
                local_shipping
              </span>
              <p>Transport Enabled:</p>
            </span>
            <p>{bidDetails.transportEnabled ? "Yes" : "No"}</p>
          </div>

          <div className="flex align-center space-between mv-1 ">
            <span className="flex align-center ">
              <span class="material-symbols-outlined purple-text ">
                apartment
              </span>
              <p>site Visit Enabled:</p>
            </span>
            <p>{bidDetails.siteVisitEnabled ? "Yes" : "No"}</p>
          </div>

          <span className="flex align-center ">
            <span class="material-symbols-outlined purple-text ">
              calendar_month
            </span>
            &nbsp;
            <p className="small-text">
              {getCurrentDateTime(bidDetails.createdAt)}
            </p>
          </span>
        </div>
      </CardPanel>
    </Col>
  );
}

export default SingleBid;

        // <PostImage postID={bidDetails.images[1]} />


// <div className="p-1 flex  ">
// <img
//   src="../images/scrap1.png"
//   style={{ width: "100px", height: "100px" }}
// />

// <div className="flex column ">
//   <div className="flex space-between ">
//     <span className="flex align-end ">
//       <b className="green-text normal-size ">₹794.00</b>&nbsp;/kg
//     </span>
//     <b>20 tonnes</b>
//   </div>

//   <span className="flex align-center ">
//     <span class="material-symbols-outlined purple-text ">
//       location_on
//     </span>
//     &nbsp;
//     <p className="small-text">Tamil Nadu</p>
//   </span>

//   <span className="flex align-center ">
//     <span class="material-symbols-outlined purple-text ">
//       local_shipping
//     </span>
//     &nbsp;
//     <p className="small-text">Transport Available</p>
//   </span>

//   <span className="flex align-center ">
//     <span class="material-symbols-outlined purple-text ">domain</span>
//     &nbsp;
//     <p className="small-text">No site visited</p>
//   </span>

//   <span className="flex align-center ">
//     <span class="material-symbols-outlined purple-text ">person</span>
//     &nbsp;
//     <p className="small-text">Ramanadhan Kishore</p>
//   </span>

//   <span className="flex align-center ">
//     <span class="material-symbols-outlined purple-text ">
//       calendar_month
//     </span>
//     &nbsp;
//     <p className="small-text">November 11, 10.10 am</p>
//   </span>
// </div>
// </div>

// <Col m={10} className="box-outLine">
// <CardPanel>
//   <img
//     src="../images/scrap.png"
//     style={{ width: "136px", height: "150px" }}
//   />
//   <div className="flex column ml-1 ">
//     <div className="flex  align-center ">
//       <span className="flex align-end ">
//         <b className="green-text normal-size ">₹794.00</b>&nbsp;/kg
//       </span>
//       <b>20 tonnes</b>
//     </div>

//     <span className="flex align-center ">
//       <span class="material-symbols-outlined purple-text ">
//         location_on
//       </span>
//       &nbsp;
//       <p className="small-text">Tamil Nadu</p>
//     </span>

//     <span className="flex align-center ">
//       <span class="material-symbols-outlined purple-text ">
//         local_shipping
//       </span>
//       &nbsp;
//       <p className="small-text">Transport Available</p>
//     </span>

//     <span className="flex align-center ">
//       <span class="material-symbols-outlined purple-text ">domain</span>
//       &nbsp;
//       <p className="small-text">No site visited</p>
//     </span>

//     <span className="flex align-center ">
//       <span class="material-symbols-outlined purple-text ">person</span>
//       &nbsp;
//       <p className="small-text">Ramanadhan Kishore</p>
//     </span>

//     <span className="flex align-center ">
//       <span class="material-symbols-outlined purple-text ">
//         calendar_month
//       </span>
//       &nbsp;
//       <p className="small-text">November 11, 10.10 am</p>
//     </span>
//   </div>
// </CardPanel>
// </Col>
