export const getRecyclersReducer = (
  state = {
    loading: false,
    recyclerList: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_RECYCLERS":
    case "FILTTERING_DATA":
      return { ...state, loading: true };
    case "GOT_RECYCLERS":
    case "DATA_FILTERED":
      return { ...state, loading: false, recyclerList: action.payload };
    case "CANNOT_GET_RECYCLERS":
      return { ...state, loading: false };
    case "FILTTERING_DATA":
    case "CANNOT_FILTER_DATA":
    default:
      return state;
  }
};

export const getSingleRecyclerreducer = (
  state = { loading: false, singleRecycler: {} },
  action
) => {
  switch (action.type) {
    case "GETING_SINGLE_RECYCLER":
      return { ...state, loading: true };
    case "GOT_SINGLE_RECYCLER":
      return { ...state, loading: false, singleRecycler: action.payload };
    case "SINGLE_RECYCLER_REFRESHED":
      return {
        ...state,
        loading: false,
        singleRecycler: {
          ...state.singleRecycler,
          documents: action.documents,
          locations: action.locations,
          notes: action.notes,
        },
      };
    case "CONNOT_GET_SINGLE_RECYCLER":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getRecyclerAnalyticsReducer = (
  state = {
    loading: false,
    analytics: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ANALYTICS":
      return { ...state, loading: true };
    case "GOT_ANALYTICS_DATA":
      return { ...state, loading: false, analytics: action.payload };
    case "CANNOT_GET_ANALYTICS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getCustomersReducer = (
  state = {
    loading: false,
    customers: [],
  },
  action
) => {
  switch (action.type) {
    case "GETTING_CUSTOMERS":
      return { ...state, loading: true };
    case "GOT_CUSTOMERS":
      return { ...state, loading: false, customers: action.payload };
    case "CANNOT_GET_CUSTOMER":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getVerifiedRecyclersReducer = (
  state = { loading: false, recyclerList: [] },
  action
) => {
  switch (action.type) {
    case "GET_VERIFIED_USERS":
      return { ...state, loading: true };
    case "GOT_VERIFIED_USERS":
      return { ...state, loading: false, recyclerList: action.payload };
    case "CANNOT_GET_VERIFIED_USERS":
      return { ...state, loading: false };
    default:
      return state;
  }
};
