import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBestSellerAction } from "../../redux/action/analyticsAction";
import { useNavigate } from "react-router-dom";

function BestSellersAnalytics() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { loading, bestSellers } = useSelector(
    (state) => state.bestSellersList
  );

  useEffect(() => {
    dispatch(getBestSellerAction());
  }, [dispatch]);

  return (
    <div className="box p-1" >
      <div className="flex space-between align-center ">
        <b>Best Sellers</b>
        <p className=" purple-text small-text hover  lighten-6 purple  btn-outline"           role={"button"}
          onClick={() => navigate("userList")} >View All</p>
      </div>
      <table className="mt-1">
        <thead>
          <th className="small-text">Name</th>
          <th className="small-text">Phone Nomber</th>
          <th className="small-text">Number Of Posts</th>
          <th className="small-text">Subscription</th>
          <th className="small-text">Action</th>
        </thead>
        <tbody>
          {bestSellers.map((user, index) => {
            if (index <= 5)
              return (
                <tr className="small-text" key={index}>
                  <td>{user.userIfno?.name}</td>
                  <td>{user.userIfno.phoneNumber ? user.userIfno?.phoneNumber : user.userIfno?.email}</td>
                  <td>{user.postsCount}</td>
                  <td>
                    {user.subscription ? user.subscription?.name : "Free Plan"}
                  </td>
                  <td>
                    <span className=" hover material-symbols-outlined">
                      visibility
                    </span>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BestSellersAnalytics;
