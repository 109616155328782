import React from "react";
import { BidImage } from "../../../modules";

function ClientActiveBids({ bids }) {
  return (
    <div className="box p-1 mt-1 ">
      {bids?.map((bid, index) => {
        if (index < 5)
          return (
            <div className="flex space-between align-center mb-1 ">
              <div className="flex align-center ">
                <BidImage bidID={bid._id} />
                &nbsp;
                <p>
                  {bid.quantity} ton - {bid.price}₹
                </p>
              </div>

              <p className="grey-text small-text ">3 November</p>
            </div>
          );
      })}
    </div>
  );
}

export default ClientActiveBids;
