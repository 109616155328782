import React, { useEffect } from "react";
import Carousel from "./components/Carousel";
import TopMarketPrice from "./components/TopMarketPrice";
import UserManual from "./components/UserManual";
import { useDispatch, useSelector } from "react-redux";
import { appmanagementAction } from "../../redux/action/appManagementAction";

function Appmanagement() {
  const dispatch = useDispatch();
  const { loading, appManagement } = useSelector(
    (state) => state.appManagementReducer
  );

  useEffect(() => {
    dispatch(appmanagementAction());
  }, [dispatch]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <Carousel imageCarousel={appManagement?.supportDetails?.carousel} />
      <TopMarketPrice topMarketPrice={appManagement.topMarketPrice} />
      <UserManual userManual={appManagement?.supportDetails?.userManual} />
    </div>
  );
}

export default Appmanagement;
