import React from "react";
import { CardPanel, Col, Row } from "react-materialize";

function CardAnalytics({ state }) {
  return (
    <Col m={4} s={12}>
      <CardPanel
        className=" flex column card-left-border "
        style={{ borderColor: state.color }}
      >
        <div className="flex space-between">
          <div className="flex align-center">
            <span
              className="material-symbols-outlined card-icon  lighten-5"
              style={{ color: state.color, backgroundColor: state.iconBG }}
            >
              {state.icon}
            </span>{" "}
            <b>{state.title}</b>
          </div>
        </div>
        <b className="card-title mt-1 ">{state.total}</b>
      </CardPanel>
    </Col>
  );
}

export default CardAnalytics;
