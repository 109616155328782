import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { readNotificationsAction } from "../../redux/action/accountsAction";
import { useNavigate } from "react-router-dom";
import { getCurrentDateTime, truncateString } from "../../helper/helper";

function RecentActivityAnalytics() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, notifications } = useSelector(
    (state) => state.accountNotifications
  );
  return (
    <div className="box p-1">
      <div className="flex space-between align-center ">
        <b>Recent Activity</b>
        <p
          className=" purple-text small-text hover  lighten-6 purple  btn-outline"
          role={"button"}
          onClick={() => navigate("/")}
        >
          View All
        </p>
      </div>
      <div>
        {notifications.map((item, index) => {
          if (index <= 7)
            return (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid #efefef",
                  padding: "7px 10px ",
                }}
                onClick={() => {
                  navigate(`/post-view/${item.post}`);
                }}
              >
                <div className="flex space-between  ">
                  <p style={{ fontWeight: "500", fontSize: "15px" }}>
                    {truncateString(item.title, 5)}
                  </p>
                  <p className="small-text grey-text ">
                    {getCurrentDateTime(item.timestamp)}
                  </p>
                </div>

                <p
                  style={{ fontWeight: "400", fontSize: "12px", color: "#999" }}
                >
                  {truncateString(item.body, 10)}
                </p>
              </div>
            );
        })}
      </div>
    </div>
  );
}

export default RecentActivityAnalytics;
