import React from "react";
import { updateMarketPriceAction } from "../../../redux/action/appManagementAction";
import { useDispatch } from "react-redux";

function TopMarketPrice({ topMarketPrice }) {
  const dispatch = useDispatch();
  return (
    <div className=" p-1 mt-1  box white">
      <spna className="flex align-center space-between ">
        <b>Top MarketPrice</b>
        <button
          className="btn"
          onClick={() => {
            dispatch(updateMarketPriceAction());
          }}
        >
          Update Top MarketPrice
        </button>
      </spna>

      {topMarketPrice?.map((marketPrice, index) => {
        return (
          <div className=" p-1 mt-1" key={index}>
            <span className="flex align-center gap-1 ">
              <b style={{ width: "40%" }}>{marketPrice.city}</b>
              <p style={{ width: "20%" }}>
                {marketPrice.name}-{marketPrice.type}
              </p>
              <p>{marketPrice.price}</p>
            </span>
            <hr color="#999" />
          </div>
        );
      })}
    </div>
  );
}

export default TopMarketPrice;
