import React from "react";
import { getCurrentDateTime } from "../../../helper/helper";
import { useNavigate } from "react-router-dom";
import { PostSingleImage } from "../../../modules";
import {truncateString} from '../../../helper/helper'

function RecentBuyersCard({ recentBuyingPosts }) {
  const navigate = useNavigate();

  return (
    <div className="full-width p-1 box white " >
      <div className="flex space-between align-center">
        <b className="normal-size">Recent Buyer Posts</b>
        <p
          className=" purple-text small-text hover  lighten-6 purple  btn-outline"
          role={"button"}
          onClick={() => navigate("offers")}
        >
          View All
        </p>
      </div>

      {recentBuyingPosts?.map((singlePost, index) => {
        if (index < 6)
          return (
            <div
              className="flex space-between align-center mb-1 hover  "
              onClick={() => navigate(`/post-view/${singlePost._id}`)}
              key={index}
            >
              <div className="flex align-center">
                <PostSingleImage postID={singlePost?._id} />
                <div className="ml-1">
                  <p>
                  {singlePost.scrap.name}-{truncateString(singlePost.scrap.variant,2)}
                  </p>
                  <p className="green-text">₹{singlePost.price}/kg</p>
                </div>
              </div>
              <div className="flex column align-end  " >
                <p>{singlePost.quantity} Tonne</p>
                <p className="small-text  grey-text ">
                  {getCurrentDateTime(singlePost.createdAt)}
                </p>
              </div>
            </div>
          );
      })}
    </div>
  );
}

export default RecentBuyersCard;
