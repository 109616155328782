import React from "react";

function ScrapsList({ scraps, removeFromArray }) {
  if (!scraps) {
    return;
  }
  return (
    <div className="flex flex-wrap gap-1">
      {scraps.map((item, index) => {
        return (
          <div
            className=" box flex column space-between  align-start "
            style={{ padding: "5px 10px " }}
          >
            <div key={index} className="flex align-center gap-1 " >
              <p>
                {item.type}-{item.name}
              </p>
              <span
                class="material-symbols-outlined small-text hover "
                onClick={() => {
                  removeFromArray(item);
                }}
              >
                delete
              </span>
            </div>
            <span className="flex" > 
            <p> {item.processingCapacity} -</p>
            <p> {item.quantityOfRecycled} -</p>
            <p>{item.category} </p>

            </span>
          </div>
        );
      })}
    </div>
  );
}

export default ScrapsList;
