import React from "react";
import { getCurrentDateTime } from "../../../helper/helper";
import { PostSingleImage } from "../../../modules";

function ClientActivePosts({ posts }) {
  return (
    <div className="box p-1 mt-1 ">
      {posts?.map((post, index) => {
        return (
          <div className="flex space-between align-center mb-1 " key={index}>
            <div className="flex align-center ">
              <PostSingleImage postID={post._id} />
              &nbsp;
              <p>
                {post.scrap?.name}-{post.scrap.variant} / {post.quantity} ton
              </p>
            </div>

            <p className="grey-text small-text ">
              {getCurrentDateTime(post.createdAt)}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default ClientActivePosts;
