import React, { useEffect } from "react";
import { Button, Col, Row, Modal } from "react-materialize";
import { PostDetails, BidsOnPost } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPostDetails } from "../redux/action/offersAction";
import { useNavigate } from "react-router-dom";
import Loading from "../modules/Loading";
import { deletePostAction } from "../redux/action/offersAction";

function PostView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postID } = useParams();
  const { postDetails, loading } = useSelector(
    (state) => state.postDetailsStore
  );

  // useEffect(() => {
  //   dispatch(getPostDetails(postID));
  // }, [dispatch]);

  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <div className="row">
      <div className="flex space-between align-center ">
        <span className="flex">
          <span
            class="material-symbols-outlined hover "
            onClick={() => navigate(-1)}
          >
            keyboard_backspace
          </span>
          <b className=" normal-size">&nbsp;Post View</b>
        </span>
        <div>
          <Modal
            actions={[]}
            bottomSheet={false}
            fixedFooter={false}
            id="Modal-10"
            open={false}
            options={{}}
            trigger={
              <Button className="red" node="button">
                Delete Post
              </Button>
            }
          >
            <b className="normal-size">Confirm Delete</b>
            <p>Are you sure you want to delete this post?</p>

            <div className="flex justify-end gap-1 align-center ">
              <button
                className="btn-submit"
                type="submit"
                onClick={() => dispatch(deletePostAction(postID, navigate))}
                modal="close"
              >
                YES
              </button>
              <Button className="btn-outline" modal="close">
                NO
              </Button>
            </div>
          </Modal>
          &nbsp;
          <Button onClick={() => navigate(`/edit-post/${postID}`)}>
            Edit Post
          </Button>
        </div>
      </div>
      <Col className="col s4">
        <PostDetails />
      </Col>
      <Col className="col s8">
        <BidsOnPost postDetails={postDetails} />
      </Col>
    </div>
  );
}

export default PostView;
