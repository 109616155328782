import React, { useState, useEffect } from "react";
import { Select as SelectOption, Switch } from "react-materialize";
import Input from "../../../modules/InputFeild";
import Select from "react-select";
import { getAllScrapsAction } from "../../../redux/action/offersAction";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { addNewOfferAction } from "../../../redux/action/offersAction";
import { toast } from "react-toastify";

function AddPost() {
  const dispatch = useDispatch();
  const { loading, scrapsList } = useSelector((state) => state.scrapsListStore);
  const [loadingAction, setLoadingAction] = useState(false);
  const [error, setError] = useState(false);
  const [location, setLocation] = useState({
    address: "",
    latitude: "",
    longitude: ""
  });

  const [scraps, setScraps] = useState([]);
  const [selectedOption, setSelectOption] = useState(null);
  const [post, setPost] = useState({
    type: "",
    quantity: "",
    price: "",
    siteVisitEnabled: false,
    transportEnabled: false
  });
  const [contactInfo, setContactInfo] = useState({
    name: "",
    phoneNumber: "",
    organization: ""
  });
  const [images, setImages] = useState([]);

  const handleChangePost = (event) => {
    const { value, name } = event.target;
    setPost({ ...post, [name]: value });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  scrapsList.forEach((element) => {
    const singleScrap = {
      value: element._id,
      label: element.name + "-" + element.variant
    };
    scraps.push(singleScrap);
  });

  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  // Handler for address change
  const handleChangeLocation = (address) => {
    setAddress(address);
    // setLocation({ address });
  };

  // Handler for address selection
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0])
        .then((res) => {
          const updatedLocation = {
            latitude: res.lat,
            longitude: res.lng,
            address: selectedAddress
          };
          setLocation(updatedLocation);
        })
        .catch((er) => console.log(er));
      setAddress(selectedAddress);
    } catch (error) {
      setErrorMessage("Error fetching location details");
    }
  };

  const submit = (e) => {
    e.preventDefault();

    if (
      !post.type ||
      !post.quantity ||
      !post.price ||
      !contactInfo.name ||
      !contactInfo.phoneNumber ||
      !contactInfo.organization ||
      !location.latitude ||
      !location.address
    ) {
      toast.error("All fields is required.");
      return;
    }

    const formData = new FormData();

    formData.append(
      "location",
      JSON.stringify({
        latitude: location?.latitude,
        longitude: location?.longitude,
        address: location?.address
      })
    );

    formData.append(
      "account",
      JSON.stringify({
        phoneNumber: contactInfo?.phoneNumber,
        organization: contactInfo?.organization,
        name: contactInfo?.name
      })
    );

    for (let key in post) {
      formData.append(key, post[key]);
    }

    images.forEach((img, i) => {
      formData.set(`image_${i}`, img);
    });

    dispatch(addNewOfferAction(formData, setLoadingAction));
  };

  useEffect(() => {
    dispatch(getAllScrapsAction());
  }, [dispatch]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="container">
      <b className="normal-size">Add New Post</b>
      <form className="flex column  p-1 " onSubmit={submit}>
        <div className="box white p-1 ">
          <label>Select Type</label>
          <SelectOption
            value={post.type}
            onChange={(e) => setPost({ ...post, type: e.target.value })}
          >
            <option value="">--select--</option>
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </SelectOption>
          <label className="mt-1">Quantity</label>
          <Input
            state={{
              value: post.quantity,
              placeHolder: "Enter quantity in tonne (1Ton = 1000kg)",
              icon: "weight",
              iconColor: "#6f2da8",
              name: "quantity"
            }}
            onChange={(e) => handleChangePost(e)}
          />
          <div className="mv-1">
            <b>location</b>
            <PlacesAutocomplete
              value={address}
              onChange={handleChangeLocation}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "Enter location...",
                      className:
                        "video-form location-search-input browser-default input-field-style full-width mt-1"
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            padding: 5
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            padding: 5
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errorMessage && <div>{errorMessage}</div>}
          </div>

          <span>
            <b>Type of material</b>
            <Select
              name="scrap"
              value={selectedOption}
              onChange={(e) => {
                setSelectOption(e.value.label);
                setPost({ ...post, scrap: e.value });
              }}
              options={scraps}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: 40,
                  width: "100%"
                })
              }}
              className="input-field-style"
            />
          </span>

          <label className="mt-1">Price</label>
          <Input
            state={{
              value: post.price,
              placeHolder: "Enter new price",
              icon: "currency_rupee",
              iconColor: "#6f2da8",
              name: "price"
            }}
            onChange={(e) => handleChangePost(e)}
          />

          <span>
            <label className="mt-1">Select Photos</label>
            <input
              type="file"
              multiple
              onChange={(e) => setImages([...e.target.files])}
            />
            <p className="grey-text">{images.length} selected</p>
          </span>

          <div className="flex align-center space-between mt-1">
            <label>Site Visit Enabled</label>
            <Switch
              id="10_siteVisitEnabled"
              checked={post.siteVisitEnabled}
              onChange={(e) =>
                setPost({ ...post, siteVisitEnabled: !post.siteVisitEnabled })
              }
            />
          </div>

          <div className="flex align-center space-between mt-1 ">
            <label>Transport Enabled</label>
            <Switch
              id="11_transportEnabled"
              checked={post.transportEnabled}
              onChange={(e) =>
                setPost({ ...post, transportEnabled: !post.transportEnabled })
              }
            />
          </div>
        </div>

        <div className="box white p-1 mt-1 ">
          <b className="normal-size">Contact Info</b>
          <label className="mt-1">Name</label>
          <Input
            state={{
              value: contactInfo.name,
              placeHolder: "Enter name",
              icon: "person",
              iconColor: "#6f2da8",
              name: "name"
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />

          <label className="mt-1">phone Number</label>
          <Input
            state={{
              value: contactInfo.phoneNumber,
              placeHolder: "Enter phone number",
              icon: "phone",
              iconColor: "#6f2da8",
              name: "phoneNumber"
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label className="mt-1">Organization Name</label>
          <Input
            state={{
              value: contactInfo.organization,
              placeHolder: "Enter organization",
              icon: "apartment",
              iconColor: "#6f2da8",
              name: "organization"
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <span className="flex justify-end mt-2  ">
          <button className="btn" type="submit" disabled={loadingAction}>
            Save
          </button>
        </span>
      </form>
    </div>
  );
}

export default AddPost;
