import React from "react";

function LocationsList({ newRecycler, deleteLocation }) {


  if (newRecycler.locations.length == 0) {
    return (
      <div className="white box p-5 flex justify-center  align-center">
        <p className="grey-text small-text ">No Locations Added.</p>
      </div>
    );
  }


  return (
    <div className="mt-1 ">
      <table className="striped">
        <thead>
          <tr className="capitalize small-text">
            <th>#</th>
            <th>Name</th>
            <th>Loation</th>
            <th>State</th>
            <th>Country</th>
            <th>Quantity</th>
            <th>Waste Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {newRecycler.locations?.map((item, index) => {
            return (
              <tr className="small-text" key={index} >
                <td>{index + 1}</td>
                <td>{item.nickname}</td>
                <td>{item.address}</td>
                <th>{item.state}</th>
                <th>{item.country}</th>
                <th>{item.wasteType}</th>
                <th>{item.quantity}&nbsp;MT</th>
                <td className="flex full-width align-center justify-center">
                  <span
                    class="material-symbols-outlined red-text hover"
                    onClick={() =>
                      deleteLocation(item.address)
                    }
                  >
                    delete
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default LocationsList;
