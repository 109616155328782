import React from "react";

function LanguageAnalytics({ analytics }) {
  return (
    <div className="flex gap-1 ">
      <div className="flex align-center white p-1 gap-1 full-width ">
        <span className="material-symbols-outlined purple lighten-4 p-1 box ">
          language
        </span>
        <span className="flex column ">
          <b>English</b>
          <b>{analytics?.english}</b>
        </span>
      </div>

      <div className="flex align-center white p-1 gap-1 full-width">
        <span className="material-symbols-outlined yellow lighten-4 p-1 box ">
          language
        </span>
        <span className="flex column ">
          <b>Tamil</b>
          <b>{analytics?.tamil}</b>
        </span>
      </div>

      <div className="flex align-center white p-1 gap-1 full-width ">
        <span className="material-symbols-outlined green lighten-4 p-1 box ">
          language
        </span>
        <span className="flex column ">
          <b>Hindi</b>
          <b>{analytics?.hindi}</b>
        </span>
      </div>
    </div>
  );
}

export default LanguageAnalytics;
