import React from "react";
import { Slider, Slide, Caption } from "react-materialize";
import { scrapMarketAdminURL } from "../redux/config/configURL";

function ImagesSliderBids({ images, bidID }) {
  return (
    <div style={{ marginTop: "10px" }}>
      <Slider
        fullscreen={false}
        options={{
          duration: 5,
          height: 160,
          width: 80,
          indicators: true,
          interval: 5000,
          indicators: false
        }}
      >
        {images?.map((image, index) => {
          return (
            <Slide key={index}>
              <img
                src={`${scrapMarketAdminURL}/bids/read/image/${bidID}?count=${
                  index + 1
                }`}
                width={"100%"}
                height={"300px"}
                alt="post-image"
                style={{ objectFit: "contain" }}
                key={index}
              />
            </Slide>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImagesSliderBids;
