import React, { useEffect, useState } from "react";
import { EditSubscriptionCard } from "../../../modules";
import Input from "../../../modules/InputFeild";
import { Button, Modal } from "react-materialize";
import AddSubscription from "./AddSubscription";
import { editSubscriptionPlansAction } from "../../../redux/action/subscriptionAction";
import { useDispatch } from "react-redux";

function EditSubscriptions({ subscriptionList, loading }) {
  const [subscriptionPlanStore, setSubscriptionPlanStore] = useState([]);
  const [newDiscountPercentage, setNewDiscountPercentage] = useState("");
  const [updateSubscriptionPlans, setUpdateSubscriptionPlans] = useState([]);
  const dispatch = useDispatch();
  const handleChangeDiscount = (e) => {
    setNewDiscountPercentage(e.target.value);

    subscriptionPlanStore.map((item) => {
      let planIndex = updateSubscriptionPlans.findIndex(
        (plan) => plan._id === item._id
      );

      if (planIndex >= 0) {
        updateSubscriptionPlans[planIndex].discountPercentage = e.target.value;
      } else {
        // If the plan does not exist in the array, add it
        updateSubscriptionPlans.push({
          _id: item._id,
          discountPercentage: e.target.value,
        });
      }
    });
  };

  const cancelChange = () => {
    setUpdateSubscriptionPlans([]);
    setSubscriptionPlanStore(subscriptionList);
    setNewDiscountPercentage("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editSubscriptionPlansAction(updateSubscriptionPlans));
  };

  useEffect(() => {
    if (loading) return;
    setSubscriptionPlanStore(subscriptionList);
  }, [loading]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex  align-center space-between  ">
          <b>Edit Subscriptions Plans</b>

          <span className="flex align-end gap-1 ">
            <span className="flex column  ">
              <p>Discount Percentage:</p>
              <Input
                state={{
                  value: newDiscountPercentage,
                  placeHolder: "Discount Percentage",
                  icon: "percent",
                  iconColor: "#0E9D1C",
                  type: "number",
                }}
                onChange={(e) => handleChangeDiscount(e)}
              />
            </span>
            <span className="flex gap-1 ">
              <Modal
                actions={[]}
                trigger={
                  <div className="btn-outline" node="button">
                    Add New Plan
                  </div>
                }
              >
                <AddSubscription />
              </Modal>
              <Button className="btn-outline" onClick={() => cancelChange()}>
                {" "}
                cancel{" "}
              </Button>
              <Button disabled={updateSubscriptionPlans.length == 0}>
                {" "}
                Save Change{" "}
              </Button>
            </span>
          </span>
        </div>

        <div className="   flex-warp gap-1 align-center">
          {subscriptionPlanStore.map((plan, index) => {
            return (
              <EditSubscriptionCard
                state={{
                  id: plan._id,
                  planName: plan.name,
                  duration: plan.numberOfMonths,
                  price: plan.price,
                  offerPrice: plan.offerPrice,
                  color: "#6f2da8",
                  discountPercentage: newDiscountPercentage
                    ? newDiscountPercentage
                    : plan.discountPercentage,
                }}
                index={index}
                subscriptionPlanStore={subscriptionPlanStore}
                updateSubscriptionPlans={updateSubscriptionPlans}
                setUpdateSubscriptionPlans={setUpdateSubscriptionPlans}
                setSubscriptionPlanStore={setSubscriptionPlanStore}
              />
            );
          })}
        </div>
      </form>
    </div>
  );
}

export default EditSubscriptions;
