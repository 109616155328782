import React from "react";

function InputFeild({ state, onChange, style }) {
  return (
    <div>
      <div className="input-field-style full-width  ">
        {state.icon && (
          <span
            class="material-symbols-outlined normal-size"
            style={{ color: state.iconColor, padding: "0 5px" }}
          >
            {state.icon}
          </span>
        )}
        <input
          placeholder={state.placeHolder}
          className="browser-default"
          type={state.type ? state.type : "text"}
          onChange={onChange}
          value={state.value}
          name={state.name ? state.name : ""}
          disabled={state.disabled}
          style={style}
          maxLength={state.maxLength ? state.maxLength : ""}
        />
      </div>
      {state.errorMSG && (
        <p style={{ marginTop: "-3" }} className="small-text red-text ">
          {state.errorMSG}
        </p>
      )}
    </div>
  );
}

export default InputFeild;

// scrap market Dashboard (CercleX)
// input Feild required
// 1 onChange state Function
// 2 state props it is a object contain
// I. icon if there
// II. input value
// III. place holder value
// IV. icon color
