import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRecentJoinedAction } from "../../../redux/action/analyticsAction";

function NewUsersJoined() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading, recentJoined } = useSelector((state) => state.recentJoined);

  useEffect(() => {
    dispatch(getRecentJoinedAction());
  }, []);

  if (loading) {
    return (
      <div className="full-width p-1 box white  " style={{ minHeight: "72vh" }}>
        <div>loading</div>
      </div>
    );
  }

  return (
    <div className="full-width p-1 box white  " style={{ minHeight: "72vh" }}>
      <div className="flex space-between  align-center ">
        <span>
          <b>Today's New Users</b>
          {/* {recentJoined && (
            <b
              style={{
                padding: "3px 14px",

                borderRadius: "2px",
                marginLeft: "5px"
              }}
              className="purple lighten-5"
            >
              {todayNewJoinedUsers}
            </b>
          )} */}
        </span>
        <p
          className=" purple-text small-text  mb-1 lighten-6 purple  btn-outline"
          onClick={() => navigate("/userList")}
        >
          View All
        </p>
      </div>

      {recentJoined?.length == 0 && (
        <div className="flex  align-center justify-center grey-text column">
          <img src="../images/empty-data.jpg" style={{ width: "200px" }} />
          <p className="small-text">No Users Joined Today.</p>
        </div>
      )}
      {recentJoined?.map((item, index) => {
        if (index <= 9)
          return (
            <div className="flex space-between align-start mb-1  " key={index}>
              <div className="flex align-center">
                <span
                  class="material-symbols-outlined circle cercle-purple-text  purple lighten-5"
                  style={{ padding: "5px" }}
                >
                  {" "}
                  person
                </span>
                <div className="ml-1">
                  <b className="capitalize">{item.name}</b>
                  <p className="small-text">
                    {item.phoneNumber ? item.phoneNumber : item.email}
                  </p>
                </div>
              </div>

              <p className="small-text capitalize orange-text">
                {item.subscription?.name
                  ? item.subscription?.name
                  : "free plan"}
              </p>
            </div>
          );
      })}
    </div>
  );
}

export default NewUsersJoined;
