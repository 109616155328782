import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis
} from "recharts";
import DatePicker from "react-datepicker";
import { SpinLoading } from "../../modules";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { endOfMonth, startOfMonth } from "date-fns";
import { getVerifiedUserByDate} from "../../redux/action/recycler";

function VerifiedUsersAnalytics() {
    const dispatch = useDispatch();
    const { loading, recyclerList } = useSelector(
      (state) => state.verifiedReyclers
    );
    console.log(recyclerList)
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));
    const [endDate, setEndDate] = useState(endOfMonth(new Date()));
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
  
    useEffect(() => {
      dispatch(getVerifiedUserByDate(startDate, endDate));
    }, [dispatch, startDate, endDate]);

  return (
    <div className="chart-dashboard mt-1 ">
      <div
        className="flex space-between align-center  pr-1 mb-1"
        style={{ marginTop: "-15px" }}
      >
        <div>
          <b>Verified Reyclers </b>
          <span className="small-text  ">
            &nbsp; ({startDate?.toDateString()} - {endDate?.toDateString()})
          </span>
        </div>

        <span className="flex align-center gap-1">
          {loading && <SpinLoading />}
          <div className="chart-date-select btn-outline flex align-center gap-1 ">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className="browser-default "
              dateFormat="dd/MM/yyyy"
            />
            <span className="material-symbols-outlined">calendar_month</span>
          </div>
        </span>
      </div>

      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          width={400}
          height={450}
          data={recyclerList ? recyclerList.result : []}
          margin={{
            top: 0,
            right: 0,
            left: -10,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorUv" y1="1" y2="0">
              <stop offset="35%" stopColor="#cea9fe" stopOpacity={0.6} />
              <stop offset="50%" stopColor="#7438fe" stopOpacity={0.4} />
            </linearGradient>
          </defs>

          <XAxis dataKey="date" />
          <YAxis dataKey="count" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="count"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default VerifiedUsersAnalytics