import React, { useState } from "react";
import { addNotesAction, deleteNoteAction } from "../../redux/action/recycler";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
function Notes({ notes }) {
  const dispatch = useDispatch();
  const [newNote, setNewNote] = useState("");
  const [addNewNote, setAddNewNote] = useState(false);
  const { id } = useParams();

  const EmptyNotes = () => {
    return (
      <div className="flex column align-center box white p-2 ">
        <span
          class="material-symbols-outlined  grey-text"
          style={{
            fontSize: "7rem",
          }}
        >
          quick_reference_all
        </span>
        <p className="grey-text mb-1 mt-1 ">There no notes for now.</p>
        <button
          className="btn  mt-1 "
          onClick={() => {
            setAddNewNote(true);
          }}
        >
          Add new note
        </button>
      </div>
    );
  };

  if (notes?.notes?.length === 0 && addNewNote)
    return (
      <div className="box p-1 white">
        <div className="mb-1 box p-1">
          <b>Add new Note</b>
          <div className=" flex align-center gap-1">
            <input
              required
              className="browser-default input-style  "
              type="text"
              placeholder="add new note"
              value={newNote}
              onChange={(e) => {
                setNewNote(e.target.value);
              }}
            />
            <button
              class="btn "
              onClick={() => {
                dispatch(addNotesAction(id, { note: newNote }));
                setNewNote("");
              }}
              disabled={!newNote}
            >
              Submit
            </button>
            <button
              class="btn-outline  "
              onClick={() => {
                setAddNewNote(false);
                setNewNote("");
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );

  if (notes?.notes?.length === 0) {
    return <EmptyNotes />;
  }

  return (
    <div className="box p-1 white">
      <div className="flex align-center space-between mb-1">
        <b className="mb-1">Notes:</b>
        <button
          className="btn"
          onClick={() => {
            setAddNewNote(true);
          }}
        >
          Add New
        </button>
      </div>

      {addNewNote && (
        <div className="mb-1 box p-1">
          <b>Add new Note</b>
          <div className=" flex align-center gap-1">
            <input
              required
              className="browser-default input-style  "
              type="text"
              placeholder="add new note"
              value={newNote}
              onChange={(e) => {
                setNewNote(e.target.value);
              }}
            />
            <button
              class="btn "
              onClick={() => {
                dispatch(addNotesAction(id, { note: newNote }));
                setNewNote("");
              }}
              disabled={!newNote}
            >
              Submit
            </button>
            <button
              class="btn-outline  "
              onClick={() => {
                setAddNewNote(false);
                setNewNote("");
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {notes?.notes?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex align-center space-between mb-1 "
            style={{
              borderBottom: "1px solid #999",
              padding: "0.5rem",
            }}
          >
            <p>
              {index + 1}.&nbsp;{item.content}
            </p>
            <span
              class="material-symbols-outlined red-text hover"
              onClick={() => {
                dispatch(deleteNoteAction(id, item.note_id));
              }}
            >
              delete
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Notes;
