import React from "react";
import EmptyMarketPrice from "./EmptyMarketPrice";
import MarketPriceUpdation from "./MarketPriceUpdation";

function MarketPriceContent({ material }) {
  return (
    <div>
      <MarketPriceUpdation material={material} />
    </div>
  );
}

export default MarketPriceContent;
