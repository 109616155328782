import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { getAppManagementReducer } from "./reducer/appManagementReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  getAllCitiesReducer,
  getSingleCityReducer,
  getCityMarketPriceHistoryReducer,
  updateCityMarketPriceReducer,
  getArchiveReducer
} from "./reducer/marketPriceReducer";

import {
  getBuyingPostsReducer,
  getPostDetailsReducer,
  getSellingPostsReducer,
  getBidsReducer,
  getSingleBidReducer,
  getAllScrapsReducer,
  getPendingPostsReducer
} from "./reducer/offerReducer";
import { loadingReducer } from "./reducer/loading";
import {
  getAllAccountsReducer,
  getSingleAccountReducer,
  getAnalyticsReducer,
  getNotificationReducer,
  getProfileReducer,
  addNewNoteReducer,
  getAdminsAccountsReducer
} from "./reducer/accountsReducer";

import {
  getJoinedUsersAnalyticsReducer,
  getBestSellerReducer,
  getSellingPostAnalyticsReducer,
  getBuyingPostAnalyticsReducer,
  getVerifiedThisMonthReducer,
  getAppliedForCreditLineReducer,
  getRecentJoinedReducer,
  getRecentPostsReducer
} from "./reducer/analyticsReducer";

import {
  getSubscriptionReducer,
  editSubscriptionPlansReducer,
  updatePriceSubscriptionReducer
} from "./reducer/subscriptionReducer";

import { getAllPaymentReducer } from "./reducer/payment";

import {
  getRecyclersReducer,
  getSingleRecyclerreducer,
  getRecyclerAnalyticsReducer,
  getCustomersReducer,
  getVerifiedRecyclersReducer
} from "./reducer/recyclersReducer";

const reducer = combineReducers({
  getAllCities: getAllCitiesReducer,
  getSingleCity: getSingleCityReducer,
  getCityMarketPriceHistory: getCityMarketPriceHistoryReducer,
  updateCityMarketPrice: updateCityMarketPriceReducer,
  loading: loadingReducer,
  buyingPostsStore: getBuyingPostsReducer,
  postDetailsStore: getPostDetailsReducer,
  sellingPostsStore: getSellingPostsReducer,
  getSingleAccountStore: getSingleAccountReducer,
  getAllAccountsStore: getAllAccountsReducer,
  postBidsStore: getBidsReducer,
  singleBid: getSingleBidReducer,
  analyticsStore: getAnalyticsReducer,
  accountNotifications: getNotificationReducer,
  scrapsListStore: getAllScrapsReducer,
  profileStore: getProfileReducer,
  addNewNoteStore: addNewNoteReducer,
  archiveStore: getArchiveReducer,
  joinedUsersList: getJoinedUsersAnalyticsReducer,
  bestSellersList: getBestSellerReducer,
  sellingPostsAnalytics: getSellingPostAnalyticsReducer,
  buyingPostsAnalytics: getBuyingPostAnalyticsReducer,
  verifiedTHisMonth: getVerifiedThisMonthReducer,
  subscriptionPlan: getSubscriptionReducer,
  editSubscriptionPlans: editSubscriptionPlansReducer,
  updatePriceSubscraiptions: updatePriceSubscriptionReducer,
  recyclers: getRecyclersReducer,
  singleRecyclerData: getSingleRecyclerreducer,
  payments: getAllPaymentReducer,
  pendingPostsStore: getPendingPostsReducer,
  ecoDataHubAnalytics: getRecyclerAnalyticsReducer,
  customersData: getCustomersReducer,
  adminsAccountsStore: getAdminsAccountsReducer,
  appManagementReducer: getAppManagementReducer,
  appliedForCreditLine: getAppliedForCreditLineReducer,
  verifiedReyclers: getVerifiedRecyclersReducer,
  recentJoined: getRecentJoinedReducer,
  recentPosts: getRecentPostsReducer
});
const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
