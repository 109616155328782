import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPaymentsActions } from "../../../redux/action/payment";
import { getCurrentDateTime } from "../../../helper/helper";
import { endOfMonth, startOfMonth } from "date-fns";
import DatePicker from "react-datepicker";
import { SpinLoading } from "../../../modules";

function Payments() {
  const dispatch = useDispatch();
  const { loading, paymentList } = useSelector((state) => state.payments);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (endDate) {
      dispatch(getAllPaymentsActions(startDate, endDate));
    }
  }, [dispatch, startDate, endDate]);

  return (
    <div>
      <div
        className="flex space-between align-center  pr-1 mb-1"
        style={{ marginTop: "-15px" }}
      >
        <div>
          <b>All Payments</b>
          <span className="small-text  ">
            &nbsp; ({startDate?.toDateString()} - {endDate?.toDateString()})
          </span>
        </div>

        <span className="flex align-center gap-1">
          {loading && <SpinLoading />}
          <div className="chart-date-select btn-outline flex align-center gap-1 ">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className="browser-default "
              dateFormat="dd/MM/yyyy"
            />
            <span className="material-symbols-outlined">calendar_month</span>
          </div>
        </span>
      </div>
      <table>
        <thead>
          <tr className="small-text">
            <th>#</th>
            <th>Date</th>
            <th>Mobile No</th>
            <th>Name</th>
            <th>Pack</th>
            <th>Location</th>
            <th>Total Amount</th>
            <th>Payment Id</th>
            <th>Status</th>
            <th>What'sApp Subscription</th>
            <th>Cities</th>
          </tr>
        </thead>
        <tbody>
          {paymentList.map((item, index) => {
            console.log(item);
            return (
              <tr className="small-text" key={index}>
                <td>{index + 1}</td>
                <td>{getCurrentDateTime(item.createdAt)}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.name}</td>
                <td>{item.package.name}</td>
                <td>{item.address}</td>
                <td>{item.amount / 100}</td>
                <td>{item.razorpay_payment_id}</td>
                <td>{item.successful ? "successful" : "Not successful"}</td>

                <td>{item.updatePricePackage ? "YES" : "NO"}</td>
                <td>
                  {item.updatePricePackage
                    ? item.updatePricePackage.cities?.length
                    : "0"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Payments;
