import React from "react";
import { Button } from "react-materialize";

function SendMessage() {
  return (
    <div className="box p-1 ">
      <b>Send message as notification for them</b>
      <textarea
        className="browser-default input-area p-1 mt-2"
        type="text"
        placeholder="Enter a message here..."
      />
      <div
        className="flex align-end justify-end mt-2"
      >
        <button  className="btn-outline" >
          Send Notification
        </button>
      </div>
    </div>
  );
}

export default SendMessage;
