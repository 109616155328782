import React, { useState, useEffect } from "react";
import { AllUsers } from "../../../components";
import Input from "../../../modules/InputFeild";
import {
  filterAccountAction,
  filterProfileAction,
  getAllAccountsAction,
} from "../../../redux/action/accountsAction";
import AccountsAnalytics from "../components/AccountsAnalytics";
import { useDispatch } from "react-redux";
import { getFilterAccountByType } from "../../../redux/action/accountsAction";


function UsersList() {
  const numberOfRow = 100;
  const [filterBy, setFilterBy] = useState("");
  const [errorMessage, setErrorMassage] = useState("");
  const [typeOfWast, setTypeOfWast] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const dispatch = useDispatch();
  const [skipPagination, setSkipPagination] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSkipPagination((pageNumber - 1) * numberOfRow);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [dispatch]);

  const handleSearch = () => {
    setSkipPagination(0);
    if (filterBy == "") {
      setErrorMassage("Please select filter type.");
      return;
    }
    setCurrentPage(1);
    if (filterBy) {
      if (filterBy == "verified") {
        dispatch(filterAccountAction("verified", skipPagination, numberOfRow));
      } else if (filterBy == "subscribed") {
        dispatch(
          filterProfileAction("subscribed", skipPagination, numberOfRow)
        );
      } else if (filterBy == 1 || filterBy == 2) {
        if (filterValue == "") {
          setErrorMassage("Phone number or user name can't be empty.");
        } else {
          dispatch(
            filterAccountAction(filterValue, skipPagination, numberOfRow)
          );
        }
      } else if (filterBy == 4 || filterBy == 3) {
        if (filterValue == "") {
          setErrorMassage("Location can't be empty.");
        } else {
          dispatch(
            filterProfileAction(filterValue, skipPagination, numberOfRow)
          );
        }
      } else if (filterBy == "typeOfWast") {
        if (typeOfWast == "") {
          setErrorMassage("Type Of Wast Can't be empty.");
        } else {
          dispatch(
            getFilterAccountByType(typeOfWast, skipPagination, numberOfRow)
          );
        }
      } else {
        dispatch(getAllAccountsAction(skipPagination, numberOfRow));
      }
    }
  };

  function reset() {
    setFilterBy("");
    setFilterValue("");
    setTypeOfWast("");
    setErrorMassage("");
    setSkipPagination(0);
    setCurrentPage(1);
  }

  useEffect(() => {
    if (filterBy) {
      if (filterBy == "verified") {
        dispatch(filterAccountAction("verified", skipPagination, numberOfRow));
      } else if (filterBy == "subscribed") {
        filterProfileAction("subscribed", skipPagination, numberOfRow);
      } else if (filterBy == 1 || filterBy == 2) {
        filterValue == ""
          ? setErrorMassage("")
          : dispatch(
              filterAccountAction(filterValue, skipPagination, numberOfRow)
            );
      } else if (filterBy == 4 || filterBy == 3) {
        dispatch(filterProfileAction(filterValue, skipPagination, numberOfRow));
      } else if (filterBy == "typeOfWast") {
        dispatch(
          getFilterAccountByType(typeOfWast, skipPagination, numberOfRow)
        );
      }
    } else {
      dispatch(getAllAccountsAction(currentPage * numberOfRow, numberOfRow));
    }
  }, [dispatch, skipPagination]);
  //

  return (
    <div className="box p-1 ">
      <AccountsAnalytics />
      <div className="flex align-center space-between  ">
        <b className="normal-size">Users List</b>
        <div className="flex justify-end mb-1">
          <div className="flex gap-1  align-center  " style={{ width: "45vw" }}>
            <div className="full-width">
              {filterBy == "typeOfWast" ? (
                <div>
                  <select
                    value={typeOfWast}
                    className="browser-default full-width "
                    style={{ borderColor: "#fff" }}
                    onChange={(e) => {
                      setTypeOfWast(e.target.value);
                    }}
                  >
                    <option value={0}>--Filter By--</option>
                    <option value={"metal"}>metal</option>
                    <option value={"paper"}>paper</option>
                    <option value={"plastic"}>plastic</option>
                    <option value={"eWaste"}>eWaste</option>
                    <option value={"battery"}>battery</option>
                    <option value={"tyres"}>tyres</option>
                    <option value={"wood"}>wood</option>
                  </select>
                  {errorMessage && <p className="red-text">{errorMessage}</p>}
                </div>
              ) : (
                <Input
                  state={{
                    value: filterValue,
                    placeHolder: "find a user, by location or phone number ",
                    icon: "",
                    iconColor: "#6f2da8",
                    errorMSG: errorMessage
                  }}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    setErrorMassage("");
                  }}
                />
              )}
            </div>
            <select
              value={filterBy}
              className="browser-default"
              style={{ borderColor: "#fff" }}
              onChange={(e) => {
                setFilterBy(e.target.value);
              }}
            >
              <option value={0}>--Filter By--</option>
              <option value={1}>Phone Number</option>
              <option value={2}>User Name</option>
              <option value={3}>Location</option>
              <option value={"verified"}>Verified</option>
              <option value={"subscribed"}>Subscribed</option>
              <option value={"typeOfWast"}>Waste Type </option>
            </select>

            <div className="flex gap-1 " style={{ width: "40%" }}>
              <button className="btn-outline" onClick={handleSearch}>
                <span class="material-symbols-outlined">search</span>
                Search
              </button>

              <button
                className="btn-outline"
                onClick={reset}
                style={{ width: "140px" }}
              >
                <span class="material-symbols-outlined">clear_all</span>
                Re-set
              </button>
            </div>
          </div>
        </div>
      </div>
      <AllUsers
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        numberOfRow={numberOfRow}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default UsersList;
// <span class="material-symbols-outlined  ">search</span>
//
