import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
// import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { loginUser, requestOtpAction } from "../../../redux/action/auth";
import ReactLoading from "react-loading";

function Login() {
  const isTablet = useMediaQuery({ query: "(max-width:790px)" });
  const { showLoading } = useSelector((state) => state.loading);

  const inputStyle = {
    border: "none",
    width: "80%",
    outline: "none",
    backgroundColor: "#fff",
    paddingLeft: "45px"
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userName) {
      setUserNameError("Please enter your email address to login. ");
    } else if (!password) {
      setPasswordError("Please enter your password to login.");
    } else {
      dispatch(loginUser(userName, password, navigate));
    }
  };

  const requestOtp = (e) => {
    e.preventDefault();
    if (userName.length < 13) {
      setUserNameError(
        "Please enter your phone number with country code to receive an OTP for login."
      );
    } else {
      dispatch(requestOtpAction(userName, navigate));
    }
  };

  return (
    <div className="auth-page  ">
      {!isTablet ? (
        <header className="full-width ph-2  flex  space-between pt-1">
          <img
            src="../images/logo.png"
            style={{ height: "28px" }}
            className="hover"
          />

          <div
            className="flex space-between align-center gap-1"
            style={{ width: "28vw" }}
          >
            <a href="https://cerclex.com/">
              <p className="normal-size">Home</p>
            </a>

            <a href="https://cerclex.com/about-us/">
              <p className="normal-size">About Us</p>
            </a>

            <a href="https://cerclex.com/blogs/">
              <p className="normal-size">Blog</p>
            </a>

            <a href="https://cerclex.com/#deep-dive">
              <p className="normal-size">Contact Us</p>
            </a>
          </div>
        </header>
      ) : (
        <div className=" flex space-between  align-center p-1 ">
          <img src="../images/logo.png" alt="logo" style={{ width: "100px" }} />
          <span class="material-symbols-outlined icon   ">menu</span>
        </div>
      )}
      <div className=" flex mt-5" style={{ height: "75%" }}>
        <div
          className="  flex box "
          style={{ height: ` ${!isTablet ? "90%" : ""}` }}
        >
          {!isTablet && (
            <div
              className="purple-core white-text p-2 "
              style={{ width: "50%", borderRadius: "20px 0 0 20px" }}
            >
              <b className="large-size flex column border-bottom">
                <span>Let’s make it </span>
                <span>happen together! </span>
              </b>
              <p className="mv-2">
                Thank you for choosing Cercle X, Please register with your
                mobile number.
              </p>
              <div className="mt-3 align-center flex center justify-center ">
                <img src="../images/earth-logo.png" className="earth-image" />
              </div>
            </div>
          )}

          <div
            className="flex column space-between p-2 center  "
            style={{
              width: `${isTablet ? "100%" : "50%"}`,
              borderRadius: "0 20px 20px 0"
            }}
          >
            <div style={{ padding: `${!isTablet ? " 0 20px 0 20px " : "0"}` }}>
              <form onSubmit={handleSubmit} className="flex column ">
                <b className="large-size">
                  <span className="purple-text">Welcome! </span>
                  Let's Sign In
                </b>
                <b>Enter your account information below</b>

                <div className="flex column align-start mt-2 ">
                  <div className="input-field">
                    <span class="material-symbols-outlined primary">mail</span>
                    <input
                      className="browser-default input-field"
                      placeholder="Enter your email or phone Number"
                      type="string"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setUserNameError("");
                      }}
                    />
                  </div>
                  {userNameError && (
                    <p className="small-text red-text mb-1 ">{userNameError}</p>
                  )}

                  <div className="input-field">
                    <span class="material-symbols-outlined primary ">lock</span>
                    <input
                      className="browser-default input-field  "
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError("");
                      }}
                    />
                    <div
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      style={{ width: "25px" }}
                    >
                      {showPassword ? (
                        <AiFillEye className="eye-icon" />
                      ) : (
                        <AiFillEyeInvisible className="eye-icon" />
                      )}
                    </div>
                  </div>
                  {passwordError && (
                    <p className="small-text red-text mb-1">{passwordError}</p>
                  )}
                  <div className="submit-btn mv-2 column flex align-center full-width ">
                    <button
                      className="btn-submit mb-1 full-width"
                      type="submit"
                    >
                      {!showLoading ? (
                        " Login "
                      ) : (
                        <div className="full-width justify-center flex align-center white-text ">
                          <ReactLoading
                            type={"spinningBubbles"}
                            color="#fff"
                            width={25}
                          />
                        </div>
                      )}
                    </button>
                    <b className="grey-text">OR</b>
                    <button
                      className="btn-outline full-width flex justify-center mt-1"
                      onClick={requestOtp}
                    >
                      <p>Request OTP</p>
                    </button>
                  </div>

                  <p className="semi-bold">
                    Dont have an account?
                    <a
                      href={"https://accounts.thinktrash.co/"}
                      className="primary cercle-purple-text "
                    >
                      {" "}
                      Register now
                    </a>{" "}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
