export const getBuyingPostsReducer = (
  state = { loading: false, buyingPosts: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_BUYING_POSTS":
      return { ...state, loading: true };
    case "FOT_BIUING_POSTS":
      return { ...state, loading: false, buyingPosts: action.payload };

    case "FILTERING_POSTS":
      return { ...state, loading: true };
    case "POSTS_FILTERED":
      return { ...state, loading: false, buyingPosts: action.payload };
    case "CANNOT_FILTER_POSTS":
      return { ...state, loading: false };

    case "CANNOT_GET_BUYING_POSTS":
      return { ...state, loading: false };

    default:
      return state;
  }
};

export const getPostDetailsReducer = (
  state = { loading: false, postDetails: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_POST_DETAILS":
      return { ...state, loading: true };
    case "GOT_POST_DETAILS":
      return { ...state, loading: false, postDetails: action.payload };
    case "CANNOT_GET_POST_DETAILS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSellingPostsReducer = (
  state = { loading: false, sellingPosts: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_SELLING_POSTS":
      return { ...state, loading: true };
    case "GOT_SELLING_POSTS":
      return { ...state, loading: false, sellingPosts: action.payload };
    case "CANNOT_GET_SELLING_POST":
      return { ...state, loading: false };
    case "FILTERING_SELLING_POSTS":
      return { ...state, loading: true };
    case "POSTS_SELLING_FILTERED":
      return { ...state, loading: false, sellingPosts: action.payload };
    case "CANNOT_FILTER_SELLING_POSTS":
      return { ...state, loading: false };

    default:
      return state;
  }
};

export const getBidsReducer = (
  state = { loading: false, postBids: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_POST_BIDS":
      return { ...state, loading: true };
    case "GOT_POST_BIDS":
      return { ...state, loading: false, postBids: action.payload };
    case "CANNOT_GET_BIDS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getSingleBidReducer = (
  state = { loading: false, bidDetails: {} },
  action
) => {
  switch (action.type) {
    case "GETTING_BID":
      return { ...state, loading: true };
    case "GOT_BID":
      return { ...state, loading: false, bidDetails: action.payload };
    case "CANNOT_GET_BID":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getAllScrapsReducer = (
  state = { loading: false, scrapsList: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_SCRAPS":
      return { ...state, loading: true };
    case "GOT_ALL_SCRAPS":
      return { ...state, loading: false, scrapsList: action.payload };
    case "CANNOT_GET_SCRAPS":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const getPendingPostsReducer = (
  state = { loading: false, pendingPosts: [] },
  action
) => {
  switch (action.type) {
    case "GETTING_PENDING_POSTS":
      return { ...state, loading: true };
    case "GOT_PENDING_POSTS":
      return { ...state, loading: false, pendingPosts: action.payload };
    case "CANNOT_GET_PENDING_POST":
      return { ...state, loading: false };
    default:
      return state;
  }
};
