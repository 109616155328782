import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatePriceSubscriptionPlansAction } from "../../../redux/action/subscriptionAction";
import { getCurrentDateTime } from "../../../helper/helper";
import { useNavigate } from "react-router-dom";

function UpdateMarketPricePlan() {
  const dispatch = useDispatch();
   const navigate = useNavigate()
  const { loading, usersList } = useSelector(
    (state) => state.updatePriceSubscraiptions
  );


  useEffect(() => {
    dispatch(getUpdatePriceSubscriptionPlansAction());
  }, [dispatch]);
  return (
    <div className="white full-width  p-1 ">
      <div className="flex space-between align-center  ">
        <b>Update Market Price Subscraptions</b>
        <p className="purple-text small-text  mb-1 lighten-6 purple  btn-outline" onClick={()=> navigate('/update-price-subscription') } >
          Veiw All{" "}
        </p>
      </div>

      <table className="striped">
        <thead>
          <tr className="small-text">
            <th>Name</th>
            <th>phoneNumber</th>
            <th>Subscription</th>
            <th>Number of Cities</th>
            <th>expiresOn</th>
          </tr>
        </thead>
        <tbody>
          {usersList.map((item, index) => {
            if (index < 6)
              return (
                <tr className="small-text" key={index}>
                  <td>{item.name}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.updatePricePack.name}</td>
                  <td>{item.updatePricePack.cities?.length}</td>
                  <td>{getCurrentDateTime(item.updatePricePack.expiresOn)}</td>
                </tr>
              );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UpdateMarketPricePlan;
