import React, { useState } from "react";
import Input from "../../modules/InputFeild";
import { useDispatch } from "react-redux";
import { deleteRecyclerScrapAction } from "../../redux/action/recycler";
import { useParams } from "react-router-dom";
import { editRecyclerScrapAction } from "../../redux/action/recycler";
import { Modal } from "react-materialize";
import AddScrapForm from "./AddScrapForm";

function EditScrpaList({ scraps }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [editData, setEditData] = useState({
    category: "",
    name: "",
    processingCapacity: "",
    quantityOfRecycled: "",
    availableCredits: "",
    variant: "",
    price: ""
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleSubmit = (itemID) => {
    dispatch(editRecyclerScrapAction(id, itemID, editData, setLoading));
  };
  return (
    <div>
      <div className="flex space-between align-center mb-1 mt-2  ">
        <b>Scraps List</b>

        <Modal
          id="Modal-10"
          open={false}
          trigger={<button className="btn">Add Scrap</button>}
        >
          <AddScrapForm />
        </Modal>
      </div>
      <table className="striped">
        <thead className="small-text">
          <tr>
            <th>Type</th>
            <th>Category</th>
            <th>Name</th>
            <th>Processing Capacity</th>
            <th>Quantity Of Recycled</th>
            <th>Available Credits</th>
            <th>Variant</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {scraps?.map((item, key) => {
            return (
              <tr className="small-text">
                <td>{item.type}</td>
                <td>
                  {editRowId == item._id ? (
                    <select
                      className="browser-default full-width"
                      onChange={(e) => handleChange(e)}
                      value={editData.category}
                      name="category"
                    >
                      <option>--select--</option>
                      <option value="CAT I">CAT-I</option>
                      <option value="CAT II">CAT-II</option>
                      <option value="CAT III">CAT-III</option>
                      <option value="CAT IV">CAT-IV</option>
                    </select>
                  ) : (
                    item.category
                  )}
                </td>
                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.name,
                        placeHolder: "Enter Recycler Name",
                        icon: "h_mobiledata_badge",
                        iconColor: "#6f2da8",
                        name: "name"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : (
                    item.name
                  )}
                </td>
                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.processingCapacity,
                        placeHolder: "Enter Recycler Name",
                        icon: "settings_suggest",
                        iconColor: "#6f2da8",
                        name: "processingCapacity"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : (
                    item.processingCapacity
                  )}
                </td>
                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.quantityOfRecycled,
                        placeHolder: "Enter quantity Of Recycled",
                        icon: "cycle",
                        iconColor: "#6f2da8",
                        name: "quantityOfRecycled"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : (
                    item.quantityOfRecycled
                  )}
                </td>
                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.availableCredits,
                        placeHolder: "Enter available Credits",
                        icon: "cycle",
                        iconColor: "#6f2da8",
                        name: "availableCredits"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : (
                    item.availableCredits
                  )}
                </td>
                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.variant,
                        placeHolder: "Enter Recycler Name",
                        icon: "keyboard_keys",
                        iconColor: "#6f2da8",
                        name: "variant"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : (
                    item.variant
                  )}
                </td>

                <td>
                  {editRowId == item._id ? (
                    <Input
                      state={{
                        value: editData.price,
                        placeHolder: "Enter Recycler Name",
                        icon: "currency_exchange",
                        iconColor: "#6f2da8",
                        name: "price"
                      }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  ) : item.price ? (
                    item.price
                  ) : (
                    "-"
                  )}
                </td>

                {!loading ? (
                  <td>
                    {editRowId == item._id ? (
                      <span
                        class="material-symbols-outlined green-text hover "
                        onClick={() => handleSubmit(item._id)}
                      >
                        check_circle
                      </span>
                    ) : (
                      <span
                        class="material-symbols-outlined hover "
                        onClick={() => {
                          setEditRowId(item._id);
                          setEditData({
                            category: item.category,
                            name: item.name,
                            processingCapacity: item.processingCapacity,
                            quantityOfRecycled: item.quantityOfRecycled,
                            availableCredits: item.availableCredits,
                            variant: item.variant,
                            price: item.price ? item.price : 0
                          });
                        }}
                      >
                        edit
                      </span>
                    )}

                    {editRowId == item._id ? (
                      <span
                        class="material-symbols-outlined red-text hover"
                        onClick={() => setEditRowId("")}
                      >
                        cancel
                      </span>
                    ) : (
                      <span
                        class="material-symbols-outlined red-text hover "
                        onClick={() =>
                          dispatch(deleteRecyclerScrapAction(id, item._id))
                        }
                      >
                        delete
                      </span>
                    )}
                  </td>
                ) : (
                  <td className="cercle-purple-text">loading...</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default EditScrpaList;
