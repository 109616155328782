import React, { useEffect } from "react";
import { Tabs, Tab, Table } from "react-materialize";
import { SellerPost, BuyerPosts, PendingPosts } from "../components";
import { getAllScrapsAction } from "../../../redux/action/offersAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


function Offers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllScrapsAction());
  }, [dispatch]);
  return (
    <div className="white p-1 box ">
      <div className="flex justify-end">
        <button className="btn" onClick={() => navigate("/add-post")}>
          Add Offer
        </button>
      </div>
      <Tabs className="gap-1">
        <Tab title="Buyer Posts">
          <BuyerPosts />
        </Tab>
        <Tab title="Seller Posts">
          <SellerPost />
        </Tab>
        <Tab title="Pending Posts">
          <PendingPosts />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Offers;
