import React, { useState } from "react";
import Map from "./Map";
import { addNewLocationAction } from "../../redux/action/recycler";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function AddLocation() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [values, setValues] = useState({
    location: {
      longitude: "",
      latitude: "",
      address: "",
      nickname: "",
      state: "",
      quantity: "",
      country: "",
      wasteType: "",
    },
  });
  function addNewLocation() {
    let newLocations = {
      state: values.location.state,
      latitude: values.location.latitude,
      longitude: values.location.longitude,
      address: values.location.address,
      nickname: values.location?.nickname,
      country: values.location.country,
      wasteType: values.location.wasteType,
      quantity: values.location.quantity,
    };

    dispatch(addNewLocationAction(id,newLocations));

    setValues({
      location: {
        longitude: "",
        latitude: "",
        address: "",
        nickname: "",
        state: "",
        quantity: "",
        country: "",
        wasteType: "",
      },
    });
  }

  return (
    <Map values={values} setValues={setValues} addLocation={addNewLocation} />
  );
}

export default AddLocation;
