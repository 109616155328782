import React, { useEffect, useState } from "react";
import { Col, Button } from "react-materialize";
import Input from "../../modules/InputFeild";
import { getCurrentDateTime } from "../../helper/helper";
import { updateCityMarketPriceAction } from "../../redux/action/marketPriceAction";
import { useDispatch } from "react-redux";

function UpdateMarketPriceForm({ scraps, cityId, material }) {
  const dispatch = useDispatch();
  const [allScraps, setAllScraps] = useState([]);

  // change
  const handleChange = (index, update, scrapId) => {
    const newValue = [...allScraps];
    newValue[index] = { ...newValue[index], price: update };
    setAllScraps(newValue);
  };

  useEffect(() => {
    if (material) {
      const scrapsFiltred = scraps.filter(
        (item) =>
          item?.scrap?.name === material?.name &&
          item?.scrap?.variant === material.variant
      );
      setAllScraps(scrapsFiltred);
    } else {
      setAllScraps(scraps);
    }
  }, [material, scraps]);

  // submit funcation
  const handleSubmit = (e) => {
    e.preventDefault();

    // allScraps.filter((singleScrap) => {
    //   scraps.find((item) => {
    //     if (
    //       item.scrap?._id === singleScrap.scrap._id &&
    //       item.price !== singleScrap.price
    //     ) {
    //       newPrice.push({
    //         scrap: singleScrap.scrap._id,
    //         price: singleScrap.price,
    //       });

    // if (newPrice.length === 0) {
    //   newPrice.push({
    //     scrap: singleScrap.scrap._id,
    //     price: singleScrap.price
    //   });

    // } else {
    //   newPrice.map((scrapChanged) => {
    //     if (scrapChanged.scrap !== singleScrap._id) {
    //       newPrice.push({
    //         scrap: singleScrap.scrap._id,
    //         price: singleScrap.price
    //       });
    //     }
    //   });
    // }
    //     }
    //   });
    // });
    let scrpaList = [];

    allScraps.forEach((item) => {
      scrpaList.push({
        scrap: item.scrap._id,
        price: item.price
      });
    });
    dispatch(updateCityMarketPriceAction(cityId, scrpaList));
  };

  return (
    <div className="flex column">
      <div className="mt-1">
        <Col className="col s7 mb-1 ">
          <b className="purple-text">Material name</b>
        </Col>
        <Col className="col s3 mb-1">
          {" "}
          <b className="purple-text">Today price</b>
        </Col>
        <Col className="col s2 mb-1 ">
          <b className="purple-text">Last Updated</b>
        </Col>
      </div>

      <form onSubmit={handleSubmit}>
        {allScraps.map((item, index) => {
          return (
            <div className="flex align-center " key={index}>
              <Col className="col s7">
                <p className=" input-field-style p-1">
                  {item.scrap?.name}- {item.scrap?.variant}
                </p>
              </Col>

              <Col className="col s3">
                <Input
                  state={{
                    value: item.price,
                    placeHolder: "enter new price",
                    icon: "currency_rupee",
                    iconColor: "#0E9D1C"
                  }}
                  onChange={(e) => {
                    handleChange(index, e.target.value, item._id);
                  }}
                />
              </Col>

              <Col className="col s2">
                <p>{getCurrentDateTime(item.timestamp)}</p>
              </Col>
            </div>
          );
        })}

        <div
          className="flex align-end justify-end mt-2"
          style={{ marginRight: "30px" }}
        >
          <Button node="button" type="submit" waves="light">
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
}

export default UpdateMarketPriceForm;
