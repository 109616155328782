import React, { useState } from "react";
import Input from "../../modules/InputFeild";
import { Button } from "react-materialize";

function ContactDetails({
  setStep,
  newRecycler,
  setNewRecycler,
  handleSubmit,
  loading
}) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "type") {
      setNewRecycler({ ...newRecycler, [name]: value });
    } else {
      setNewRecycler({ ...newRecycler, [name]: value });
    }
  };

  const handleContactChange = (index, field, value) => {
    setNewRecycler((prevRecycler) => {
      const updatedContactDetails = [...prevRecycler.contactDetails];
      updatedContactDetails[index][field] = value;
      return {
        ...prevRecycler,
        contactDetails: updatedContactDetails
      };
    });
  };

  return (
    <div>
      <b>Contact Details:</b>
      <div className="flex align-center space-between gap-2 mb-1 mt-2 ">
        <div className="flex column full-width ">
          <label>Name</label>
          <Input
            state={{
              value: newRecycler.contactDetails[0].name,
              placeHolder: "Enter Recycler Name",
              icon: "person",
              iconColor: "#6f2da8",
              name: "name1"
            }}
            onChange={(e) => {
              handleContactChange(0, "name", e.target.value);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label>Email</label>
          <Input
            state={{
              value: newRecycler.email,
              placeHolder: "Enter Recycler Email",
              icon: "email",
              iconColor: "#6f2da8",
              name: "email"
            }}
            onChange={(e) => {
              handleContactChange(0, "email", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex align-center space-between gap-2  ">
        <div className="flex column full-width ">
          <label>Phone Number</label>
          <Input
            state={{
              value: newRecycler.phoneNumber,
              placeHolder: "enter phone number",
              icon: "phone",
              iconColor: "#6f2da8",
              name: "phoneNumber"
            }}
            onChange={(e) => {
              handleContactChange(0, "phoneNumber", e.target.value);
            }}
          />
        </div>

        <div className="flex column full-width "></div>
      </div>

      <hr className="mt-2 mb-2  " />
      <div className="flex align-center space-between gap-2 mb-1 ">
        <div className="flex column full-width ">
          <label>Name</label>
          <Input
            state={{
              value: newRecycler.name,
              placeHolder: "Enter Recycler Name",
              icon: "person",
              iconColor: "#6f2da8",
              name: "name"
            }}
            onChange={(e) => {
              handleContactChange(1, "name", e.target.value);
            }}
          />
        </div>

        <div className="flex column full-width ">
          <label>Email</label>
          <Input
            state={{
              value: newRecycler.email,
              placeHolder: "Enter Recycler Email",
              icon: "email",
              iconColor: "#6f2da8",
              name: "email"
            }}
            onChange={(e) => {
              handleContactChange(1, "email", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex align-center space-between gap-2  ">
        <div className="flex column full-width ">
          <label>Phone Number</label>
          <Input
            state={{
              value: newRecycler.phoneNumber,
              placeHolder: "enter phone number",
              icon: "phone",
              iconColor: "#6f2da8",
              name: "phoneNumber"
            }}
            onChange={(e) => {
              handleContactChange(1, "phoneNumber", e.target.value);
            }}
          />
        </div>

        <div className="flex column full-width "></div>
      </div>
      <span className="flex align-end gap-1  justify-end flex-end mt-1 ">
        <Button className="btn-outline" onClick={() => setStep(0)}>
          Back
        </Button>
        <Button type="submit" disabled={loading}>
          {loading ? "loading..." : "Submit"}
        </Button>
      </span>
    </div>
  );
}

export default ContactDetails;
