import React from "react";
import { Button } from "react-materialize";
import { EditAdditionalCard } from "../../../modules";

function EditAdditionalCredits() {
  return (
    <div>
      <div className="flex align-center space-between ">
        <span className="flex align-center gap-1">
          <span class="material-symbols-outlined">arrow_back</span>
          <b className="normal-size">Edit Subscription</b>
        </span>
        <div className="flex gap-1 align-center ">
          <button className="btn-delete">Cancel</button>
          <Button>Save Changes</Button>
        </div>
      </div>
      <div className="flex-wrap flex gap-1">
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#6A78FA",
            secondary_color: "#F1F7FF"
          }}
        />
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#FD6EC4",
            secondary_color: "#F9EDF4"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#6A78FA",
            secondary_color: "#F1F7FF"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#FD6EC4",
            secondary_color: "#F9EDF4"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#6A78FA",
            secondary_color: "#F1F7FF"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#FD6EC4",
            secondary_color: "#F9EDF4"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#6A78FA",
            secondary_color: "#F1F7FF"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#FD6EC4",
            secondary_color: "#F9EDF4"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#6A78FA",
            secondary_color: "#F1F7FF"
          }}
        />{" "}
        <EditAdditionalCard
          state={{
            planName: "Free Welcome Credits",
            coins: "10",
            activeUsers: "30000",
            color: "#FD6EC4",
            secondary_color: "#F9EDF4"
          }}
        />
      </div>
    </div>
  );
}

export default EditAdditionalCredits;
