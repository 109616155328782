import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSingleRecyclerAction } from "../../redux/action/recycler";
import { useParams } from "react-router-dom";
import { getFirstAndLastLetters } from "../../helper/helper";
import EditScrapsList from "../../components/recycler/EditScrpaList";
import { Modal } from "react-materialize";
import AddNewConactDetails from "../../components/recycler/AddNewConactDetails";
import EditRecyclerDetails from "./EditRecyclerDetails";
import AddLocation from "../../components/recycler/AddLocation";
import EditContactDetails from "../../components/recycler/EditContactDetails";
import Notes from "./Notes";
import {
  deleteScrapAction,
  deleteRecyclerLocationAction
} from "../../redux/action/recycler";

function EditRecycler() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, singleRecycler } = useSelector(
    (state) => state.singleRecyclerData
  );
  useEffect(() => {
    dispatch(getSingleRecyclerAction(id));
  }, [dispatch]);

  if (loading) {
    return <p>loading</p>;
  }
  return (
    <div className=" p-1 container ">
      <span className="flex column align-center">
        <div
          className="p-5 cercle-purple flex align-center justify-center circle normal-size bold"
          style={{ width: "40px", height: "40px" }}
        >
          <b style={{ fontSize: "25px" }} className="white-text">
            {getFirstAndLastLetters(
              singleRecycler.organizationName?.split(" ")
            )}
          </b>
        </div>

        <b>{singleRecycler.organizationName}</b>
        <p>{singleRecycler.organizationEmail}</p>
      </span>

      <div>
        <span className="flex mb-1 align-center space-between ">
          <b>Contact Details:</b>

          <Modal
            id="Modal-10"
            open={false}
            trigger={
              <button className="btn" node="button">
                Add New
              </button>
            }
          >
            <AddNewConactDetails />
          </Modal>
        </span>
        <Notes notes={singleRecycler.notes} />
        {singleRecycler.contactDetails?.length == 0 && (
          <div className="p-2 box justify-center align-center flex  ">
            {" "}
            <p>No Contact Details</p>{" "}
          </div>
        )}
        <EditContactDetails contactDetails={singleRecycler?.contactDetails} />
     
      </div>
      <div className="mt-2">
        <span className="flex align-center space-between">
          <b>Location:</b>

          <Modal
            trigger={
              <button className="btn" node="botton">
                Add Location
              </button>
            }
          >
            <AddLocation />
          </Modal>
        </span>
        {singleRecycler.locations?.map((item, index) => {
          return (
            <div key={index} className=" flex align-center space-between ">
              <span className="flex">
                <p className="small-text grey-text ">{item.nickname}</p>
                <p className="small-text grey-text">
                  {item.address}
                </p>
              </span>
              <span
                onClick={() =>
                  dispatch(deleteRecyclerLocationAction(id, item._id))
                }
                className="material-symbols-outlined red-text p-1 hover "
              >
                delete
              </span>
            </div>
          );
        })}
      </div>

      <EditScrapsList scraps={singleRecycler.scraps} />
      <EditRecyclerDetails singleRecycler={singleRecycler} />
    </div>
  );
}

export default EditRecycler;
